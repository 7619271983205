<template>
    <div class="bg-white flex flex-col items-center justify-center h-screen" v-if="!$store.state.auth.errorLogin">
        <img class="w-1/12 animation-image" src="@/assets/icons/preloader.svg " alt="">
    </div>
    <div v-else class="flex flex-col items-center justify-center h-screen">
        <div class="bg-theme-1 p-5 rounded-xl flex flex-col gap-3">
            <p class="text-center">
                {{$t('login.error_social')}}
            </p>
            <a href="/login" class="btn btn-primary">{{$t('login.btn_error_social')}}</a>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
    components: {
    },
    setup() {
        const route = useRoute();
        const code = route.query.code
        const errror = ref(false)
        console.log(code)

        const store = useStore();
        store.dispatch('auth/loginSocial',{
            url:"auth/google",
            code,
        })

    }
});


</script>
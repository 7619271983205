const getDateZFormatted = (date) => {
    if (date === undefined) {
        date = new Date();
    }
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return year +
        "-" +
        ('00' + month).slice(-2) +
        "-" +
        ('00' + day).slice(-2) +
        "T00:00:00.000Z";
}

const todayDateWithCustomStart = (years, months, days) => {
    const date = new Date();
    if (years) {
        date.setFullYear(date.getFullYear() + years)
    }

    if (months) {
        date.setMonth(date.getMonth() + months)
    }

    if (days) {
        date.setDate(date.getDate() + days)
    }

    return date;
}

export { todayDateWithCustomStart, getDateZFormatted }
<template>
    <span>
        <div
            class="flex flex-row gap-5"
            v-for="(dat,index) in data"
            :key="dat"
        >
            <div class="flex flex-row gap-2 w-full mt-2">
                <input
                    id="regular-form-1"
                    type="text"
                    class="form-control h-10 w-full"
                    :placeholder="$t('content.factMatching.placeholder_question') "
                    v-model="dat.question"
                    :class="dat.question.trim().length <= 5 ? 'border-danger-100' : 'border-success-100' "
                >
                <button
                    type="button"
                    v-on:click="removedataContent(index)"
                    class="btn btn-primary-outline w-24 h-10"
                >
                    <inline-svg
                        :src="require('@/assets/icons/trash-solid.svg')"
                        alt=""
                        class="h-full"
                    />
                </button>
                <hr>
            </div>
        </div>
        <div class="flex flex-row my-5 items-center gap-3">
            <button
                type="button"
                v-on:click="addNewData()"
                class="btn btn-primary-soft h-10 w-16 "
            >
                <inline-svg
                    :src="require('@/assets/icons/plus-solid.svg')"
                    alt=""
                    class="h-full"
                    style="fill:white"
                />
            </button>
            <p v-if="dataContent" 
                class="text-lg  "
                :class="dataContent.length < $store.state.globalVariables.factMatching.minContent ? 'text-danger-100' :'text-success-120' "
            >
                {{dataContent.length}}/{{$store.state.globalVariables.factMatching.maxContent}}
            </p>

        </div>
    </span>
</template>

<script>
import { axiosToken } from "@/libs/http";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { SmartTagz } from "smart-tagz";
import "smart-tagz/dist/smart-tagz.css";
import { useI18n } from "vue-i18n";
import { notify } from "notiwind";
import { useStore } from "vuex";

export default defineComponent({
    components: {
        SmartTagz,
    },

    props: {
        data: Object,
    },

    setup(props) {
        const dataContent = ref(props.data);
        const { t } = useI18n();
        const store = useStore();
        
        const logResult = (result, e) => {
            dataContent.value[e].quiz = result;
        };

        const addNewData = () => {
            if (dataContent.value.length >= store.state.globalVariables.factMatching.maxContent) {
                notify ({
                    group: "error",
                    title: t('general.error'),
                    text: t('content.alert_limit_content')
                }, 2000)
                return;
            }
            dataContent.value.push({ question: "" });
        };

        const removedataContent = (index) => {
            dataContent.value.splice(index, 1);
        };

        const tag = ref(["asd"]);
        return {
            tag,
            logResult,
            dataContent,
            addNewData,
            removedataContent,
        };
    },
});
</script>


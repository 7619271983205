import Dropzone from 'dropzone'
import { notify } from "notiwind";

const init = (el, props) => {
  Dropzone.autoDiscover = false
  el.dropzone = new Dropzone(el, props.options).on("success",(file,response) => {
    notify(
      {
          group: "top",
          template: "image_upload",
      },
      4000
  );
    setTimeout((()=>{
      el.dropzone.removeFile(file);

    }), 2000);

    
    props.success(response)
    }
  );
}

export { init }

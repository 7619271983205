const en = {
    // Register
    register: {
        early_1: "Sorry, Remote Boost is not available yet.",
        early_2: "It will be available as of April 2022. Enter your email below if you want to be notified when the launch happens:",
        early_3: "We are also having an Early Birds program for clients to try it sooner and at an exclusive price in exchange for monthly feedback. If you are interested, get in contact with us at",
        btn_notify: "Keep me updated",
        early_notifty: "Thanks for your interest. We will contact you as soon as possible.",


        // register Form 
        signup: "Sign Up",
        placeholder_email: "Corporate Email",
        placeholder_password: "Password",
        placeholder_password_confirmation: "Password Confirmation",
        terms_1: "I agree to the RemoteBoost",
        terms_2: "Privacy Policy",
        btn_login: "Login",
        btn_register: "Register"
    },


    email_validate: {
        text: "Your email has been been validated",
        btn_log_in: "Sign In",
        title_error: "Error",
        text_send_again: "Send Again",
        btn_re_send: "Send Email",
    },


    //register
    register_activate: {
        alert_new_email: "We have sent you a new email.",
        alert_register_email: "We have sent you a confirmation email, please check your inbox.",
    },




    //register
    login: {
        signIn_title: "Sign In",
        password_error: "Wrong password",
        forgot_password: "Can't remember your password?",
        login_btn: "Login",
        signup_btn: "Sign up",
        term_text: "By signing up, you agree to our",
        term: "Terms and Conditions",
        privacy: "Privacy Policy",
        error_social: "Error",
        btn_error_social: "Return to Login",

    },

    onBoarding: {
        title_user: "Tell us more about you",
        title_org: "...and about your organization",
        labble_name: "Name",
        placeholder_name: "Tony Stark",
        labble_title: "Title (optional)",
        placeholder_title: "HR Director",
        btn_next: "Next",
        label_org: "Company name",
        placeholder_org: "Stark Enterprises",
        btn_finish: "Finish",
        label_industry: "Industry",
        label_language: "Language",
        label_size: "Size",
        placeholder_industry: "FinTech",
    },

    alerts: {
        image_upload: "Image Uploaded"
    },


    // trial
    trial: {
        endTrial: "Your trial period is over. Contact the sales team at contact{'@'}remoteboost.io if you want your team to continue enjoying Remote Boost."
    },

    // side menu
    sideMenu: {
        Team: 'Team',
        Bonfire: 'Bonfire',
        Activities: 'Activities',
        Bookings: 'Bookings',
        Organization: 'Organization',
        Settings: 'Settings',
        Content: 'Content',
        GameUsage: 'Game Usage',
        Analytics: 'Analytics',
    },

    topMenu: {
        help_modal: "Getting Started - Remote Boost's menu overview"
    },

    general: {
        edit: "Edit",
        delete: "Delete",
        date: "Date",
        hour: "Time",
        all_teams: "All users",
        update: "Update",
        save: "Save",
        help: "Help",
        back: "Back",
        accept: "Accept",
        cancel: "Cancel",
        success: "Success",
        error: "Error",
        date_start: "Start",
        date_end: "End",
        close: "Close",
    },

    placeholder: {
        name: "Name"
    },

    teams: {
        title: "Teams",
        addteam_btn: "Add team",
        adduser_btn: "Add user",
        all_teams: "All teams",
        // new team modal
        newteam_title: "Add new team",
        newteam_placeholder_name: "Name",
        newteam_save_btn: "Add team",

        // new user modal
        newuser_title: "Add new user",
        newuser_placeholder_name: "Name",
        newuser_placeholder_email: "Email",
        newuser_placeholder_title: "Title",
        newuser_team_labble: "Team",
        newuser_save_btn: "Add user",
        newuser_rol_labble: "Role",

        // edit team modal
        editteam_title: "Edit team",
        editteam_btn_delete: "Delete team",

        // edit user modal
        edituser_title: "Edit user",
        edituser_linked_acc: "This user is linked to an account",
        edituser_btn_delete: "Delete user",

        // alerts
        alert_linked_account: "This user cannot be deleted because it's linked to an account. Please contact support.",

        //users rol
        rol_user: "User",
        team_lead: "Team captain",

        users: "Users",
    },



    bonfire: {
        title: "Bonfire",
        bonfire_now_title: "Start a Bonfire Now",
        bonfire_now_text: "Is your team ready to play? Don't keep them waiting and start a Bonfire now.",
        bonfire_shedule_title: "Schedule a Bonfire",
        bonfire_shedule_text: "Want to plan ahead? Start organizing your future Bonfires now.",
        remember_me: "Send a reminder email 10 min before the activity",
        send_invitations: "Send invitations",
        delete_room: "Are you going to remove the room?",
        delete_event: "Are you going to remove the event?",
        delete_recurrent_event: "Are you going to remove the event and its recurrency?",
        update_room: "Room updated.",
        update_event: "Event updated.",
        update_recurrent_event: "Event and its recurrency updated.",
        incoming_bonfires: "Incoming Bonfires",


        //shedule modal 
        modal_shedule_title: "Shedule a Bonfire",
        modal_shedule_date: "Date",
        modal_shedule_hour: "Time",
        modal_shedule_message: "Messages",
        modal_shedule_team: "Team",
        modal_shedule_btn: "Send",
        modal_alert_success: "Invitation sent. See you on Bonfire!",
        modal_alert_success_room: "¡Room created! See you on Bonfire.",
        modal_alert_error_delete_event: "There was a problem deleting the bonfire.",
        modal_alert_success_delete_event: "Bonfire deleted.",
        modal_alert_error_delete_room: "There was a problem deleting the room.",
        modal_alert_success_delete_room: "Room deleted.",
        modal_alert_error: "Invitation couldn't be sent",
        btn_start_bonfire: "Start Bonfire",
        btn_shedule_bonfire: "Shedule a Bonfire",
        invitations_send: "{n_invitations} invitations will be sent",

        // bonfire 2.0
        btn_new_bonfire: "+ New Bonfire",
        room_permament: "Permament Room",
        room_temporary: "Temporary Room",
        finish: " Finish :",
        btn_copy: "Copy link",

        modal_new_title: "Create Bonfire Room",
        modal_new_max_bonfire: "Your organization can have up to {rooms} permanent Bonfire rooms",
        modal_new_room_name: "Room Name",
        modal_new_type: "Room type",
        option_temporal: "Temporary (2 hours)",
        option_permanent: "Permanent",
        btn_create: "Create",

        alert_copy_title: "Link copied",
        alert_copy_text: "The link has been copied to your clipboard. You just need to paste it.",
        alert_new_room_created: "The Bonfire has been scheduled successfully.",

        // bonfire 3.0
        text_info: "Schedule social gaming moments with your colleagues.",

        open_rooms: "Open rooms",
        shedule_rooms: "Scheduled Bonfires",
        week: "week",

        btn_enter: "Enter",
        at: "at",
        team: "team",
        all: "all",

        title_shedule: "Schedule a new Bonfire",
        text_shedule: "Choose the type of event that suits your team the best and send them an invite.",
        stanalone_game: "Standalone Game",
        bonfire_ritual: "Bonfire Ritual",
        open_room: "Open Room",
        time_zone: "Time zone",
        placeholder_msg: "Add a custom message to your invite.",
        duration_game: "Duration (informative, game time is not limited):",
        btn_shedule: "Shedule",
        btn_create: "Create",
        day_choose: "Choose how often the event occurs:",
        day_1: "Mon",
        day_2: "Tue",
        day_3: "Wed",
        day_4: "Thu",
        day_5: "Fri",
        day_6: "Sat",
        day_7: "Sun",
        day_choose_weekly: "Multiple days per week",
        day_choose_week: "Weekly",
        day_choose_week_2: "Every other week",
        day_choose_month: "Once a month",


        tooltip_ritual: "A Bonfire event that repeats over time.",
        tooltip_open_room: "A permanent room with open access anytime.",
        tooltip_puntual: "One-time Bonfire event. At the end of the game the link will expire.",

        //edit 
        edit_title: "Edit Bonfire",
        edit_schedule: "Edit schedule",
    },

    activities: {
        title: "Hosted activities",
        btn_details: "Details",
        btn_book: "Book",
        modal_book_title: "Book",
        modal_book_edit_title: "Edit booking",
        modal_book_team: "Choose a team",
        modal_book_bt: "Book",
        modal_error_date: "The date and time have to be later than the present date.",
        modal_alert_success: "Booking succesful",
        modal_alert_success_edit: "Booking successfully updated",

        details_include: "Includes",
        details_pax: "ppl",
        details_min: "min",
        details_requirements: "Requirements",
        details_player_requirements: "Player requirements",
        no_permission_book: "Your are not allowed to book.",

    },

    booking: {
        title: "Bookings",
        table_activity: "Activity",
        table_date_and_time: "Date and time",
        table_team: "Team",
        table_state: "Status",
        state_approved: "Approved",
        state_unapproved: "Not approved",
        state_done: "Done",
        alert_book_delete: "Booking deleted"

    },

    organization: {
        title: "Organization",
        users: "Users",
        plan: "plan",
        invitations_language: "Invitation language",
        change_settings: "Change settings",
        alert_success_updating: "The organization has been updated successfully.",
        alert_error_updating: "An error occurred while updating the organization.",
    },

    update_profile: {
        title: "Update profile",
        change_password: "Change password",
        placeholder_password: "Password",
        password_labble: "Password",
        re_passowrd_labble: "Repeat password",
        success_password: "Password updated",
        errror_password: "Your password could not be updated",
    },


    // Content Manager
    content: {
        title: "Game Content",
        title_admin: "Game Content Admin",
        text_first_page: "Create your own custom content!",
        validated: "Validated and Saved",
        btn_add_content: "Add Content",
        not_validated: "Not validated",

        card_new_content_title: "Add Content",
        card_new_content_text: "Here you will be able to add your own custom content for some of the Bonfire games.",

        btn_new_content: "Add New Category",
        btn_save_all: "Save all",
        btn_new_theme: "New theme",

        label_name_category: "Category name",
        label_name_set: "Game set name",

        placeholder_name_category: "Category...",
        placeholder_name_set: "Game set...",

        modal_new_content_title: "New Content",
        modal_new_content_name_labble: "Name",
        modal_new_content_name_placeholder: "Name",
        modal_new_content_btn: "Create Content",

        content_table_name: "Category",
        content_table_status: "Status",
        content_table_actions: "Actions",
        content_table_active: "Active",
        content_table_inactive: "Inactive",
        content_table_edit: "Edit",
        content_table_delete: "Delete",
        content_table_user: "User",

        subcontent_title: "How to create content for this game:",
        subcontent_text_factMatching: "Each content has between {minContent} and {maxContent} questions that will be asked to all participants. The question has to be open-ended so that players can give creative or identifying answers.",
        subcontent_text_topo: "Each content should have {minContent} words of a similar or slightly related topic. It is important that they are abstract and known concepts.",
        subcontent_text_sellerSponge: "Each content must have {minContent} products to sell (each one will be assigned to a player). The product must be paired with an image. It can be given a creative or serious approach depending on the nature of the product. All participants must be familiar with the product.",
        subcontent_text_quiz: "Each content is a set of between {minContent} and {maxContent} questions with 4 answer options to choose from. The questions may include an image.",
        subcontent_text_pictionary: "Each content consists of between {minContent} and {minContent} groups of 4 concepts (the more groups, the more variety and replayability the game will have). In each group, the 4 concepts must be representable with a drawing and must be related to each other. They must also be easily confused with each other, but not synonyms.<br>For example: island, peninsula, archipelago and continent.",
        subcontent_text_mingleTingle: "Each content has {minContent} questions to generate debate among the participants and a final question that invites them to vote for a person in the group.<br>The discussion questions should be open-ended and promote the expression of opinions, i.e. What is love for you?<br>The final question should be related to the topic and serve as a way to use what has been learned about each other, i.e. Who is most likely to be in a polyamorous relationship?",
        content_of: "Content of ",

        alert_save_success: "Content saved successfully.",
        alert_save_error: "Content couldn't be saved.",
        alert_add_question_success: "Question added successfully.",
        alert_add_question_error: "The question couldn't be added.",
        alert_delete_question_success: "Question deleted successfully.",
        alert_delete_question_error: "The question couldn't be deleted.",
        alert_delete_content: "Content deleted successfully.",
        alert_limit_content: "You can't add more than content.",
        alert_enabled: "Content activated.",
        alert_disabled: "Content disabled.",
        alert_cant_enable: "The content couldn't be activated.",
        alert_delete_content_question: "Are you sure you want to delete this content?",
        alert_add_content_success: "Content created.",
        alert_new_category_empty_name: "Category name can't be empty.",

        // mingle Tingle
        mingleTingle: {
            placeholder_question: "Question"
        },

        // sellerSponge
        sellerSponge: {
            placeholder_question: "Question",
            placeholder_image: "Drop files here or click to upload. As square as possible.",
        },
        mingleTingle: {
            final_question: "Final question",
            open_questions: "Open ended questions",

        },

        factMatching: {
            placeholder_question: "Question",
        },

        quiz: {
            question: "Question",
            correct: "Correct answer",
            incorrect: "Wrong answer",
            photo: "Trivia question",
            eliminar: "Delete content"

        },

        pictionary: {
            placeholder: "Add concepts to draw",
        },
        topo: {
            placeholder: "Add Mole words",
        }
    },
    analytics: {
        title: "Analytics",
        bonfire_time: "Bonfire Time",
        play_time: "Play Time",
        games_played: "Games Played",
        mean_players: "Average Players",
        most_played: "Most Played",
        content_most_played: "Content Most Played",
    }
}



module.exports = {
    en
}

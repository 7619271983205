<template>
    <h2 class="font-black text-2xl xl:text-3xl text-center xl:text-left my-5">
        {{ $t("booking.title") }}
    </h2>
    <transition name="view" mode="out-in">
        <div
            class="intro-y col-span-12 overflow-auto lg:overflow-visible"
            v-if="loadingBookings"
        >
            <table class="table table-report -mt-2" v-if="bookings.length > 0">
                <thead>
                    <tr>
                        <th class="whitespace-nowrap">
                            {{ $t("booking.table_activity") }}
                        </th>
                        <th class="whitespace-nowrap">
                            {{ $t("booking.table_date_and_time") }}
                        </th>
                        <th class="text-center whitespace-nowrap">
                            {{ $t("booking.table_team") }}
                        </th>
                        <th class="text-center whitespace-nowrap">
                            {{ $t("booking.table_state") }}
                        </th>
                        <th
                            class="text-center whitespace-nowrap"
                            v-if="$store.state.auth.rol != 'regular_user'"
                        ></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="book in bookings" :key="book" class="intro-x">
                        <td class="w-96 font-bold text-lg">
                            {{ book.activity.name }}
                        </td>
                        <td>
                            {{ $filters.formatDate(book.date) }}
                        </td>
                        <td v-if="book.all" class="text-center mx-auto">
                            {{ $t("general.all_teams") }}
                        </td>
                        <td v-else class="text-center mx-auto">
                            {{ book.team.name }}
                        </td>
                        <td class="text-center mx-auto">
                            <p v-if="book.state == 0">
                                {{ $t("booking.state_unapproved") }}
                            </p>
                            <p v-if="book.state == 1">
                                {{ $t("booking.state_approved") }}
                            </p>
                            <p v-if="book.state == 2">
                                {{ $t("booking.state_done") }}
                            </p>
                        </td>
                        <td
                            class="table-report__action w-56"
                            v-if="
                                $store.getters.getPermission('bookings.edit') ||
                                    $store.getters.getPermission(
                                        'bookings.delete'
                                    )
                            "
                        >
                            <div class="flex justify-center items-center">
                                <a
                                    data-toggle="modal"
                                    data-target="#booking-modal"
                                    v-on:click="
                                        setActivity(book.activity.name, book.id)
                                    "
                                    class="flex items-center mr-3 cursor-pointer "
                                    v-if="
                                        book.state == 0 &&
                                            $store.getters.getPermission(
                                                'bookings.edit'
                                            )
                                    "
                                >
                                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                                    {{ $t("general.edit") }}
                                </a>
                                <a
                                    class="flex items-center text-theme-6 cursor-pointer"
                                    v-on:click="deleteActivity(book.id)"
                                    v-if="
                                        book.state == 0 &&
                                            $store.getters.getPermission(
                                                'bookings.delete'
                                            )
                                    "
                                >
                                    <Trash2Icon class="w-4 h-4 mr-1" />
                                    {{ $t("general.delete") }}
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div
                v-else-if="bookings.length == 0 && loadingBookings"
                class="flex flex-col gap-5 mt-20 items-center"
            >
                <h2 class="text-text-100 text-2xl">No booking</h2>
                <router-link to="/activities" class="btn btn-primary-soft w-48"
                    >Make your first booking!</router-link
                >
            </div>
        </div>
        <div v-else>
            <img
                class="m-auto w-2/12 animation-image"
                src="@/assets/icons/preloader.svg "
                alt=""
            />
        </div>
    </transition>

    <BookingModal
        :activity="currentActivity"
        :edit="true"
        :form="bookingform"
        :update="parseBooking"
    />
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { axiosToken } from "@/libs/http";
import BookingModal from "@/components/modals/BookingModal.vue";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";

export default defineComponent({
    components: {
        BookingModal
    },
    setup() {
        onMounted(() => {
            parseBooking();
        });
        const { t } = useI18n();
        const loadingBookings = ref(false);
        const bookings = ref([]);
        const currentActivity = ref({});
        const bookingform = ref({});
        const parseBooking = () => {
            axiosToken({
                method: "get",
                url: "booking/"
            }).then(response => {
                loadingBookings.value = true;
                bookings.value = response.data;
            });
        };

        const deleteActivity = id => {
            if (confirm("Are you sure to delete the booking?") == true) {
                axiosToken({
                    method: "delete",
                    url: "booking/" + id
                }).then(response => {
                    parseBooking();
                    notify(
                        {
                            group: "top",
                            title: "Success",
                            text: t("booking.alert_book_delete")
                        },
                        4000
                    );
                });
            }
        };

        const setActivity = (name, id) => {
            currentActivity.value = {
                name,
                id
            };

            bookingform.value = bookings.value.find(e => e.id == id);
        };

        return {
            loadingBookings,
            bookings,
            setActivity,
            currentActivity,
            bookingform,
            parseBooking,
            deleteActivity
        };
    }
});
</script>

<template>
    <div id="booking-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <a data-dismiss="modal" href="javascript:;">
                    <XIcon class="w-8 h-8 text-slate-400" />
                </a>
                <div class="modal-body px-5 py-10 flex flex-col">
                    <div class="text-center">
                        <h2 v-if="edit">
                            {{ $t("activities.modal_book_edit_title") }}
                        </h2>
                        <h2 v-else>
                            {{ $t("activities.modal_book_title") }}
                        </h2>
                        <input
                            v-if="currentActivity"
                            :disabled="true"
                            class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2"
                            type="text"
                            v-model="currentActivity.name"
                        />

                        <div id="basic-datepicker" class="flex flex-row gap-5">
                            <p class="my-auto">{{ $t("general.date") }}:</p>
                            <div class="preview w-full">
                                <Litepicker
                                    v-model="v$.form.date.$model"
                                    :options="{
                                        autoApply: false,
                                        showWeekNumbers: true,
                                        dropdowns: {
                                            minYear: 1990,
                                            maxYear: null,
                                            months: true,
                                            years: true
                                        }
                                    }"
                                    class=" focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2 "
                                />
                            </div>
                        </div>
                        <div id="basic-datepicker" class="flex flex-row gap-5">
                            <p class="my-auto">{{ $t("general.hour") }}:</p>
                            <div class="preview w-full">
                                <input
                                    v-model="v$.form.hour.$model"
                                    type="time"
                                    step="600"
                                    class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2"
                                />
                            </div>
                        </div>
                        <p>
                            {{ $t("activities.details_player_requirements") }}
                        </p>
                        <select
                            class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2"
                            name=""
                            id=""
                            v-model="v$.form.team.$model"
                        >
                            <option value="all"
                                >{{ $t("general.all_teams") }}
                            </option>
                            <option
                                v-for="team in teams"
                                :key="team"
                                :value="team.id"
                                >{{ team.name }}</option
                            >
                        </select>
                        <Btn
                            v-if="
                                $store.getters.getPermission('activities.book')
                            "
                            type="button"
                            class="btn btn-primary "
                            :disabled="v$.form.$invalid"
                            v-on:click="bookingActivity()"
                            :isLoading="bookingLoading"
                        >
                            <p v-if="edit">
                                {{ $t("general.edit") }}
                            </p>
                            <p v-else>
                                {{ $t("activities.modal_book_bt") }}
                            </p>
                        </Btn>
                        <p v-else>
                            {{ $t("activities.no_permission_book") }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    onMounted,
    ref,
    reactive,
    computed,
    watch
} from "vue";
import { axiosToken } from "@/libs/http";
import { notify } from "notiwind";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import Btn from "@/global-components/btn/Main.vue";
import moment from "moment";
import { useI18n } from "vue-i18n";

export default {
    props: {
        activity: Object,
        edit: { type: Boolean, default: false },
        form: { type: Object, default: {} },
        update: { type: Function, default: () => {} }
    },
    components: {
        Btn
    },
    setup(props) {
        const currentActivity = ref(null);
        const { t } = useI18n();
        watch(
            () => props.activity,
            () => {
                bookingForm.form.activity = props.activity.id;
                currentActivity.value = props.activity;
            }
        );
        watch(
            () => props.form,
            () => {
                bookingForm.form.date = moment(String(props.form.date)).format(
                    "ll"
                );
                bookingForm.form.hour = moment(String(props.form.date)).format(
                    "HH:mm"
                );
                if (props.form.all) {
                    bookingForm.form.team = "all";
                } else {
                    bookingForm.form.team = props.form.team.id;
                }
            }
        );

        const teams = ref([]);
        const activities = ref([]);
        const fetchTeams = () => {
            axiosToken({
                method: "get",
                url: "accounts/team"
            }).then(response => {
                teams.value = response.data;
                return response.data;
            });
        };

        onMounted(() => {
            fetchTeams();
        });

        // Booking activity
        const bookingLoading = ref(false);
        const bookingForm = reactive({
            form: {
                activity: "",
                date: "",
                hour: "",
                team: ""
            }
        });
        const rulesBooking = computed(() => ({
            form: {
                activity: {
                    required
                },
                date: {
                    required
                },
                hour: { required },
                team: { required }
            }
        }));

        const bookingActivity = () => {
            let dateobj = new Date(
                bookingForm.form.date + " " + bookingForm.form.hour
            );
            var B = dateobj.toISOString();
            let body = {
                activity: bookingForm.form.activity,
                date: B
            };
            console.log(dateobj);
            if (dateobj < Date.now()) {
                notify(
                    {
                        group: "error",
                        title: "Error",
                        text: t("activities.modal_error_date")
                    },
                    4000
                );
                return;
            }
            if (bookingForm.form.team == "all") {
                body.all = true;
            } else {
                body.team = bookingForm.form.team;
            }
            bookingLoading.value = true;
            if (props.edit) {
                axiosToken({
                    method: "put",
                    url: "booking/" + props.form.id,
                    body
                })
                    .then(response => {
                        cash("#booking-modal").modal("hide");
                        notify(
                            {
                                group: "top",
                                title: "Success",
                                text: t("activities.modal_alert_success_edit")
                            },
                            4000
                        );
                        bookingLoading.value = false;
                        props.update();
                    })
                    .catch(e => {
                        console.log(e.response);

                        bookingLoading.value = false;
                    });
            } else {
                axiosToken({
                    method: "post",
                    url: "booking/",
                    body
                })
                    .then(response => {
                        cash("#booking-modal").modal("hide");
                        notify(
                            {
                                group: "top",
                                title: "Success",
                                text: t("activities.modal_alert_success")
                            },
                            4000
                        );
                        bookingLoading.value = false;
                    })
                    .catch(e => {
                        console.log(e.response);

                        bookingLoading.value = false;
                    });
            }
        };
        const v$ = useVuelidate(rulesBooking, bookingForm);
        return {
            v$,

            bookingActivity,
            bookingLoading,
            teams,
            currentActivity
        };
    }
};
</script>

<style></style>

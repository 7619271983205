const state = () => {
    return {
        // WHEN ARRAY IS EMPTY ALL USERS HAVE PERMISSION
        team: {
            access: [],
            add_user: ['admin'],
            edit_user: ['admin'],
            delete_user: ['admin'],
            add_team: ['admin'],
            edit_team: ['admin'],
            delete_team: ['admin'],
        },
        bonfire: {
            access: [],
            add_event: ['admin'],
            edit_event: ['admin'],
            delete_event: ['admin'],
            add_room: ['admin'],
            edit_room: ['admin'],
            delete_room: ['admin'],
        },
        activities: {
            access: [],
            book: ['admin'],
        },
        bookings: {
            access: [],
            edit: ['admin'],
            delete: ['admin'],
        },
        organization: {
            access: [],
            edit: ['admin', 'regular_user'],
        },
        content: {
            access: [],
            view_all: ['admin'],
            enable: [],
        },
        analytics: {
            access: ['admin'],
        },
        settings: {
            access: [],
        },
        content_admin: {
            access: ['super'],
        },
        game_usage: {
            access: ['super']
        }
    }
}
const getters = {
    getPermission: (state, getters, rootState) => (permission) => {
        permission = permission.split('.');
        return state[permission[0]][permission[1]].find(element => element == rootState.auth.rol)
            || !state[permission[0]][permission[1]].length
            || rootState.auth.rol == "super";
    }
}


export default { state, getters }

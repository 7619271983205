import AuthService from '../libs/auth/auth.service'
import axios from 'axios'
import router from '../router'
import { axiosToken } from '@/libs/http'
import { notify } from "notiwind";
import { i18n } from '../i18n'
const API_URL = process.env.VUE_APP_API_URL



const user = localStorage.getItem('user');
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };



const state = () => {
  return {
    ...initialState,
    errorLogin: false,
    errorRegister: "",
    startRegister: false,
    profileCompleted: false,
    loginLoading: false,
    organitzation: {
      name: "",
      bonfireUrl: "",
      image: null,
      max_room: 0,
      plan: null,
      language: "",
    },
    modalOrganitzation: false,
    rol: null,
    loadingResetPassword: false,
    resetPassword: false,
    resetFail: false,
    modalPersonalInfo: false,
    registerSuccess: false,
    userInfo: {
      email: "",
      name: "",
      id: ""
    },
    loadingApp: true,
    trial: {
      state: false,
      finish_date: null
    },
    recoveryEmailError: ""
  }
}

// getters
const getters = {}
// actions
const actions = {
  login({ commit }, user) {
    commit('loginClean')
    axios
      .post(API_URL + 'api/authentication/login/', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        console.log(response)
        if (response.data.key) {
          console.log("save")
          localStorage.setItem('user', response.data.key)
        }
        console.log("in")
        commit('loginSuccess', response.data.key)
        router.push("/")

        return Promise.resolve(response.data.key)
      }).catch((e) => {
        console.log("in")
        return commit('loginFailure')
      })
  },
  logout({ commit }) {
    AuthService.logout(user);
    commit('logout');
    location.reload();
  },
  register({ commit }, user) {
    commit('registerStart')
    return AuthService.register(user).then(
      response => {
        console.log(this)
        commit('setRegisterSuccess')
        notify(
          {
            group: "top",
            title: "Success",
            template: "registration_success",
          },
          4000
        );
      },
      error => {
        const errrorData = error.response.data
        commit('registerFailure', errrorData[Object.keys(errrorData)[0]][0]);
        return Promise.reject(errrorData[Object.keys(errrorData)[0]][0]);
      }
    );
  },
  recoveryPassword({ commit }, user) {
    commit('recoveryStart')
    axios
      .post(API_URL + 'api/authentication/password/reset/', {
        email: user.email
      })
      .then(response => {
        return commit('recoveryFinish')
      }).catch((e) => {
        const errrorData = e.response.data
        if ("email" in errrorData) {
          return commit('recoveryError', errrorData.email.email[0])
        }
        else {
          return commit('recoveryError', '500')
        }
      })
  },

  async checkOrg({ commit }) {
    axiosToken({
      url: "accounts/organitzation",
      method: "get",
      body: {}
    })
      .then(response => {

        commit('setOrganitzationData', response.data)
        console.log("in_1")
      }).catch((e) => {
        if (e.response.status === 401) {
          console.log("remove user")
          localStorage.removeItem('user')
          router.push("/login")
        }
        else if (e.response.status === 400) {
          commit('setOrganitzationModal')
        }
        else if (e.response.status === 406) {
          commit('setPersonalModal')
        }
        else {

        }
      })
    await axiosToken({
      url: "accounts/rol",
      method: "get",
      body: {}
    })
      .then(response => {
        commit('setRolUser', response.data)
      }).catch((e) => {
      })
    commit('appLoad')


  },

  loginSocial({ commit }, data) {
    axios
      .post(API_URL + data.url, {
        code: data.code
      })
      .then(response => {
        if (response.data.key) {
          localStorage.setItem('user', response.data.key)
        }
        console.log("in")
        commit('loginSuccess', response.data.key)
        router.push("/")

        return Promise.resolve(response.data.key)
      }).catch((e) => {
        console.log("in")
        return commit('loginFailure')
      })
  }




}

// mutations
const mutations = {
  // organitzation 
  appLoad(state) {
    state.loadingApp = false;
  },
  recoveryStart(state) {
    state.loadingResetPassword = true;
    state.resetFail = false;
  },
  recoveryFinish(state) {
    state.resetPassword = true;
  },
  recoveryError(state, data) {
    state.resetFail = true;
    state.recoveryEmailError = data
    state.loadingResetPassword = false;
  },
  setRolUser(state, data) {
    state.userInfo.name = data.name
    state.userInfo.email = data.user.email
    state.userInfo.id = data.id
    state.rol = data.rol
  },
  setOrganitzationData(state, data) {
    console.log(data.name)
    state.modalPersonalInfo = false;
    state.modalOrganitzation = false;
    state.organitzation.name = data.name
    state.organitzation.bonfireUrl = data.bonfire_url
    state.modalOrganitzation = false
    state.organitzation.image = data.img
    state.trial.state = data.plan.trial
    state.organitzation.plan = data.plan.plan
    state.organitzation.language = data.language
    state.organitzation.max_room = data.max_open_room
    state.organitzation.user_number = data.user_number
    state.organitzation.slack_app_installed = data.slack_app_installed
    if (data.plan.end_trial) {
      const diffTime = new Date(data.plan.end_trial) - new Date()
      console.log(diffTime)
      state.trial.finish_date = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
  },
  setOrganitzationModal(state, data) {
    state.modalPersonalInfo = false;
    state.modalOrganitzation = true
  },
  setPersonalModal(state, data) {
    state.modalOrganitzation = false
    state.modalPersonalInfo = true
  },
  loginClean(state) {
    state.loginLoading = true;
    state.errorLogin = false;
  },
  loginSuccess(state, user) {
    console.log(user)
    state.status.loggedIn = true;
    state.user = user;
  },
  loginFailure(state) {
    state.loginLoading = false;
    state.status.loggedIn = false;
    state.user = null;
    state.errorLogin = true;
  },
  logout(state) {
    state.status.loggedIn = false;
    state.user = null;
    localStorage.removeItem("user")
  },
  setRegisterSuccess(state) {
    state.status.loggedIn = false;
    state.registerSuccess = true;
  },
  registerFailure(state, error) {
    state.status.loggedIn = false;
    state.errorRegister = error;
    state.startRegister = false;
  },
  registerStart(state) {
    state.errorRegister = "";
    state.startRegister = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

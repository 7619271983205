<template>
    <layout>
        <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-white-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
            <div class="flex flex-col gap-5">
                <h2 class=" font-bold text-2xl xl:text-3xl text-center xl:text-left">
                    {{$t('onBoarding.title_user')}}
                </h2>
                <div class=" mt-2">
                    <p class="text-text-100 my-2">
                        {{$t('onBoarding.labble_name')}}
                    </p>
                    <input
                        v-model="v$.form.name.$model"
                        type="text"
                        class="py-3 px-4  rounded-md block background-input w-full"
                        :placeholder="$t('onBoarding.placeholder_name')"
                    />
                    <div
                        class="input-errors"
                        v-for="(error, index) of v$.form.name.$errors"
                        :key="index"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <div class="">
                    <p class="text-text-100 my-2">
                        {{$t('onBoarding.labble_title')}}
                    </p>
                    <input
                        v-model="v$.form.title.$model"
                        type="text"
                        class="py-3 px-4  rounded-md block background-input w-full"
                        :placeholder="$t('onBoarding.placeholder_title')"
                    />
                    <div
                        class="input-errors"
                        v-for="(error, index) of v$.form.title.$errors"
                        :key="index"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <Btn
                    :disabled="v$.form.$invalid "
                    class=" btn btn-primary py-3 px-4 "
                    :isLoading="loadingUser"
                    v-on:click="submitUser()"
                >
                    {{$t('onBoarding.btn_next')}}
                </Btn>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "@/components/auth/Layout.vue";
import {
    reactive,
    computed,
    ref,

} from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import Btn from "@/global-components/btn/Main.vue";
import {axiosToken} from '@/libs/http.js'
import { useStore } from 'vuex';

export default {
    components: {
        Layout,
        Btn,
    },
    setup() {
        const store = useStore();
        const loadingUser = ref(false)
        const state = reactive({
            form: {
                name: "",
                title:""
            },
        });
        const rules = computed(() => ({
            form: {
                name: { required },
                title:{}
            },
        }));
        const v$ = useVuelidate(rules, state);
        const submitUser = () => {
            loadingUser.value = true;
            axiosToken({
                method: "post",
                url: "accounts/user",
                body: {
                    name: state.form.name,
                    title: state.form.title,
                },
            }).then((response) => {
                store.dispatch("auth/checkOrg")
            });
        };


        return {
            v$,
            submitUser,
            loadingUser,
        };
    },
};
</script>

<style>
</style>
<template>
    <div
        :id="'confirm-modal' + confirmModalCustom"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content " :class="'bg-' + backgroundColor">
                <div class="rounded-lg p-5">
                    <h2
                        class="text-xl xl:text-xl text-center py-2 font-extrabold"
                    >
                        {{ title }}
                    </h2>
                    <p class="text-center">
                        {{ text }}
                    </p>
                </div>
                <div class="flex flex-row items-center justify-center mb-6">
                    <slot></slot>
                </div>
                <div
                    v-if="showButtonAccept || showButtonCancel"
                    class="flex flex-col gap-4"
                >
                    <div
                        class="flex flex-row items-center justify-center flex-wrap gap-2 mb-6"
                    >
                        <Btn
                            v-if="showButtonAccept"
                            type="button"
                            :disabled="false"
                            :isLoading="false"
                            class="btn btn-primary-soft capitalize"
                            @click="actionAccept"
                        >
                            {{ txtButtonAccept }}
                        </Btn>
                        <Btn
                            v-if="showButtonCancel"
                            type="button"
                            :disabled="false"
                            :isLoading="false"
                            class="btn btn-primary-soft capitalize"
                            @click="actionCancel"
                        >
                            {{ txtButtonCancel }}
                        </Btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Btn from "@/global-components/btn/Main.vue";
import { useI18n } from "vue-i18n";

export default {
    props: {
        title: { type: String, default: "" },
        text: { type: String, default: "" },
        textButtonAccept: { type: String, default: "" },
        textButtonCancel: { type: String, default: "" },
        showButtonAccept: { type: Boolean, default: true },
        showButtonCancel: { type: Boolean, default: true },
        backgroundColor: { type: String, default: "white" },
        confirmModalCustom: { type: String, default: "" },
        actionAccept: {
            type: Function,
            default: () => {
                cash("#confirm-modal").modal("hide");
            }
        },
        actionCancel: {
            type: Function,
            default: () => {
                cash("#confirm-modal").modal("hide");
            }
        }
    },
    components: {
        Btn
    },
    setup(props) {
        const { t } = useI18n();
        const txtButtonAccept = props.textButtonAccept
            ? props.textButtonAccept
            : t("general.accept");
        const txtButtonCancel = props.textButtonCancel
            ? props.textButtonCancel
            : t("general.cancel");

        return {
            txtButtonAccept,
            txtButtonCancel
        };
    }
};
</script>

<style></style>

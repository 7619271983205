<template>
    <transition name="view">
        <div
            class="bg-white flex flex-col items-center justify-center h-screen"
            v-if="$store.state.auth.loadingApp"
        >
            <img
                class="w-1/12 animation-image"
                src="@/assets/icons/preloader.svg "
                alt=""
            />
        </div>
        <div v-else-if="$store.state.auth.modalPersonalInfo">
            <UserInformation></UserInformation>
        </div>
        <div v-else-if="$store.state.auth.modalOrganitzation">
            <OrganitzationInformation></OrganitzationInformation>
        </div>
        <div
            v-else
            class="bodymenu overflow-x-hidden font-gilroy font- text-sm text-gray-800 py-5 side-menu-fixed"
        >
            <MobileMenu />
            <div class="flex">
                <!-- BEGIN: Side Menu -->
                <nav class="side-nav pr-0">
                    <!-- BEGIN: Logo -->
                    <router-link
                        :to="{ name: 'landing' }"
                        tag="a"
                        class="hidden xl:block intro-x flex flex-col justify-center items-center px-2 pt-4 my-5 mx-auto"
                    >
                        <img
                            alt="Icewall Tailwind HTML Admin Template"
                            class="w-100"
                            src="@/assets/images/logo-red.png"
                        />
                    </router-link>
                    <router-link
                        :to="{ name: 'landing' }"
                        tag="a"
                        class=" xl:hidden intro-x flex flex-col justify-center items-center px-2 pt-4 my-5 mx-auto"
                    >
                        <img
                            alt="Icewall Tailwind HTML Admin Template"
                            class="w-100"
                            src="@/assets/images/favicon.png"
                        />
                    </router-link>
                    <!-- END: Logo -->
                    <div
                        class="hidden xl:flex  flex-col justify-center items-center mb-10 mx-auto"
                    >
                        <img
                            v-if="$store.state.auth.organitzation.image"
                            alt="Icewall Tailwind HTML Admin Template"
                            class="w-2/4 rounded-full"
                            :src="$store.state.auth.organitzation.image"
                        />
                        <img
                            v-else
                            alt="Icewall Tailwind HTML Admin Template"
                            class="w-2/4 rounded-full"
                            src="@/assets/images/group_logo_placeholder.jpg"
                        />
                        <p class="text-text-100 font-bold capitalize">
                            {{ $store.state.auth.organitzation.name }}
                        </p>
                        <p
                            class="text-primary-1 font-bold capitalize"
                            v-if="$store.state.auth.trial.state"
                        >
                            TRIAL {{ $store.state.auth.trial.finish_date }} Days
                        </p>

                        <p class="text-text-100 capitalize mt-1">
                            {{ $store.state.auth.userInfo.name }}
                        </p>
                        <p
                            class="text-primary-1 capitalize"
                            v-if="$store.state.auth.rol == 'regular_user'"
                        >
                            {{ $t("teams.rol_user") }}
                        </p>
                        <p class="text-primary-1 capitalize" v-else>
                            {{ $store.state.auth.rol }}
                        </p>
                    </div>

                    <ul>
                        <!-- BEGIN: First Child -->
                        <template v-for="(menu, menuKey) in formattedMenu">
                            <li
                                v-if="menu == 'devider'"
                                :key="menu + menuKey"
                                class="side-nav__devider my-6"
                            ></li>
                            <li
                                :key="menu + menuKey"
                                v-else-if="
                                    $store.getters.getPermission(
                                        menu.permission + '.access'
                                    )
                                "
                            >
                                <SideMenuTooltip
                                    tag="a"
                                    :content="menu.title"
                                    :href="
                                        menu.subMenu
                                            ? 'javascript:;'
                                            : router.resolve({
                                                  name: menu.pageName
                                              }).path
                                    "
                                    class="side-menu"
                                    :class="{
                                        'side-menu--active': menu.active,
                                        'side-menu--open': menu.activeDropdown
                                    }"
                                    @click="linkTo(menu, router, $event)"
                                >
                                    <div class="side-menu__icon">
                                        <component
                                            :is="menu.icon"
                                            v-if="!menu.custom_icon"
                                        />
                                        <inline-svg
                                            width="24px"
                                            style="fill:currentColor"
                                            :src="
                                                require('@/assets/icons/' +
                                                    menu.icon)
                                            "
                                            v-else
                                            alt=""
                                            :class="{
                                                'font-bold text-primary-1':
                                                    menu.active
                                            }"
                                        />
                                    </div>
                                    <div
                                        class="side-menu__title mx-2 hidden xl:block "
                                    >
                                        {{ namesMenu[menu.title] }}
                                        <div
                                            v-if="menu.subMenu"
                                            class="side-menu__sub-icon"
                                            :class="{
                                                'transform rotate-180':
                                                    menu.activeDropdown
                                            }"
                                        >
                                            <ChevronDownIcon />
                                        </div>
                                    </div>
                                </SideMenuTooltip>
                                <!-- BEGIN: Second Child -->
                                <transition @enter="enter" @leave="leave">
                                    <ul
                                        v-if="
                                            menu.subMenu && menu.activeDropdown
                                        "
                                    >
                                        <li
                                            v-for="(subMenu,
                                            subMenuKey) in menu.subMenu"
                                            :key="subMenuKey"
                                        >
                                            <SideMenuTooltip
                                                tag="a"
                                                :content="subMenu.title"
                                                :href="
                                                    subMenu.subMenu
                                                        ? 'javascript:;'
                                                        : router.resolve({
                                                              name:
                                                                  subMenu.pageName
                                                          }).path
                                                "
                                                class="side-menu"
                                                :class="{
                                                    'side-menu--active':
                                                        subMenu.active
                                                }"
                                                @click="
                                                    linkTo(
                                                        subMenu,
                                                        router,
                                                        $event
                                                    )
                                                "
                                            >
                                                <div class="side-menu__icon">
                                                    <ActivityIcon />
                                                </div>
                                                <div class="side-menu__title">
                                                    {{
                                                        namesMenu[subMenu.title]
                                                    }}
                                                    <div
                                                        v-if="subMenu.subMenu"
                                                        class="side-menu__sub-icon"
                                                        :class="{
                                                            'transform rotate-180':
                                                                subMenu.activeDropdown
                                                        }"
                                                    >
                                                        <ChevronDownIcon />
                                                    </div>
                                                </div>
                                            </SideMenuTooltip>
                                            <!-- BEGIN: Third Child -->
                                            <transition
                                                @enter="enter"
                                                @leave="leave"
                                            >
                                                <ul
                                                    v-if="
                                                        subMenu.subMenu &&
                                                            subMenu.activeDropdown
                                                    "
                                                >
                                                    <li
                                                        v-for="(lastSubMenu,
                                                        lastSubMenuKey) in subMenu.subMenu"
                                                        :key="lastSubMenuKey"
                                                    >
                                                        <SideMenuTooltip
                                                            tag="a"
                                                            :content="
                                                                lastSubMenu.title
                                                            "
                                                            :href="
                                                                lastSubMenu.subMenu
                                                                    ? 'javascript:;'
                                                                    : router.resolve(
                                                                          {
                                                                              name:
                                                                                  lastSubMenu.pageName
                                                                          }
                                                                      ).path
                                                            "
                                                            class="side-menu"
                                                            :class="{
                                                                'side-menu--active':
                                                                    lastSubMenu.active
                                                            }"
                                                            @click="
                                                                linkTo(
                                                                    lastSubMenu,
                                                                    router,
                                                                    $event
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                class="side-menu__icon"
                                                            >
                                                                <ZapIcon />
                                                            </div>
                                                            <div
                                                                class="side-menu__title"
                                                            >
                                                                {{
                                                                    namesMenu[
                                                                        subMenu
                                                                            .title
                                                                    ]
                                                                }}
                                                            </div>
                                                        </SideMenuTooltip>
                                                    </li>
                                                </ul>
                                            </transition>
                                            <!-- END: Third Child -->
                                        </li>
                                    </ul>
                                </transition>
                                <!-- END: Second Child -->
                            </li>
                        </template>
                        <!-- END: First Child -->
                    </ul>
                </nav>
                <!-- END: Side Menu -->
                <!-- BEGIN: Content -->
                <div class="content">
                    <TopBar />
                    <router-view />
                </div>
                <!-- END: Content -->
                <div
                    id="programmatically-modal"
                    class="modal"
                    data-backdrop="static"
                    tabindex="-1"
                    aria-hidden="true"
                >
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body px-5 py-10">
                                <div class="text-center">
                                    <div class="mb-5">
                                        You have to name your organitzation!
                                    </div>
                                    <input
                                        v-model="formOrg.name"
                                        type="text"
                                        class=" intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4 mb-2"
                                        placeholder="Name of the org"
                                    />
                                    <Btn
                                        :isLoading="loadingOrg"
                                        type="button"
                                        v-on:click="submitOrg()"
                                        class="btn btn-primary w-36"
                                    >
                                        Creat New
                                    </Btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="general-user-info"
                    class="modal"
                    data-backdrop="static"
                    tabindex="-1"
                    aria-hidden="true"
                >
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body px-5 py-10">
                                <div class="text-center">
                                    <div class="mb-5">
                                        Tell more about you
                                    </div>
                                    <input
                                        v-model="formPersonalInfo.name"
                                        type="text"
                                        class=" intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4 mb-2
                  "
                                        placeholder="What's your name"
                                    />

                                    <button
                                        type="button"
                                        v-on:click="submitUser()"
                                        class="btn btn-primary w-24"
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {
    defineComponent,
    computed,
    onMounted,
    ref,
    watch,
    reactive
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";
import { linkTo, nestedMenu, enter, leave } from "./index";
import { axiosToken } from "@/libs/http";
import Btn from "@/global-components/btn/Main.vue";
import UserInformation from "@/components/bootUser/UserInformation.vue";
import OrganitzationInformation from "@/components/bootUser/OrganitzationInformation.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
    components: {
        TopBar,
        MobileMenu,
        SideMenuTooltip,
        Btn,
        UserInformation,
        OrganitzationInformation
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const { t } = useI18n();
        const store = useStore();
        const formattedMenu = ref([]);
        const loadingOrg = ref(false);
        const formOrg = ref({
            name: ""
        });

        const namesMenu = computed(() => ({
            Team: t("sideMenu.Team"),
            Bonfire: t("sideMenu.Bonfire"),
            Activities: t("sideMenu.Activities"),
            Bookings: t("sideMenu.Bookings"),
            Organization: t("sideMenu.Organization"),
            Settings: t("sideMenu.Settings"),
            Content: t("sideMenu.Content"),
            GameUsage: t("sideMenu.GameUsage"),
            ContentAdmin: "ContentAdmin",
            Analytics: t("sideMenu.Analytics")
        }));

        const formPersonalInfo = ref({
            name: ""
        });

        const sideMenu = computed(() =>
            nestedMenu(store.state.sideMenu.menu, route)
        );

        watch(
            computed(() => route.path),
            () => {
                formattedMenu.value = $h.toRaw(sideMenu.value);
            }
        );

        onMounted(async () => {
            await store.dispatch("auth/checkOrg");

            cash("body")
                .removeClass("error-page")
                .removeClass("login")
                .addClass("main");
            formattedMenu.value = $h.toRaw(sideMenu.value);
        });

        const getImage = path => {
            return require(path);
        };

        return {
            getImage,
            formattedMenu,
            router,
            linkTo,
            enter,
            leave,
            formOrg,
            formPersonalInfo,
            loadingOrg,
            namesMenu
        };
    }
});
</script>

<style lang="scss" scoped></style>

<style>
html {
    background: white !important;
}

.bounce-enter-active {
    animation: bounce-in 1s;
}
.bounce-leave-active {
    animation: bounce-in 1s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
.view-leave-active {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}

.view-enter-active {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease;
    transition-delay: 0.5s;
}

.view-enter,
.view-leave-to {
    opacity: 0;
}

.view-enter-to,
.view-leave {
    opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

@media (max-width: 1280px) {
    .side-menu-fixed {
        margin-left: 85px;
    }
}

@media (min-width: 1280px) {
    .side-menu-fixed {
        margin-left: 230px;
    }
}
</style>

import * as types from './mutation-types'





const state = () => {
  return {
    darkMode: false,
    languages:{
      'es':"Español",
      'en':"English",
    },
    language:""
  }
}

// getters
const getters = {
  darkMode: state => state.darkMode
}

// actions
const actions = {
  setDarkMode ({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode })
  },
  persistLanguage ({commit}, data){
    localStorage.setItem("language",data )
  }
}

// mutations
const mutations = {
  [types.SET_DARK_MODE] (state, { darkMode }) {
    state.darkMode = darkMode
  },
  setLanguage(state,data)
  {
    state.language = data
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

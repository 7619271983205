<template>
    <Screen :loading="loading">
        <div class="">
            <div class="flex flex-row my-3 ">
                <h2
                    class="font-black text-2xl xl:text-3xl text-center xl:text-left "
                >
                    {{ bonfireDetails.name }}
                </h2>
                <div class="flex flex-row ml-auto gap-2 h-10">
                    <router-link
                        v-if="!admin"
                        type="button"
                        :to="'/content/'"
                        class="btn btn-primary-outline w-32 h-10"
                        >{{ $t("general.back") }}
                    </router-link>
                    <router-link
                        v-else
                        type="button"
                        :to="'/admin-content/'"
                        class="btn btn-primary-outline w-32 h-10"
                        >{{ $t("general.back") }}
                    </router-link>
                    <a
                        type="button"
                        data-target="#modal-add"
                        data-toggle="modal"
                        class="btn btn-primary-soft "
                        >{{ $t("content.btn_new_content") }}</a
                    >
                </div>
            </div>
            <div class="flex flex-row">
                <p>
                    {{ bonfireDetails.descripcion_princiapl }}
                </p>
            </div>

            <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
                <table
                    class="table table-report -mt-2 "
                    v-if="gameCategory.length > 0"
                >
                    <thead>
                        <tr>
                            <th class="whitespace-nowrap">
                                {{ $t("content.content_table_name") }}
                            </th>
                            <th class="whitespace-nowrap">
                                {{ $t("content.content_table_user") }}
                            </th>
                            <th class="text-center whitespace-nowrap">
                                {{ $t("content.content_table_status") }}
                            </th>
                            <th class="text-center whitespace-nowrap">
                                {{ $t("content.content_table_actions") }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="game in gameCategory"
                            :key="game"
                            class="intro-x"
                        >
                            <td>
                                <div
                                    class="text-text-100 text-xs whitespace-nowrap mt-0.5"
                                >
                                    <span v-if="admin">
                                        {{ game.name }} ES:
                                        {{ game.name_es }} EN:
                                        {{ game.name_en }}
                                    </span>
                                    <span v-else>
                                        {{ game.name }}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <p class="text-text-100" v-if="game.user">
                                    {{ game.user.name }}
                                </p>
                            </td>
                            <td class="w-40">
                                <button
                                    v-on:click="
                                        publishedContent(
                                            game.published,
                                            game.id
                                        )
                                    "
                                    v-if="
                                        $store.getters.getPermission(
                                            'content.enable'
                                        )
                                    "
                                    class="flex items-center justify-center"
                                    :class="{
                                        'text-theme-9': game.published,
                                        'text-theme-6': !game.published
                                    }"
                                >
                                    <CheckSquareIcon class="w-4 h-4 mr-2" />
                                    {{
                                        game.published
                                            ? $t("content.content_table_active")
                                            : $t(
                                                  "content.content_table_inactive"
                                              )
                                    }}
                                </button>
                                <p
                                    v-else
                                    class="flex items-center justify-center"
                                    :class="{
                                        'text-theme-9': game.published,
                                        'text-theme-6': !game.published
                                    }"
                                >
                                    {{
                                        game.published
                                            ? $t("content.content_table_active")
                                            : $t(
                                                  "content.content_table_inactive"
                                              )
                                    }}
                                </p>
                            </td>
                            <td class="table-report__action w-56">
                                <div class="flex justify-center items-center">
                                    <router-link
                                        :to="
                                            '/content/' +
                                                route.params.id +
                                                '/' +
                                                game.id +
                                                '?name=' +
                                                game.name
                                        "
                                        class="flex items-center mr-3"
                                        href="javascript:;"
                                        v-if="!admin"
                                    >
                                        <CheckSquareIcon class="w-4 h-4 mr-1" />
                                        {{ $t("content.content_table_edit") }}
                                    </router-link>
                                    <router-link
                                        v-else
                                        :to="
                                            '/admin-content/' +
                                                route.params.id +
                                                '/' +
                                                game.id +
                                                '?name=' +
                                                game.name
                                        "
                                        class="flex items-center mr-3"
                                        href="javascript:;"
                                    >
                                        <CheckSquareIcon class="w-4 h-4 mr-1" />
                                        {{ $t("content.content_table_edit") }}
                                    </router-link>
                                    <button v-on:click="deleteContent(game.id)">
                                        <Trash2Icon class="w-4 h-4 mr-1" />
                                        {{ $t("content.content_table_delete") }}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                v-if="gameCategory.length <= 0"
                class="flex flex-col h-full items-center justify-center"
            >
                <div
                    class="bg-white rounded-lg p-5 mt-20 w-2/4 flex flex-col items-center gap-4"
                >
                    <h2 class="font-black text-2xl xl:text-3xl text-center">
                        {{ $t("content.card_new_content_title") }}
                    </h2>
                    <p>
                        {{ $t("content.card_new_content_text") }}
                    </p>
                    <a
                        type="button"
                        data-target="#modal-add"
                        data-toggle="modal"
                        class="btn btn-primary-soft w-32 "
                        >{{ $t("content.btn_new_content") }}</a
                    >
                </div>
            </div>
            <div id="modal-add" class="modal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <a data-dismiss="modal" href="javascript:;">
                            <XIcon class="w-8 h-8 text-slate-400" />
                        </a>
                        <div class="modal-body px-5 py-10">
                            <div class="text-center">
                                <div class="mb-5">
                                    {{ $t("content.modal_new_content_title") }}
                                </div>
                                <p class="mr-left text-left text-text-100 my-1">
                                    {{
                                        $t(
                                            "content.modal_new_content_name_labble"
                                        )
                                    }}
                                </p>
                                <input
                                    type="text"
                                    class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4 mb-2"
                                    :placeholder="
                                        $t(
                                            'content.modal_new_content_name_placeholder'
                                        )
                                    "
                                    v-model="contentName"
                                />
                                <button
                                    type="button"
                                    class="btn btn-primary-soft w-48"
                                    v-on:click="newContentBtn()"
                                >
                                    {{ $t("content.modal_new_content_btn") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Screen>
</template>

<script>
import { axiosToken } from "@/libs/http";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import Screen from "@/global-components/loading/Screen";
import { notify } from "notiwind";
import { useStore } from "vuex";
export default defineComponent({
    components: {
        Screen
    },

    setup() {
        const router = useRouter();
        const route = useRoute();
        const gameCategory = ref([]);
        const selectedGame = ref(null);
        const contentName = ref("");
        const admin = ref(false);
        const { locale } = useI18n();
        const loading = ref(true);
        const bonfireDetails = ref({});
        let firstTime = true;
        let loadingCounter = 0;
        const store = useStore();
        const { t } = useI18n();

        const loadingScreen = () => {
            loadingCounter++;
            if (loadingCounter >= 2) {
                loading.value = false;
            }
        };

        const selectGame = game_name => {
            selectedGame.value = game_name;
        };

        const setAdmin = () => {
            if (window.location.pathname.split("/")[1] == "admin-content") {
                admin.value = true;
            }
        };

        watch(
            () => locale.value,
            () => {
                axiosToken({
                    url: "activities/bonfire/" + route.params.id,
                    method: "get",
                    body: {
                        language: locale.value
                    }
                })
                    .then(response => {
                        loadingScreen();
                        bonfireDetails.value = response.data;
                    })
                    .catch(e => {
                        router.push("/content");
                    });
            }
        );

        const newContentBtn = () => {
            let admin_body = {};

            if (contentName.value.trim() === "") {
                notify(
                    {
                        group: "error",
                        title: t("general.error"),
                        text: t("content.alert_new_category_empty_name")
                    },
                    2000
                );
                return;
            }

            if (!admin.value) {
                admin_body = {
                    custom: true
                };
            }
            axiosToken({
                url: "content/category",
                method: "post",
                body: {
                    name_id: route.params.id,
                    name: contentName.value,
                    ...admin_body
                }
            }).then(response => {
                fetchData();
                contentName.value = "";
                cash("#modal-add").modal("hide");
                notify(
                    {
                        group: "top",
                        title: t("general.success"),
                        text: t("content.alert_add_content_success")
                    },
                    2000
                );
            });
        };
        const fetchData = () => {
            let body = {
                name_id: route.params.id
            };
            if (admin.value == false) {
                body["custom"] = true;
            }
            axiosToken({
                url: "content/category",
                method: "get",
                body
            }).then(response => {
                if (!store.getters.getPermission("content.view_all")) {
                    gameCategory.value = response.data.filter(
                        e => e.user.id == store.state.auth.userInfo.id
                    );
                } else {
                    gameCategory.value = response.data;
                }
                loadingScreen();
            });
            if (firstTime) {
                firstTime = false;
                axiosToken({
                    url: "activities/bonfire/" + route.params.id,
                    method: "get",
                    body: {
                        language: locale.value
                    }
                })
                    .then(response => {
                        loadingScreen();
                        bonfireDetails.value = response.data;
                    })
                    .catch(e => {
                        router.push("/content");
                    });
            }
        };
        const deleteContent = id => {
            if (confirm(t("content.alert_delete_content_question"))) {
                axiosToken({
                    url: "content/category/" + id,
                    method: "delete"
                }).then(response => {
                    fetchData();
                    notify(
                        {
                            group: "top",
                            title: t("general.success"),
                            text: t("content.alert_delete_content")
                        },
                        2000
                    );
                });
            }
        };

        const publishedContent = (mode, id) => {
            axiosToken({
                url: "content/activate_content/" + id,
                method: "post",
                body: {
                    published: !mode
                }
            }).then(response => {
                console.log(response);
                if (response.data.published) {
                    notify(
                        {
                            group: "top",
                            title: t("general.success"),
                            text: t("content.alert_enabled")
                        },
                        2000
                    );
                } else {
                    if (mode) {
                        notify(
                            {
                                group: "top",
                                title: t("general.success"),
                                text: t("content.alert_disabled")
                            },
                            2000
                        );
                    } else {
                        notify(
                            {
                                group: "error",
                                title: t("general.error"),
                                text: t("content.alert_cant_enable")
                            },
                            2000
                        );
                    }
                }
                fetchData();
            });
        };

        onMounted(() => {
            setAdmin();
            fetchData();
        });
        console.log(route.params);

        return {
            gameCategory,
            selectGame,
            selectedGame,
            route,
            contentName,
            newContentBtn,
            deleteContent,
            publishedContent,
            admin,
            bonfireDetails,
            loading
        };
    }
});
</script>

<template>
    <!-- BEGIN: Top Bar -->
    <div class="top-bar">
        <!-- BEGIN: Breadcrumb -->
        <div class="-intro-x breadcrumb mr-auto hidden sm:flex"></div>
        <!-- END: Breadcrumb -->
        <!-- BEGIN: Search -->
        <!-- <div class="intro-x relative mr-3 sm:mr-6">
      <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input form-control border-transparent placeholder-theme-13"
          placeholder="Search..."
          @focus="showSearchDropdown"
          @blur="hideSearchDropdown"
        />
        <SearchIcon class="search__icon dark:text-gray-300" />
      </div>
      <a class="notification sm:hidden" href="">
        <SearchIcon class="notification__icon dark:text-gray-300" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">Pages</div>
          <div class="mb-5">
            <a href="" class="flex items-center">
              <div
                class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"
              >
                <InboxIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Mail Settings</div>
            </a>
            <a href="" class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"
              >
                <UsersIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Users & Permissions</div>
            </a>
            <a href="" class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"
              >
                <CreditCardIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Transactions Report</div>
            </a>
          </div>
          <div class="search-result__content__title">Users</div>
          <div class="mb-5">
            <a
              v-for="(faker, fakerKey) in $_.take($f(), 4)"
              :key="fakerKey"
              href
              class="flex items-center mt-2"
            >
              <div class="w-8 h-8 image-fit">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="rounded-full"
                  :src="require(`@/assets/images/${faker.photos[0]}`).default"
                />
              </div>
              <div class="ml-3">{{ faker.users[0].name }}</div>
              <div
                class="ml-auto w-48 truncate text-gray-600 text-xs text-right"
              >
                {{ faker.users[0].email }}
              </div>
            </a>
          </div>
          <div class="search-result__content__title">Products</div>
          <a
            v-for="(faker, fakerKey) in $_.take($f(), 4)"
            :key="fakerKey"
            href
            class="flex items-center mt-2"
          >
            <div class="w-8 h-8 image-fit">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                :src="require(`@/assets/images/${faker.images[0]}`).default"
              />
            </div>
            <div class="ml-3">{{ faker.products[0].name }}</div>
            <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">
              {{ faker.products[0].category }}
            </div>
          </a>
        </div>
      </div>
    </div> -->
        <!-- END: Search -->
        <!-- BEGIN: Notifications -->
        <!-- <div class="intro-x dropdown mr-auto sm:mr-6">
      <div
        class="dropdown-toggle notification notification--bullet cursor-pointer"
        role="button"
        aria-expanded="false"
      >
        <BellIcon class="notification__icon dark:text-gray-300" />
      </div>
      <div class="notification-content pt-2 dropdown-menu">
        <div
          class="notification-content__box dropdown-menu__content box dark:bg-dark-6"
        >
          <div class="notification-content__title">Notifications</div>
          <div
            v-for="(faker, fakerKey) in $_.take($f(), 5)"
            :key="fakerKey"
            class="cursor-pointer relative flex items-center"
            :class="{ 'mt-5': fakerKey }"
          >
            <div class="w-12 h-12 flex-none image-fit mr-1">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                :src="require(`@/assets/images/${faker.photos[0]}`).default"
              />
              <div
                class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
              ></div>
            </div>
            <div class="ml-2 overflow-hidden">
              <div class="flex items-center">
                <a href="javascript:;" class="font-medium truncate mr-5">{{
                  faker.users[0].name
                }}</a>
                <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">
                  {{ faker.times[0] }}
                </div>
              </div>
              <div class="w-full truncate text-gray-600 mt-0.5">
                {{ faker.news[0].shortContent }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
        <!-- END: Notifications -->
        <!-- BEGIN: Account Menu -->
        <div class="flex flex-row justify-end items-center">
            <div
                @click="modalHelp"
                class="flex flex-row justify-center items-center cursor-pointer"
            >
                <inline-svg
                    :src="require('@/assets/icons/question-circle-regular.svg')"
                    alt="Help"
                    class="w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
                    fill="#b3b3b3"
                />
            </div>
            <div class="flex flex-row ml-auto">
                <select
                    name=""
                    id=""
                    class="py-3 px-4 m-3 rounded-md block background-input w-full"
                    v-model="$i18n.locale"
                    v-on:change="
                        $store.dispatch('main/persistLanguage', $i18n.locale)
                    "
                >
                    <option
                        v-for="(lang, key) in $store.state.main.languages"
                        :key="key"
                        :value="key"
                    >
                        {{ lang }}
                    </option>
                </select>
            </div>
            <p class="mx-3">
                {{ $store.state.auth.userInfo.name }}
            </p>
            <div class="intro-x dropdown w-8 h-8">
                <div
                    class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
                    role="button"
                    aria-expanded="false"
                >
                    <img
                        alt="Icewall Tailwind HTML Admin Template"
                        src="@/assets/images/user.png"
                    />
                </div>
                <div class="dropdown-menu w-56">
                    <div
                        class="dropdown-menu__content box bg-white dark:bg-dark-6 text-text-100"
                    >
                        <div
                            class="p-4 border-b border-white dark:border-dark-3"
                        >
                            <div class="font-medium">
                                {{ $store.state.auth.userInfo.name }}
                            </div>
                        </div>
                        <div class="p-4 border-b truncate">
                            <div class="truncate">
                                {{ $store.state.auth.userInfo.email }}
                            </div>
                        </div>

                        <div
                            class="p-2 border-t border-white dark:border-dark-3 w-full"
                        >
                            <button
                                v-on:click="logout()"
                                class="flex w-full items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                            >
                                <ToggleRightIcon class="w-4 h-4 mr-2" /> Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Account Menu -->
        <ConfirmModal
            :title="$t('topMenu.help_modal')"
            :showButtonAccept="false"
            :textButtonCancel="$t('general.close')"
            backgroundColor="transparent"
            confirmModalCustom="-help"
        >
            <div>
                <iframe
                    width="800"
                    height="500"
                    :src="
                        $i18n.locale == 'es'
                            ? 'https://www.youtube.com/embed/2TANlQwfRp4'
                            : 'https://www.youtube.com/embed/dceIFf17jQM'
                    "
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
        </ConfirmModal>
    </div>

    <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default defineComponent({
    components: {
        ConfirmModal
    },
    setup() {
        const searchDropdown = ref(false);

        const modalHelp = () => {
            console.log("modalHelp");
            cash("#confirm-modal-help").modal("show");
        };

        const showSearchDropdown = () => {
            searchDropdown.value = true;
        };

        const hideSearchDropdown = () => {
            searchDropdown.value = false;
        };

        const store = useStore();

        const logout = () => {
            store.dispatch("auth/logout");
        };

        return {
            searchDropdown,
            showSearchDropdown,
            hideSearchDropdown,
            logout,
            modalHelp
        };
    }
});
</script>

<template>
    <div class="bg-white rounded-xl p-5 flex flex-col" v-if="bonfireMenu">
        <div class="flex flex-row">
            <h2 class="font-black text-xl xl:text-xl text-center xl:text-left">
                <span v-if="editingShedule">
                    {{ $t("bonfire.edit_schedule") }}
                </span>
                <span v-else>
                    {{ $t("bonfire.title_shedule") }}
                </span>
            </h2>
            <div class="ml-auto">
                <XIcon
                    class="w-8 h-8 text-slate-400 cursor-pointer"
                    v-on:click="setBonfireMenu(false)"
                />
            </div>
        </div>
        <div class="flex flex-col">
            <p class="text-text-100">
                <span v-if="!editingShedule">
                    {{ $t("bonfire.text_shedule") }}
                </span>
            </p>
            <div
                class="flex flex-row   rounded-lg bg-white list-select text-center"
            >
                <button
                    v-for="(type, index) in sheduleTypes"
                    :disabled="editingShedule || !type.permission_add"
                    :key="index"
                    v-on:click="setSheduleType(index)"
                    :class="
                        sheduleType == index
                            ? 'border-soft-100 font-extrabold bg-white'
                            : 'border-theme-2 '
                    "
                    class="border-2  py-2 px-5 cursor-pointer tooltip w-1/3 child disabled:opacity-50 "
                >
                    {{ type.btn_text }}
                    <div class="tooltiptext font-normal">
                        {{ type.tooltip }}
                    </div>
                </button>
            </div>
            <div
                class="flex flex-col"
                v-if="sheduleType == 0 && sheduleTypes[0].permission_edit"
            >
                <p class="mr-left text-left text-text-100 my-1">
                    {{ $t("teams.newuser_placeholder_name") }}
                </p>
                <input
                    v-model="form.form.name.$model"
                    type="text"
                    class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mb-2"
                    :placeholder="$t('teams.newuser_placeholder_name')"
                />
                <div id="basic-datepicker" class="flex flex-row gap-5">
                    <p class="my-auto">{{ $t("general.date") }}:</p>
                    <div class="preview w-full">
                        <Litepicker
                            v-model="form.form.date.$model"
                            :options="{
                                autoRefresh: true,
                                showWeekNumbers: true,
                                dropdowns: {
                                    minYear: 2022,
                                    maxYear: null,
                                    months: true,
                                    years: true
                                }
                            }"
                            class=" focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2 "
                        />
                    </div>
                </div>
                <div id="basic-datepicker" class="flex flex-row gap-5">
                    <p class="my-auto">{{ $t("general.hour") }}:</p>
                    <div class="preview w-full">
                        <input
                            v-model="form.form.hour.$model"
                            type="time"
                            step="600"
                            class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2"
                        />
                    </div>
                </div>
                <div id="basic-datepicker" class="flex flex-row gap-5">
                    <p class="my-auto flex flex-col">
                        {{ $t("bonfire.time_zone") }}
                    </p>
                    <select
                        class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border bg-white border-border-100 block mt-4 mb-2"
                        name=""
                        id=""
                        v-model="myTimeZone"
                    >
                        <option
                            v-for="zone in timeZones"
                            :key="zone"
                            :value="zone.value"
                            >{{ zone.text }}</option
                        >
                    </select>
                </div>
                <p>
                    {{ $t("bonfire.modal_shedule_message") }}
                </p>
                <textarea
                    v-model="form.form.msg.$model"
                    type="text"
                    rows="3"
                    class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2 "
                    :placeholder="$t('bonfire.placeholder_msg')"
                />
                <p>
                    {{ $t("bonfire.modal_shedule_team") }}
                </p>
                <select
                    class="focus:font-bold w-full rounded-lg font-300 py-3 bg-white px-4 border border-border-100 block mt-4 mb-2"
                    name="form-schedule-one"
                    id=""
                    v-model="form.form.team.$model"
                >
                    <option value="all">All Users</option>
                    <option
                        v-for="team in teams"
                        :key="team"
                        :value="team.id"
                        >{{ team.name }}</option
                    >
                </select>
                <p>
                    {{ $t("bonfire.duration_game") }}
                </p>
                <div
                    class="flex flex-col sm:flex-row mt-2 items-center justify-center mb-2"
                >
                    <div
                        class="form-check mr-2 "
                        v-for="(duration, index) in durations"
                        :key="duration"
                    >
                        <input
                            :id="'radio-switch' + index"
                            class="form-check-input text-text-100"
                            type="radio"
                            name="horizontal_radio_button"
                            :value="index"
                            v-model="form.form.duration.$model"
                        /><label
                            class="form-check-label text-text-100"
                            :for="'radio-switch' + index"
                            >{{ duration.text }}</label
                        >
                    </div>
                </div>
                <div class="text-text-100 flex flex-row items-center mb-3">
                    <input
                        id="horizontal-form-3"
                        class="form-check-input"
                        type="checkbox"
                        v-model="form.form.remainder_email.$model"
                    />
                    <label
                        class="form-check-label text-text-100"
                        for="horizontal-form-3"
                    >
                        {{ $t("bonfire.remember_me") }}
                    </label>
                </div>
                <div class="text-text-100 flex flex-row items-center mb-3">
                    <input
                        id="horizontal-form-4"
                        name="form-schedule-one"
                        class="form-check-input"
                        type="checkbox"
                        v-model="form.form.invite_email.$model"
                    />
                    <label
                        class="form-check-label text-text-100"
                        for="horizontal-form-4"
                    >
                        {{ $t("bonfire.send_invitations") }}
                        <span v-if="form.form.invite_email.$model">
                            -
                            {{
                                $t("bonfire.invitations_send", {
                                    n_invitations: teamLength
                                })
                            }}</span
                        >
                    </label>
                </div>
                <Btn
                    v-if="!editingShedule"
                    type="button"
                    :disabled="form.form.$invalid"
                    :isLoading="sendingLoading"
                    class="btn btn-primary-soft "
                    v-on:click="bookCall('new')"
                >
                    {{ $t("bonfire.btn_shedule") }}
                </Btn>
                <div class="flex flex-row gap-5 justify-center" v-else>
                    <Btn
                        type="button"
                        :disabled="form.form.$invalid"
                        :isLoading="sendingLoading"
                        class="btn btn-primary-soft w-3/4"
                        v-on:click="bookCall('edit')"
                    >
                        {{ $t("general.save") }}
                    </Btn>
                    <Btn
                        type="button"
                        :disabled="form.form.$invalid"
                        :isLoading="sendingLoading"
                        class="btn btn-danger "
                        v-if="
                            $store.getters.getPermission('bonfire.delete_event')
                        "
                        v-on:click="bookCall('delete')"
                    >
                        <inline-svg
                            :src="require('@/assets/icons/trash-solid.svg')"
                            alt=""
                            class="h-full w-4"
                            style="fill:white"
                        />
                    </Btn>
                </div>
            </div>
            <div class="flex flex-col" v-if="sheduleType == 1">
                <p class="mr-left text-left text-text-100 my-1">
                    {{ $t("teams.newuser_placeholder_name") }}
                </p>
                <input
                    v-model="form_2.form.name.$model"
                    type="text"
                    class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mb-2"
                    :placeholder="$t('teams.newuser_placeholder_name')"
                />
                <div
                    class="input-errors"
                    v-for="(error, index) of form_2.form.name.$errors"
                    :key="index"
                >
                    <div class="error-msg">
                        {{ error.$message }}
                    </div>
                </div>
                <p>
                    {{ $t("bonfire.day_choose") }}
                </p>
                <select
                    class=" focus:font-bold w-full rounded-lg font-300 py-3 px-4 border bg-white border-border-100 block mt-4 mb-2"
                    name=""
                    id=""
                    v-model="form_2.form.often.$model"
                    v-on:change="updateOften()"
                >
                    <option value="0">{{
                        $t("bonfire.day_choose_weekly")
                    }}</option>
                    <option value="1">{{
                        $t("bonfire.day_choose_week")
                    }}</option>
                    <option value="2">{{
                        $t("bonfire.day_choose_week_2")
                    }}</option>
                    <option value="3">{{
                        $t("bonfire.day_choose_month")
                    }}</option>
                </select>
                <div
                    class="flex flex-row justify-between items-center w-full px-5"
                >
                    <div
                        v-on:click="dayChoose(index)"
                        :class="
                            dayActive[index] == true
                                ? 'border-soft-100'
                                : 'border-theme-2'
                        "
                        class="cursor-pointer w-8 h-8 rounded-full rounded-fulltext-center border-2 bg-theme-2 text-text-100 flex flex-col justify-center items-center"
                        v-for="(day, index) in dayWeek"
                        :key="day"
                    >
                        {{ day }}
                    </div>
                </div>

                <div id="basic-datepicker" class="flex flex-col ">
                    <p class="my-auto">{{ $t("general.hour") }}:</p>
                    <div class="preview w-full">
                        <input
                            v-model="form_2.form.hour.$model"
                            type="time"
                            step="600"
                            class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2"
                        />
                    </div>
                </div>
                <select
                    v-if="sheduleType == 1"
                    class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border bg-white border-border-100 block mt-4 mb-2"
                    name=""
                    id=""
                    v-model="myTimeZone"
                >
                    <option
                        v-for="zone in timeZones"
                        :key="zone"
                        :value="zone.value"
                        >{{ zone.text }}</option
                    >
                </select>

                <p>
                    {{ $t("bonfire.modal_shedule_message") }}
                </p>
                <textarea
                    v-model="form_2.form.msg.$model"
                    type="text"
                    rows="3"
                    class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2 "
                    :placeholder="$t('bonfire.placeholder_msg')"
                />
                <p>
                    {{ $t("bonfire.modal_shedule_team") }}
                </p>
                <select
                    v-if="sheduleType == 1"
                    class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border bg-white border-border-100 block mt-4 mb-2"
                    name="form-schedule-two"
                    id=""
                    v-model="form_2.form.team.$model"
                >
                    <option value="all">All Users</option
                    >"once"
                    <option
                        v-for="team in teams"
                        :key="team"
                        :value="team.id"
                        >{{ team.name }}</option
                    >
                </select>
                <p>
                    {{ $t("bonfire.duration_game") }}
                </p>
                <div
                    class="flex flex-col sm:flex-row mt-2 items-center justify-center mb-2"
                >
                    <div
                        class="form-check mr-2 "
                        v-for="(duration, index) in durations"
                        :key="duration"
                    >
                        <input
                            :id="'radio-switch' + index"
                            class="form-check-input text-text-100"
                            type="radio"
                            name="horizontal_radio_button"
                            :value="index"
                            v-model="form_2.form.duration.$model"
                        /><label
                            class="form-check-label text-text-100"
                            :for="'radio-switch' + index"
                            >{{ duration.text }}</label
                        >
                    </div>
                </div>
                <div class="text-text-100 flex flex-row items-center mb-3">
                    <input
                        id="horizontal-form-3"
                        class="form-check-input"
                        type="checkbox"
                        v-model="form_2.form.remainder_email.$model"
                    />
                    <label
                        class="form-check-label text-text-100"
                        for="horizontal-form-3"
                    >
                        {{ $t("bonfire.remember_me") }}
                    </label>
                </div>
                <div class="text-text-100 flex flex-row items-center mb-3">
                    <input
                        id="horizontal-form-4"
                        class="form-check-input"
                        type="checkbox"
                        name="form-schedule-two"
                        v-model="form_2.form.invite_email.$model"
                    />
                    <label
                        class="form-check-label text-text-100"
                        for="horizontal-form-4"
                    >
                        {{ $t("bonfire.send_invitations") }}
                        <span v-if="form_2.form.invite_email.$model">
                            -
                            {{
                                $t("bonfire.invitations_send", {
                                    n_invitations: teamLength_2
                                })
                            }}</span
                        >
                    </label>
                </div>
                <Btn
                    v-if="!editingShedule"
                    type="button"
                    :disabled="form_2.form.$invalid"
                    :isLoading="sendingLoading"
                    class="btn btn-primary-soft "
                    v-on:click="bookingApiRecurrent('new')"
                >
                    {{ $t("bonfire.btn_shedule") }}
                </Btn>
                <div class="flex flex-row gap-5 justify-center" v-else>
                    <Btn
                        type="button"
                        :disabled="form_2.form.$invalid"
                        :isLoading="sendingLoading"
                        class="btn btn-primary-soft w-3/4"
                        v-on:click="bookingApiRecurrent('edit')"
                    >
                        {{ $t("general.update") }}
                    </Btn>
                    <Btn
                        type="button"
                        :isLoading="sendingLoading"
                        class="btn btn-danger "
                        v-if="
                            $store.getters.getPermission('bonfire.delete_event')
                        "
                        v-on:click="bookingApiRecurrent('delete')"
                    >
                        <inline-svg
                            :src="require('@/assets/icons/trash-solid.svg')"
                            alt=""
                            class="h-full w-4"
                            style="fill:white"
                        />
                    </Btn>
                </div>
            </div>
            <div class="flex flex-col" v-if="sheduleType == 2">
                <p class="mr-left text-left text-text-100 my-1">
                    {{ $t("teams.newuser_placeholder_name") }}
                </p>
                <input
                    v-model="form_3.form.name.$model"
                    type="text"
                    class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mb-2"
                    :placeholder="$t('teams.newuser_placeholder_name')"
                />
                <div
                    class="input-errors"
                    v-for="(error, index) of form_3.form.name.$errors"
                    :key="index"
                >
                    <div class="error-msg">
                        {{ error.$message }}
                    </div>
                </div>
                <Btn
                    v-if="!editingShedule"
                    type="button"
                    :disabled="form_3.form.$invalid"
                    :isLoading="sendingLoading"
                    class="btn btn-primary-soft "
                    v-on:click="createRoom('new')"
                >
                    {{ $t("bonfire.btn_create") }}
                </Btn>
                <div class="flex flex-row gap-5 justify-center" v-else>
                    <Btn
                        type="button"
                        :disabled="form_3.form.$invalid"
                        :isLoading="sendingLoading"
                        class="btn btn-primary-soft w-3/4"
                        v-on:click="createRoom('edit')"
                    >
                        {{ $t("general.update") }}
                    </Btn>
                    <Btn
                        type="button"
                        :isLoading="sendingLoading"
                        class="btn btn-danger "
                        v-if="
                            $store.getters.getPermission('bonfire.delete_room')
                        "
                        v-on:click="createRoom('delete')"
                    >
                        <inline-svg
                            :src="require('@/assets/icons/trash-solid.svg')"
                            alt=""
                            class="h-full w-4"
                            style="fill:white"
                        />
                    </Btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject, onMounted, provide, ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { timeZones } from "@/libs/timeZones";
import Btn from "@/global-components/btn/Main.vue";
import { integer } from "@vuelidate/validators";
import { useStore } from "vuex";

export default {
    props: {
        form: Object,
        form_2: Object,
        form_3: Object,
        sendingLoading: Boolean,
        bookCall: Function,
        bonfireMenu: Boolean,
        teams: Array,
        durations: Array,
        setBonfireMenu: Function,
        editingShedule: Boolean,
        setSheduleType: Function,
        sheduleType: Number,
        createRoom: Function,
        sheduleForm: Object,
        sheduleForm_2: Object,
        bookingApiRecurrent: Function
    },

    components: {
        Btn
    },

    setup(props) {
        const { t } = useI18n();
        const currentTimeZone = Intl.DateTimeFormat().resolvedOptions()
            .timeZone;
        const myTimeZone = inject("myTimeZone");

        let teamLength = ref(0);
        let teamLength_2 = ref(0);

        const store = useStore();

        onMounted(() => {
            myTimeZone.value = timeZones.find(e =>
                e.utc.find(y => y == currentTimeZone)
            ).value;
        });
        const dayActive = ref([
            false,
            false,
            false,
            false,
            false,
            false,
            false
        ]);

        const updateOften = () => {
            dayActive.value = [false, false, false, false, false, false, false];
        };
        const dayChoose = id => {
            if (props.form_2.form.often.$model != 0) {
                for (let x = 0; x < dayActive.value.length; x++) {
                    if (x != id) {
                        dayActive.value[x] = false;
                    }
                }
            }
            dayActive.value[id] = !dayActive.value[id];
            console.log(props);
            props.sheduleForm.form.day = "";
            dayActive.value.map((day, index) => {
                if (day == true) {
                    props.sheduleForm.form.day += String(index + 1);
                }
            });
            console.log(props.form_2);
        };

        const dayWeek = [
            t("bonfire.day_1"),
            t("bonfire.day_2"),
            t("bonfire.day_3"),
            t("bonfire.day_4"),
            t("bonfire.day_5"),
            t("bonfire.day_6"),
            t("bonfire.day_7")
        ];

        const sheduleTypes = computed(() => [
            {
                btn_text: t("bonfire.stanalone_game"),
                tooltip: t("bonfire.tooltip_puntual"),
                permission_add: store.getters.getPermission(
                    "bonfire.add_event"
                ),
                permission_edit: store.getters.getPermission(
                    "bonfire.edit_event"
                )
            },
            {
                btn_text: t("bonfire.bonfire_ritual"),
                tooltip: t("bonfire.tooltip_ritual"),
                permission_add: store.getters.getPermission(
                    "bonfire.add_event"
                ),
                permission_edit: store.getters.getPermission(
                    "bonfire.edit_event"
                )
            },
            {
                btn_text: t("bonfire.open_room"),
                tooltip: t("bonfire.tooltip_open_room"),
                permission_add: store.getters.getPermission("bonfire.add_room"),
                permission_edit: store.getters.getPermission(
                    "bonfire.edit_room"
                )
            }
        ]);

        const teamSize = teamSelected => {
            const arrayTeams = props.teams;
            let length = 0;

            console.log(arrayTeams);

            if (teamSelected != "") {
                if (teamSelected != "all") {
                    let searchTeam = arrayTeams.find(d => {
                        return d.id == teamSelected;
                    });

                    searchTeam.user_number
                        ? (length = searchTeam.user_number)
                        : length;
                } else {
                    length = store.state.auth.organitzation.user_number;
                }
                return length;
            }
        };

        watch(
            () => props.sheduleForm.form.day,
            () => {
                if (typeof props.sheduleForm.form.day == "number") {
                    updateOften();
                    console.log("change");
                    const stringDays = String(props.sheduleForm.form.day);
                    console.log(stringDays);
                    for (let a = 0; a < stringDays.length; a++) {
                        dayActive.value[parseInt(stringDays[a]) - 1] = true;
                    }
                }
            }
        );

        watch(
            () => props.editingShedule,
            () => {
                console.log("in");
                if (props.editingShedule == false) {
                    updateOften();
                }
            }
        );

        watch(
            () => props.form.form.team.$model,
            () => {
                teamLength.value = teamSize(props.form.form.team.$model);
            }
        );

        watch(
            () => props.form_2.form.team.$model,
            () => {
                teamLength_2.value = teamSize(props.form_2.form.team.$model);
            }
        );

        return {
            dayActive,
            updateOften,
            dayChoose,
            dayWeek,
            sheduleTypes,
            timeZones,
            myTimeZone,
            teamSize,
            teamLength,
            teamLength_2
        };
    }
};
</script>

<style lang="scss" scoped>
.tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
.tooltip:hover .tooltiptext {
    visibility: visible;
}
.tooltip .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    top: 100%;
    left: 50%;
    margin-left: -50%; /* Use half of the width (120/2 = 60), to center the tooltip */
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    width: 100%;
}

.activateType {
    @apply bg-soft-100 text-white;
}

.activate-day {
    @apply bg-soft-100 text-white;
}

.list-select > button:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.list-select > button:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
</style>

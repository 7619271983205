<template>
    <router-view />
    <NotificationGroup group="top">
        <div
            class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none"
            style="z-index:999999"
        >
            <div class="w-full max-w-sm">
                <Notification
                    v-slot="{ notifications, close }"
                    enter="transform ease-out duration-300 transition"
                    enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                    enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-500"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                    move="transition duration-500"
                    move-delay="delay-300"
                >
                    <div
                        class="w-full max-w-sm mt-4 overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5"
                        v-for="notification in notifications"
                        :key="notification.id"
                    >
                        <div class="p-4">
                            <div class="flex  items-center justify-center">
                                <div class="flex-shrink-0">
                                    <svg
                                        class="w-6 h-6 text-success-100 my-auto"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                </div>
                                <div class="ml-3 w-0 flex-1 pt-0.5">
                                    <p class="font-semibold text-text-120">{{ notification.title }}</p>
                                    <template v-if="notification.template">
                                        <p
                                            v-if="notification.template == 'registration_success'"
                                            class="text-sm font-semibold text-text-100"
                                        >{{ $t('register_activate.alert_register_email')}}</p>
                                        <p
                                            v-if="notification.template == 'image_upload'"
                                            class="text-sm font-semibold text-text-100"
                                        >{{ $t('alerts.image_upload')}}</p>
                                    </template>
                                    <p class="text-sm font-semibold text-text-100">{{ notification.text }}</p>
                                </div>
                                <div class="flex flex-shrink-0 ml-4">
                                    <button
                                        @click="close(notification.id)"
                                        class="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                                    >
                                        <span class="sr-only">Close</span>
                                        <svg
                                            class="w-5 h-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Notification>
            </div>
        </div>
    </NotificationGroup>

    <NotificationGroup group="error">
        <div
            class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none"
            style="z-index:999999"
        >
            <div class="w-full max-w-sm ">
                <Notification
                    v-slot="{ notifications }"
                    enter="transform ease-out duration-300 transition"
                    enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                    enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-500"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                    move="transition duration-500"
                    move-delay="delay-300"
                >
                    <div
                        class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md"
                        v-for="notification in notifications"
                        :key="notification.id"
                    >
                        <div class="flex items-center justify-center w-12 bg-red-500">
                            <svg
                                class="w-6 h-6 text-danger-100 fill-current my-auto"
                                viewBox="0 0 40 40"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"></path>
                            </svg>
                        </div>

                        <div class="px-4 py-2 -mx-3">
                            <div class="mx-3">
                                <p class="font-semibold text-text-120">{{ notification.title }}</p>
                                <p class="text-sm font-semibold text-text-100">{{ notification.text }}</p>
                            </div>
                        </div>
                    </div>
                </Notification>
            </div>
        </div>
    </NotificationGroup>
</template>
<script>
import { onMounted } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
    setup() {
        onMounted(() => {
            const store = useStore();
            const { t, locale, fallbackLocale, messages } = useI18n();
            const currentLanguage = localStorage.getItem("language");
            let notInLang = false;
            if (currentLanguage) {
                locale.value = currentLanguage;
                for (let lang in messages.value) {
                    if ([lang][0] == locale.value) {
                        notInLang = true;
                    }
                }
                if (!notInLang) {
                    locale.value = "en";
                }
                return;
            }
            for (let lang in messages.value) {
                if ([lang][0] == locale.value) {
                    notInLang = true;
                }
            }
            if (!notInLang) {
                locale.value = "en";
            }

            if (navigator.language == "es-ES") {
                locale.value = "es";
            }
        });
    },
};
</script>

<style>
    .aspect-square{
        aspect-ratio: 1;
    }
</style>
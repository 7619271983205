const es = {
    // Register
    register: {
        early_1: "Lo sentimos, Remote Boost todavía no está disponible.",
        early_2: "Estará disponible a partir de Abril de 2022. Déjanos tu correo si quieres que te notifiquemos del lanzamiento:",
        early_3: "La convocatoria para nuestro programa de Early Birds sigue abierta. Si quieres ser de los primeros en probar Remote Boost a un precio reducido a cambio de feedback mensual, escríbenos a ",

        btn_notify: "Notifícame",
        early_notifty: "Gracias por tu interés, nos pondremos en contacto contigo lo antes posible.",

        // register Form 
        signup: "Regístrate",
        placeholder_email: "Correo corporativo",
        placeholder_password: "Contraseña",
        placeholder_password_confirmation: "Repetir contraseña",
        terms_1: "Estoy de acuerdo con la",
        terms_2: "Política de privacidad de RemoteBoost",
        btn_login: "Iniciar sesión",
        btn_register: "Registrarse"


    },


    email_validate: {
        text: "Tu correo ha sido validado.",
        btn_log_in: "Iniciar sesión",
        title_error: "Error",
        text_send_again: "Enviar de nuevo",
        btn_re_send: "Enviar correo",
    },

    register_activate: {
        alert_new_email: "Te hemos enviado un nuevo correo.",
        alert_register_email: "Te hemos enviado un correo para confirmar tu cuenta.",
    },

    //register
    login: {
        signIn_title: "Iniciar sesión",
        password_error: "Contraseña incorrecta",
        forgot_password: "¿Contraseña olvidada?",
        login_btn: "Iniciar sesión",
        signup_btn: "Registrarse",
        term_text: "Al registrarte, aceptas nuestros ",
        term: "Términos y Condiciones",
        privacy: "Política de privacidad",
        error_social: "Error",
        btn_error_social: "Volver al inicio de sesión",
    },
    // ONboarding
    onBoarding: {
        title_user: "Cuéntanos más sobre ti",
        title_org: "...y sobre tu empresa",
        labble_name: "Nombre Completo",
        placeholder_name: "Tony Stark",
        labble_title: "Cargo en la empresa (opcional)",
        placeholder_title: "HR Manager",
        btn_next: "Siguiente",
        label_org: "Nombre de la empresa",
        placeholder_org: "Stark Enterprises",
        btn_finish: "Finalizar",
        label_industry: "Indústria",
        label_language: "Idioma",
        label_size: "Tamaño",
        placeholder_industry: "FinTech",
    },

    //alerts
    alerts: {
        image_upload: "Imagen subida"
    },

    // trial
    trial: {
        endTrial: "Tu periodo de prueba se ha terminado. Ponte en contacto con el equipo de ventas en contact{'@'}remoteboost.io si quieres que tu equipo siga disfrutando de Remote Boost."
    },

    // side menu
    sideMenu: {
        Team: 'Equipo',
        Bonfire: 'Bonfire',
        Activities: 'Actividades',
        Bookings: 'Reservas',
        Organization: 'Organización',
        Settings: 'Ajustes',
        Content: 'Contenido',
        GameUsage: 'Historial',
        Analytics: "Analíticas",
    },

    topMenu: {
        help_modal: "Remote Boost - Presentación de los menús"
    },

    general: {
        edit: "Editar",
        delete: "Borrar",
        date: "Fecha",
        hour: "Hora",
        all_teams: "Todos los equipos",
        update: "Actualizar",
        save: "Guardar",
        help: "Ayuda",
        back: "Atrás",
        accept: "Aceptar",
        cancel: "Cancelar",
        success: "Éxito",
        error: "Error",
        date_start: "Inicio",
        date_end: "Final",
        close: "Cerrar",
    },

    placeholder: {
        name: "Nombre"
    },

    teams: {
        title: "Equipos",
        addteam_btn: "Añadir equipo",
        adduser_btn: "Añadir usuario",
        all_teams: "Todos los equipos",
        // new team modal
        newteam_title: "Añadir nuevo equipo",
        newteam_placeholder_name: "Nombre",
        newteam_save_btn: "Añadir equipo",

        // new user modal
        newuser_title: "Añadir nuevo usuario",
        newuser_placeholder_name: "Nombre",
        newuser_placeholder_email: "Correo",
        newuser_placeholder_title: "Título",
        newuser_team_labble: "Equipo",
        newuser_save_btn: "Añadir usuario",
        newuser_rol_labble: "Rol",

        // edit team modal
        editteam_title: "Editar equipo",
        editteam_btn_delete: "Borrar equipo",

        // edit user modal
        edituser_title: "Editar usuario",
        edituser_linked_acc: "Este usuario está asociado a una cuenta.",
        edituser_btn_delete: "Borrar usuario",

        // alerts
        alert_linked_account: "Este usario no se puede borrar porque está asociado a un cuenta. Contacta con el equipo de soporte.",


        //users rol
        rol_user: "Usuario",
        team_lead: "Capitán/a del equipo",

        users: "Usuarios",
    },

    bonfire: {
        title: "Bonfire",
        bonfire_now_title: "Empieza un Bonfire ahora",
        bonfire_now_text: "No hagas esperar a tu equipo. ¡Empieza un Bonfire ahora!",
        bonfire_shedule_title: "Programa un Bonfire",
        bonfire_shedule_text: "Empieza a planear el siguiente Bonfire.",
        remember_me: "Enviar un correo de recordatorio 10 min antes de la actividad",
        send_invitations: "Enviar invitaciones",
        delete_room: "¿Seguro que quieres eliminar esta sala? Ya no se podrá acceder a través de su enlace.",
        delete_event: "¿Seguro que quieres eliminar este Bonfire?",
        delete_recurrent_event: "¿Seguro que quieres eliminar este ritual de Bonfire? Se borrará el siguiente Bonfire y todos los de después.",
        update_room: "Sala abierta actualizada.",
        update_event: "Bonfire actualizado.",
        update_recurrent_event: "Ritual de Bonfire actualizado.",
        invitations_send: "Se enviarán {n_invitations} invitaciones",
        incoming_bonfires: "Siguientes Bonfires",

        //shedule modal 
        modal_shedule_title: "Programa un Bonfire",
        modal_shedule_date: "Día",
        modal_shedule_hour: "Hora",
        modal_shedule_message: "Mensaje",
        modal_shedule_team: "Equipo",
        modal_shedule_btn: "Enviar",
        modal_alert_success: "Invitacion enviada. ¡Disfruta de Bonfire!",
        modal_alert_success_room: "Sala abierta creada. ¡Disfruta de Bonfire!",
        modal_alert_error_delete_event: "Hubo un problema eliminando el Bonfire.",
        modal_alert_success_delete_event: "Bonfire eliminado.",
        modal_alert_error_delete_room: "Hubo un problema eliminando la sala abierta.",
        modal_alert_success_delete_room: "Sala abierta eliminada.",
        modal_alert_error: "La invitación no se ha podido enviar.",
        btn_start_bonfire: "Empieza un Bonfire ahora",
        btn_shedule_bonfire: "Programa un Bonfire para otro momento",

        // bonfire 2.0
        btn_new_bonfire: "+ Nuevo Bonfire",
        room_permament: "Sala permanente",
        room_temporary: "Sala temporal",
        finish: " Termina dentro  :",
        btn_copy: "Copiar enlace",

        modal_new_title: "Crear sala de Bonfire",
        modal_new_max_bonfire: "Tu organización puede tener hasta {rooms} salas de Bonfire permanentes",
        modal_new_room_name: "Nombre de la sala",
        modal_new_type: "Tipo de sala",
        option_temporal: "Temporal (2 horas)",
        option_permanent: "Permanente",
        btn_create: "Crear",

        alert_copy_title: "Enlace copiado.",
        alert_copy_text: "El enlace se ha copiado correctamente en tu portapapeles. Solo tienes que pegarlo.",
        alert_new_room_created: "El Bonfire se ha agendado correctamente.",

        // bonfire 3.0
        text_info: "Programa momentos de juego sociales con tus compañeros.",

        open_rooms: "Salas abiertas",
        shedule_rooms: "Agenda de Bonfires",
        week: "semana",

        btn_enter: "Entrar",
        at: "a las",
        team: "equipo",
        all: "todos",

        title_shedule: "Agenda un nuevo Bonfire",
        text_shedule: "Elige el formato de encuentro que prefieras y comparte la convocatoria con tu equipo.",
        stanalone_game: "Juego puntual",
        bonfire_ritual: "Ritual de Bonfire ",
        open_room: "Sala abierta",
        time_zone: "Zona horaria",
        placeholder_msg: "Añade un mensaje personalizado en la convocatoria.",
        duration_game: "Duración (informativo, no se limita el tiempo de juego):",
        btn_shedule: "Agendar",
        btn_create: "Crear",
        day_choose: "Elige cuando se repite el evento:",
        day_1: "Lun",
        day_2: "Mar",
        day_3: "Mié",
        day_4: "Jue",
        day_5: "Vie",
        day_6: "Sáb",
        day_7: "Dom",
        day_choose_weekly: "Varios días a la semana",
        day_choose_week: "Semanalmente",
        day_choose_week_2: "Cada dos semanas",
        day_choose_month: "Cada mes",

        tooltip_ritual: "Un evento de Bonfire que se repite en el tiempo.",
        tooltip_open_room: "Una sala permanente de acceso libre en cualquier momento.",
        tooltip_puntual: "Evento de Bonfire que se hace una sola vez. Al finalizar el juego el enlace caduca.",

        //edit 
        edit_title: "Editar Bonfire",
        edit_schedule: "Editar Bonfire",


    },

    activities: {
        title: "Actividades con anfitrión",
        btn_details: "Detalles",
        btn_book: "Reservar",
        modal_book_title: "Reservar",
        modal_book_edit_title: "Editar reserva",
        modal_book_team: "Selecciona el equipo",
        modal_book_bt: "Reservar",
        modal_error_date: "La fecha debe ser posterior a la actual",
        modal_alert_success: "Reserva realizada",
        modal_alert_success_edit: "Reserva editada con éxito",

        details_include: "Incluye",
        details_pax: "pax",
        details_min: "min",
        details_requirements: "Requisitos",
        details_player_requirements: "Seleccionar equipo/s:",
        no_permission_book: "No tienes permisos para reservar.",

    },

    booking: {
        title: "Reservas",
        table_activity: "Actividad",
        table_date_and_time: "Fecha y hora",
        table_team: "Equipo",
        table_state: "Estado",
        state_approved: "Confirmada",
        state_unapproved: "No confirmada",
        state_done: "Realizada",
        alert_book_delete: "Reserva eliminada"

    },

    organization: {
        title: "Organización",
        users: "Usuarios",
        plan: "Plan",
        invitations_language: "Idioma de las comunicaciones por email",
        change_settings: "Actualizar configuración",
        alert_success_updating: "La confirguración de tu organización se ha actualizado correctamente.",
        alert_error_updating: "Se ha producido un error al actualizar la configuración de tu organización.",
    },

    update_profile: {
        title: "Actualizar perfil",
        change_password: "Cambiar contraseña",
        placeholder_password: "Contraseña",
        password_labble: "Contraseña",
        re_passowrd_labble: "Reptir contraseña",
        success_password: "Contraseña actualizada",
        errror_password: "La contraseña no se ha podido actualizar",
    },

    // Content Manager
    content: {
        title: "Contenido personalizado",
        title_admin: "Administrar contenido personalizado",
        text_first_page: "¡Crea tu propio contenido personalizado!",
        validated: "Validado y guardado",
        btn_add_content: "Añadir contenido",
        not_validated: "No validado",

        card_new_content_title: "Añadir categoría",
        card_new_content_text: "Aquí podrás añadir tu propio contenido personalizado para algunos de los juegos de Bonfire.",

        btn_new_content: "Añadir nueva categoría",
        btn_save_all: "Guardar todo",
        btn_new_theme: "Nuevo tema",

        label_name_category: "Nombre de la categoria",
        label_name_set: "Nombre del set de juego",

        placeholder_name_category: "Categoría...",
        placeholder_name_set: "Set de juego...",

        modal_new_content_title: "Contenido nuevo",
        modal_new_content_name_labble: "Nombre",
        modal_new_content_name_placeholder: "Nombre",
        modal_new_content_btn: "Crear contenido",

        content_table_name: "Categoría",
        content_table_status: "Estado",
        content_table_actions: "Acciones",
        content_table_active: "Activo",
        content_table_inactive: "Inactivo",
        content_table_edit: "Editar",
        content_table_delete: "Borrar",
        content_table_user: "Usuari",

        subcontent_title: "Cómo crear contenido para este juego:",
        subcontent_text_factMatching: "Cada contenido tiene entre {minContent} y {maxContent} preguntas que se harán a todos los participantes. La pregunta tiene que ser abierta para que los jugadores puedan dar respuestas creativas o que les identifiquen.",
        subcontent_text_topo: "Cada contenido debe tener {minContent} palabras de temática similar o ligeramente relacionadas. Es importante que se trate de conceptos abstractos y conocidos.",
        subcontent_text_sellerSponge: "Cada contenido debe tener {minContent} productos que hay que vender (cada uno será asignado a un jugador). El producto debe ir acompañado de una imagen. Se le puede dar un enfoque creativo o serio en función de la temática del producto. Hay que tener en cuenta que todos los participantes conozcan el producto.",
        subcontent_text_quiz: "Cada contenido es una batería de entre {minContent} y {maxContent} preguntas de selección entre 4 opciones de respuesta. Las preguntas pueden incluir una imagen.",
        subcontent_text_pictionary: "Cada contenido consta de entre {minContent} y {maxContent} grupos de 4 conceptos (cuantos más grupos, más variedad y rejugabilidad tendrá el juego).  En cada grupo los 4 conceptos tienen que ser representables con un dibujo y deben estar relacionados entre ellos. También deben ser confundibles entre ellos, pero no sinónimos.<br>Por ejemplo: isla, península, archipiélago y continente.",
        subcontent_text_mingleTingle: "Cada contenido tiene {minContent} preguntas para generar debate entre los participantes y una pregunta final que los inste a votar por una persona del grupo.<br>Las preguntas de debate deben ser abiertas y promover la exposición de opiniones, i.e. ¿Qué es para ti el amor?<br>La pregunta final debe estar relacionada con la temática y servir para usar el aprendizaje sobre los otros que se ha hecho, i.e. ¿Quién es más probable que tenga una relación poliamorosa?",
        content_of: "Contenido de ",
        alert_save_success: "Contenido guardado correctamente.",
        alert_save_error: "El contenido no se ha podido eliminar.",
        alert_add_question_success: "Pregunta añadida correctamente.",
        alert_add_question_error: "La pregunta no se ha podido añadir.",
        alert_delete_question_success: "Pregunta borrada correctamente.",
        alert_delete_question_error: "No se ha podido borrar la pregunta.",
        alert_delete_content: "Contenido borrado correctamente.",
        alert_limit_content: "No puedes añadir más contenido.",
        alert_enabled: "Contenido activado.",
        alert_disabled: "Contenido desactivado.",
        alert_cant_enable: "El contenido no se ha podido activar.",
        alert_delete_content_question: "¿Estás seguro de que quieres eliminar este contenido?",
        alert_add_content_success: "Contenido creado.",
        alert_new_category_empty_name: "Category name can't be empty.",

        // mingle Tingle
        mingleTingle: {
            placeholder_question: "Pregunta"
        },

        // sellerSponge
        sellerSponge: {
            placeholder_question: "Pregunta",
            placeholder_image: "Arrastra aquí tus archivos o haz click para subirlos. Lo más quadradas possibles.",
        },

        mingleTingle: {
            final_question: "Pregunta final",
            open_questions: "Preguntas respuesta abierta",

        },

        factMatching: {
            placeholder_question: "Pregunta",
        },

        quiz: {
            question: "Pregunta",
            correct: "Respuesta correcta",
            incorrect: "Respuesta incorrecta",
            photo: "Imagen del trivia",
            eliminar: "Eliminar contenido"

        },

        pictionary: {
            placeholder: "Añadir conceptos para dibujar",
        },
        topo: {
            placeholder: "Añadir palabras para el Topo",
        }

    },
    analytics: {
        title: "Analíticas",
        bonfire_time: "Tiempo de Bonfire",
        play_time: "Tiempo de juego",
        games_played: "Juegos jugados",
        mean_players: "Media de jugadores",
        most_played: "Juego más jugado",
        content_most_played: "Contenido más jugado",
    }
}



module.exports = {
    es
}

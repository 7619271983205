<template>
   <transition
            name="view"
            mode="out-in"
            
    >
        <div class="flex flex-col items-center justify-center" v-if="loading" :class="class">
            <img
                class="animation-image"
                src="@/assets/icons/preloader.svg "
                alt=""
                :class="class_loader"
            >
        </div>
        <div v-else >
            <slot >
                
            </slot>
        </div>
    </transition>
</template>

<script>
export default {
    props:{
        loading:{type:Boolean, default:false},
        class:{type:String, default:""},
        class_loader:{type:String, default:""},
    }
};
</script>

<style>
</style>
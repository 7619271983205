function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

function sortArrayDataById(data, sortOrder) {
  if (sortOrder == "asc") {
    return data.map(item => item).sort((a, b) => a.id - b.id)
  }
  else {
    return data.map(item => item).sort((a, b) => b.id - a.id)
  }
}

function sortArrayDataByParameter(data, sortOrder, parameter) {
  if (sortOrder == "asc") {
    return data.map(item => item).sort((a, b) => a[parameter] - b[parameter])
  }
  else {
    return data.map(item => item).sort((a, b) => b[parameter] - a[parameter])
  }
}

function sortArrayDataByParameter2(data, sortOrder, parameter, parameter2) {
  if (sortOrder == "asc") {
    return data.map(item => item).sort((a, b) => a[parameter][parameter2] - b[parameter][parameter2])
  }
  else {
    return data.map(item => item).sort((a, b) => b[parameter][parameter2] - a[parameter][parameter2])
  }
}

// check duplicates, returns an array of equal dimensions than the one sended by parameter full of true or false to indicate if it's duplicated or not. 
const checkDuplicates = (array) => {
  let duplicateArray = new Array(array.length).fill(false);

  for (let y = 0; y < array.length; y++) {
    for (let j = 0; j < array.length; j++) {
      if (y != j && y >= j && !duplicateArray[y]) {
        if (array[y] === array[j]) {
          duplicateArray[y] = true
          duplicateArray[j] = true
        }
      }
    }
  }
  return duplicateArray
}

/*const teamSize = (array, searchedTeam) => {
  let teamLength = 0;
  const arrayTeams = array;
  console.log(arrayTeams, '------', searchedTeam)
  if (searchedTeam != 'all')
  {
      let searchTeam = arrayTeams.find(d=>{
          return d.id==searchedTeam;
      });

      teamLength = searchTeam.user_number;
      
  } else {
      arrayTeams.forEach(element => {
          teamLength += element.user_number;
      });
      
  }
  return teamLength ;
}*/

export { makeid, sortArrayDataById, checkDuplicates, sortArrayDataByParameter, sortArrayDataByParameter2 }
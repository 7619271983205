<template>
    <span>
        <div class="flex flex-row items-center" v-for="(pict,index) in pictionaryDataOffset"
                :key="pict">
            <smart-tagz
                class="test"
                :defaultTags="[...pict]"
                editable
                :inputPlaceholder="$t('content.pictionary.placeholder')"
                :allowPaste="{delimiter: ','}"
                :allowDuplicates="false"
                :maxTags="4"
                :theme="{
            primary: '#FE3732',
            background: '#fff',
            tagTextColor: '#fff',
        }"
                :on-changed="(e)=>logResult(e,index)"
            />
            <button
            type="button"
            v-on:click="removePictionaryData(index)"
            class="btn btn-primary-outline w-16 h-10  my-auto"
        >
            <inline-svg
                        :src="require('@/assets/icons/trash-solid.svg')"
                        alt=""
                        class="h-full"
                    />
        </button>
        </div>

        <div class="flex flex-row my-5 items-center gap-3">
            <button
                type="button"
                v-on:click="addNewData()"
                class="btn btn-primary-soft h-10 w-16 "
            >
                <inline-svg
                    :src="require('@/assets/icons/plus-solid.svg')"
                    alt=""
                    class="h-full"
                    style="fill:white"
                />
            </button>
            <p v-if="pictionaryData.length" 
                class="text-lg  "
                :class="pictionaryData.length < $store.state.globalVariables.pictionary.minContent ? 'text-danger-100' :'text-success-120' "
            >
                {{pictionaryData.length}}/{{$store.state.globalVariables.pictionary.maxContent}}
            </p>
        </div>
    </span>
</template>

<script>
import { axiosToken } from "@/libs/http";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { SmartTagz } from "smart-tagz";
import "smart-tagz/dist/smart-tagz.css";
import { useI18n } from "vue-i18n";
import { notify } from "notiwind";
import { useStore } from "vuex";

export default defineComponent({
    components: {
        SmartTagz,
    },

    props: {
        data: Object,
    },



    setup(props) {
        const pictionaryData = ref(props.data)
        const pictionaryDataOffset = ref({...props.data})
        const { t } = useI18n();
        const store = useStore();
        
        const logResult = (result,e) => {
            pictionaryData.value[e] = result
        };

        const addNewData = ()=>{
            if (pictionaryData.value.length >= store.state.globalVariables.pictionary.maxContent) {
                notify ({
                    group: "error",
                    title: t('general.error'),
                    text: t('content.alert_limit_content')
                }, 2000)
                return;
            }
            pictionaryData.value.push([])
            pictionaryDataOffset.value = {...pictionaryData.value}
        }

        const removePictionaryData = (index)=>{
            pictionaryData.value.splice(index, 1); 
            pictionaryDataOffset.value = {...pictionaryData.value}
            }

        const tag = ref(["asd"]);
        return {
            tag,
            logResult,
            pictionaryData,
            addNewData,
            removePictionaryData,
            pictionaryDataOffset,
        };
    },
});
</script>

<style lang="scss" scoped>

</style>
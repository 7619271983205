<template>
    <AreaTrial>
        <span>
            <Area
                :loading="!loadingBonfireRooms || !loadingBonfireShedule"
                v-if="bonfireRoomSelector == 0 || bonfireRoomSelector == 1"
                :class="'my-10'"
                :class_loader="'w-1/5'"
            >
                <div class="flex flex-row my-5">
                    <h2
                        class=" text-2xl xl:text-3xl text-center xl:text-left font-extrabold"
                    >
                        {{ $t("bonfire.title") }}
                    </h2>
                    <div class="flex flex-row ml-auto">
                        <button
                            data-target="#team-modal-add"
                            data-toggle="modal"
                            class="btn btn-primary-soft mr-1 capitalize"
                            v-if="
                                $store.getters.getPermission(
                                    'bonfire.add_room'
                                ) ||
                                    $store.getters.getPermission(
                                        'bonfire.add_event'
                                    )
                            "
                            v-on:click="setBonfireMenu(true)"
                        >
                            {{ $t("bonfire.btn_new_bonfire") }}
                        </button>
                    </div>
                </div>
                <div class="flex flex-col">
                    <p class="text-xl">
                        {{ $t("bonfire.text_info") }}
                    </p>
                </div>
                <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
                    <div class="" :class="bonfireMenu ? 'form-shedule' : ''">
                        <div
                            class="flex flex-row items-center justify-center my-4 bg-white rounded-lg text-center  text-lg"
                        >
                            <div
                                class=" py-3 px-5 cursor-pointer w-1/2 rounded-l-lg"
                                :class="
                                    bonfireRoomSelector == 0
                                        ? ' border-soft-100 border-2 font-extrabold bg-white'
                                        : 'border-theme-2 '
                                "
                                v-on:click="
                                    setBonfireRoomSelector(0);
                                    resetSelected();
                                "
                            >
                                {{ $t("bonfire.open_rooms") }}
                            </div>
                            <div
                                class=" py-3 px-5 cursor-pointer bg-white w-1/2  rounded-r-lg"
                                :class="
                                    bonfireRoomSelector == 1
                                        ? ' border-soft-100 border-2 font-extrabold bg-white'
                                        : 'border-theme-2 '
                                "
                                v-on:click="
                                    setBonfireRoomSelector(1);
                                    resetSelected();
                                "
                            >
                                {{ $t("bonfire.shedule_rooms") }}
                            </div>
                        </div>
                        <Area
                            :loading="!loadingBonfireRooms"
                            v-if="bonfireRoomSelector == 0"
                            :class="'my-10'"
                            :class_loader="'w-1/4'"
                        >
                            <div
                                class="grid grid-cols-1 gap-2 w-full xl:grid-cols-2 "
                                v-if="bonfireRoomSelector == 0"
                            >
                                <div
                                    v-for="room in bonfireRooms"
                                    :key="room"
                                    class="bg-white rounded-lg p-5 "
                                    :class="
                                        room.id == selectedShedule.shedule_id &&
                                        selectedShedule.type == 'open'
                                            ? 'eventSelected'
                                            : ''
                                    "
                                >
                                    <h2
                                        class="text-xl xl:text-xl  text-center py-2 font-extrabold truncate"
                                    >
                                        <span v-if="room.name">
                                            {{ room.name }}
                                        </span>
                                        <span v-else>
                                            Demo Room
                                        </span>
                                    </h2>
                                    <div class="flex-col grid gap-4">
                                        <div
                                            class="flex flex-row items-center justify-center flex-wrap gap-2"
                                        >
                                            <button
                                                v-on:click="
                                                    editarShedule(
                                                        room,
                                                        'openRoom'
                                                    )
                                                "
                                                class="btn btn-outline-soft tooltip capitalize"
                                                v-if="
                                                    $store.getters.getPermission(
                                                        'bonfire.edit_room'
                                                    )
                                                "
                                            >
                                                <inline-svg
                                                    :src="
                                                        require('@/assets/icons/pencil-solid.svg')
                                                    "
                                                    alt=""
                                                    class="h-5 w-5 fill-current"
                                                />
                                                <div
                                                    class="tooltiptext font-normal"
                                                >
                                                    {{ $t("general.edit") }}
                                                </div>
                                                <!--{{$t('general.edit')}}-->
                                            </button>
                                            <button
                                                v-on:click="
                                                    copyLink(
                                                        'https://bonfire.remoteboost.io/' +
                                                            room.url
                                                    )
                                                "
                                                target="_blank"
                                                class="btn btn-primary-soft tooltip capitalize"
                                            >
                                                <inline-svg
                                                    :src="
                                                        require('@/assets/icons/copy-solid.svg')
                                                    "
                                                    alt=""
                                                    class="h-5 w-5"
                                                    style="fill:white"
                                                />
                                                <div
                                                    class="tooltiptext font-normal"
                                                >
                                                    {{ $t("bonfire.btn_copy") }}
                                                </div>
                                                <!--{{$t('bonfire.btn_copy')}}-->
                                            </button>
                                            <button
                                                @click="
                                                    openSendInvitationsModal(
                                                        room
                                                    )
                                                "
                                                target="_blank"
                                                class="btn btn-primary-soft tooltip capitalize"
                                            >
                                                <inline-svg
                                                    :src="
                                                        require('@/assets/icons/paper-plane-solid.svg')
                                                    "
                                                    alt=""
                                                    class="h-5 w-5"
                                                    style="fill:white"
                                                />
                                                <div
                                                    class="tooltiptext font-normal"
                                                >
                                                    {{
                                                        $t(
                                                            "bonfire.send_invitations"
                                                        )
                                                    }}
                                                </div>
                                                <!--{{$t('bonfire.send_invitations')}}-->
                                            </button>
                                            <a
                                                :href="
                                                    'https://bonfire.remoteboost.io/' +
                                                        room.url
                                                "
                                                target="_blank"
                                                class="btn btn-primary-soft tooltip capitalize"
                                            >
                                                <inline-svg
                                                    :src="
                                                        require('@/assets/icons/play-solid.svg')
                                                    "
                                                    alt=""
                                                    class="h-5 w-5"
                                                    style="fill:white"
                                                />
                                                <div
                                                    class="tooltiptext font-normal"
                                                >
                                                    {{
                                                        $t("bonfire.btn_enter")
                                                    }}
                                                </div>
                                                <!--{{$t('bonfire.btn_enter')}}-->
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Area>
                        <Area
                            v-else-if="bonfireRoomSelector == 1"
                            :loading="!loadingBonfireShedule"
                            :class="'my-10'"
                            :class_loader="'w-1/4'"
                        >
                            <div>
                                <div
                                    class="flex flex-row gap-2 items-center justify-center flex-wrap"
                                >
                                    <div
                                        class="border-2 rounded-lg  py-2 px-5 cursor-pointer bg-white border-white"
                                        :class="
                                            teamSelected == 'All'
                                                ? 'active-bonfire'
                                                : ''
                                        "
                                        v-on:click="setTeamSelected('All')"
                                    >
                                        <p class="text-text-100">
                                            # All
                                        </p>
                                    </div>
                                    <div
                                        class="border-2 rounded-lg  py-2 px-5 cursor-pointer bg-white border-white"
                                        :class="
                                            teamSelected == team.name
                                                ? 'active-bonfire'
                                                : ''
                                        "
                                        v-on:click="setTeamSelected(team.name)"
                                        v-for="team in teams"
                                        :key="team"
                                    >
                                        <p class="text-text-100">
                                            # {{ team.name }}
                                        </p>
                                    </div>
                                </div>
                                <hr class="my-1" />
                                <div
                                    v-for="bonfire in bonfireSchedule"
                                    :key="bonfire"
                                    class="flex flex-col gap-3"
                                >
                                    <p class="text-text-100 text-lg">
                                        <span
                                            v-if="isNaN(bonfire.week_day)"
                                            class="capitalize font-bold"
                                        >
                                            {{
                                                $t("bonfire.incoming_bonfires")
                                            }}
                                        </span>
                                        <span
                                            v-else
                                            class="capitalize font-bold"
                                        >
                                            {{ $t("bonfire.week") }}:
                                            {{
                                                $filters.formatDateDay(
                                                    bonfire.week_day
                                                )
                                            }}
                                        </span>
                                    </p>
                                    <template
                                        v-for="bonfire_week in bonfire.week"
                                        class=""
                                    >
                                        <div
                                            :key="bonfire_week"
                                            v-if="
                                                teamSelected == 'All' ||
                                                    bonfire_week.team.name ==
                                                        teamSelected
                                            "
                                            class="bg-white rounded-lg p-5"
                                            :class="[
                                                bonfire_week.shedule_id ==
                                                    selectedShedule.shedule_id &&
                                                bonfire_week.once &&
                                                selectedShedule.type == 'once'
                                                    ? 'eventSelected'
                                                    : '',
                                                bonfire_week.shedule_id ==
                                                    selectedShedule.shedule_id &&
                                                bonfire_week.recurrent &&
                                                selectedShedule.type ==
                                                    'recurrent'
                                                    ? 'eventSelected'
                                                    : ''
                                            ]"
                                        >
                                            <h2
                                                class="font-black text-xl xl:text-xl text-center xl:text-left truncate"
                                            >
                                                {{ bonfire_week.name }}
                                            </h2>
                                            <div
                                                class="flex flex-row justify-between w-full my-2"
                                            >
                                                <div>
                                                    {{
                                                        $filters.formatDateDay(
                                                            bonfire_week.start
                                                        )
                                                    }}
                                                </div>
                                                <div>
                                                    {{ $t("bonfire.at") }}
                                                    {{
                                                        $filters.formatDateHour(
                                                            bonfire_week.start
                                                        )
                                                    }}
                                                </div>
                                                <div>
                                                    {{ bonfire_week.duration }}
                                                    min
                                                </div>
                                            </div>
                                            <div
                                                class="flex flex-row justify-between w-full items-center capitalize"
                                            >
                                                <p class="text-text-100">
                                                    <span
                                                        v-if="
                                                            bonfire_week.team
                                                                .name
                                                        "
                                                    >
                                                        {{
                                                            $t("bonfire.team")
                                                        }}:
                                                        {{
                                                            bonfire_week.team
                                                                .name
                                                        }}
                                                    </span>
                                                    <span v-else>
                                                        {{
                                                            $t("bonfire.team")
                                                        }}:
                                                        {{ $t("bonfire.all") }}
                                                    </span>
                                                </p>
                                                <div
                                                    class="ml-auto flex flex-row"
                                                >
                                                    <span
                                                        v-if="
                                                            $store.getters.getPermission(
                                                                'bonfire.edit_event'
                                                            )
                                                        "
                                                    >
                                                        <button
                                                            data-target="#team-modal-add"
                                                            data-toggle="modal"
                                                            class="btn btn-outline-soft mr-1 tooltip capitalize"
                                                            v-if="
                                                                bonfire_week.once
                                                            "
                                                            v-on:click="
                                                                editarShedule(
                                                                    bonfire_week,
                                                                    'once'
                                                                )
                                                            "
                                                        >
                                                            <inline-svg
                                                                :src="
                                                                    require('@/assets/icons/pencil-solid.svg')
                                                                "
                                                                alt=""
                                                                class="h-5 w-5 fill-current"
                                                            />
                                                            <div
                                                                class="tooltiptext font-normal"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "general.edit"
                                                                    )
                                                                }}
                                                            </div>
                                                            <!--{{$t('general.edit')}}-->
                                                        </button>
                                                        <button
                                                            data-target="#team-modal-add"
                                                            data-toggle="modal"
                                                            v-on:click="
                                                                editarShedule(
                                                                    bonfire_week,
                                                                    'recurrent'
                                                                )
                                                            "
                                                            class="btn btn-outline-soft tooltip mr-1 capitalize"
                                                            v-else
                                                        >
                                                            <inline-svg
                                                                :src="
                                                                    require('@/assets/icons/pencil-solid.svg')
                                                                "
                                                                alt=""
                                                                class="h-5 w-5 fill-current"
                                                            />
                                                            <div
                                                                class="tooltiptext font-normal"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "general.edit"
                                                                    )
                                                                }}
                                                            </div>
                                                            <!--{{$t('general.edit')}}-->
                                                        </button>
                                                    </span>
                                                    <button
                                                        v-on:click="
                                                            copyLink(
                                                                'https://bonfire.remoteboost.io/' +
                                                                    bonfire_week
                                                                        .bonfire_room[0]
                                                                        .url
                                                            )
                                                        "
                                                        target="_blank"
                                                        class="btn btn-primary-soft tooltip mr-1 capitalize"
                                                    >
                                                        <inline-svg
                                                            :src="
                                                                require('@/assets/icons/copy-solid.svg')
                                                            "
                                                            alt=""
                                                            class="h-5 w-5"
                                                            style="fill:white"
                                                        />
                                                        <div
                                                            class="tooltiptext font-normal"
                                                        >
                                                            {{
                                                                $t(
                                                                    "bonfire.btn_copy"
                                                                )
                                                            }}
                                                        </div>
                                                    </button>
                                                    <a
                                                        class="btn btn-primary-soft tooltip mr-1 capitalize"
                                                        target="_blank"
                                                        :href="
                                                            'https://bonfire.remoteboost.io/' +
                                                                bonfire_week
                                                                    .bonfire_room[0]
                                                                    .url
                                                        "
                                                    >
                                                        <inline-svg
                                                            :src="
                                                                require('@/assets/icons/play-solid.svg')
                                                            "
                                                            alt=""
                                                            class="h-5 w-5"
                                                            style="fill:white"
                                                        />
                                                        <div
                                                            class="tooltiptext font-normal"
                                                        >
                                                            {{
                                                                $t(
                                                                    "bonfire.btn_enter"
                                                                )
                                                            }}
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <hr />
                                </div>
                            </div>
                        </Area>
                    </div>
                    <div class="">
                        <FormShedule
                            :form="v$"
                            :form_2="v2$"
                            :sendingLoading="sendingLoading"
                            :setBonfireMenu="setBonfireMenu"
                            :bookCall="bookingApi"
                            :bonfireMenu="bonfireMenu"
                            :teams="teams"
                            :myTimeZone="myTimeZone"
                            :durations="durations"
                            :editingShedule="editingShedule"
                            :sheduleType="sheduleType"
                            :setSheduleType="setSheduleType"
                            :createRoom="createRoom"
                            :form_3="v3$"
                            :sheduleForm="formTeamGame"
                            :sheduleForm_2="newSheduleBonfire"
                            :bookingApiRecurrent="bookingApiRecurrent"
                        />
                    </div>
                </div>
                <ConfirmModal
                    :title="$t('bonfire.send_invitations')"
                    :actionAccept="sendInvitations"
                >
                    <div class="flex flex-col w-10/12 ">
                        <p>
                            {{ $t("bonfire.modal_shedule_team") }}
                        </p>
                        <select
                            class="focus:font-bold rounded-lg font-300 py-3 px-4 border bg-white border-border-100 block mb-2"
                            name=""
                            id=""
                            v-model="sendInvitationsTeamSelected"
                            @change="teamSize"
                        >
                            <option value="all">All Users</option
                            >"once"
                            <option
                                v-for="team in teams"
                                :key="team"
                                :value="team.id"
                            >
                                {{ team.name }}
                            </option>
                        </select>
                        <h4>
                            {{
                                $t("bonfire.invitations_send", {
                                    n_invitations: teamLength
                                })
                            }}
                        </h4>
                    </div>
                </ConfirmModal>
            </Area>
        </span>
    </AreaTrial>
</template>

<script>
import {
    defineComponent,
    onMounted,
    ref,
    reactive,
    computed,
    provide,
    inject
} from "vue";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";

import { notify } from "notiwind";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";
import { timeZones } from "@/libs/timeZones";
import FormShedule from "./FormShedule.vue";
import moment from "moment";
import { useState } from "@/hooks/state";
import Area from "@/global-components/loading/Area.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import AreaTrial from "@/global-components/trial/Area.vue";
export default defineComponent({
    components: {
        FormShedule,
        Area,
        ConfirmModal,
        AreaTrial
    },
    setup() {
        const { locale, t } = useI18n();
        const myTimeZone = ref();
        provide("myTimeZone", myTimeZone);
        const createRoomLoading = ref(false);
        let teamLength = ref(0);
        const store = useStore();

        // bonfire room selector
        const [bonfireRoomSelector, setBonfireRoomSelector] = useState(0);
        const [teamSelected, setTeamSelected] = useState("All");

        const sendInvitationsTeamSelected = ref("all");
        let sendInvitationsRoom = ref({});

        const openSendInvitationsModal = room => {
            cash("#confirm-modal").modal("show");
            sendInvitationsRoom = room;
            teamSize();
        };

        const teamSize = () => {
            const arrayTeams = teams.value;
            teamLength.value = 0;
            if (sendInvitationsTeamSelected._value != "all") {
                let searchTeam = arrayTeams.find(d => {
                    return d.id == sendInvitationsTeamSelected._value;
                });

                teamLength.value = searchTeam.user_number;
            } else {
                teamLength.value = store.state.auth.organitzation.user_number;
            }
        };

        const sendInvitations = () => {
            axiosToken({
                method: "post",
                url: "bonfire/shedule-game-email",
                body: {
                    id: sendInvitationsRoom.id,
                    team: sendInvitationsTeamSelected._value
                }
            })
                .then(response => {
                    notify(
                        {
                            group: "top",
                            title: t("general.success"),
                            text: t("bonfire.modal_alert_success")
                        },
                        4000
                    );
                    cash("#confirm-modal").modal("hide");
                })
                .catch(e => {
                    notify(
                        {
                            group: "bottom",
                            title: t("general.error"),
                            text: t("bonfire.modal_alert_error")
                        },
                        4000
                    );
                    cash("#confirm-modal").modal("hide");
                });
        };

        const createRoom = action => {
            createRoomLoading.value = true;
            const timeData = {
                permanent: true
            };
            if (action == "new") {
                axiosToken({
                    method: "post",
                    url: "bonfire/bonfireRooms",
                    body: {
                        required_login: false,
                        name: formBonfireRoom.form.name,
                        ...timeData
                    }
                })
                    .then(response => {
                        formBonfireRoom.form.name = "";
                        createRoomLoading.value = false;
                        fetchRooms();
                        notify(
                            {
                                group: "top",
                                title: t("general.success"),
                                text: t("bonfire.modal_alert_success_room")
                            },
                            4000
                        );
                    })
                    .catch(e => {
                        formBonfireRoom.form.name = "";
                        createRoomLoading.value = false;
                        if (e.response.data.error_id == 1) {
                            notify(
                                {
                                    group: "error",
                                    title: t("general.error"),
                                    text: t("bonfire.modal_new_max_bonfire", {
                                        rooms:
                                            store.state.auth.organitzation
                                                .max_room
                                    })
                                },
                                4000
                            );
                        }
                    });
            } else if (action == "edit") {
                axiosToken({
                    method: "put",
                    url:
                        "bonfire/bonfireRooms/" +
                        selectedShedule.value.shedule_id,
                    body: {
                        required_login: false,
                        name: formBonfireRoom.form.name,
                        ...timeData
                    }
                })
                    .then(response => {
                        formBonfireRoom.form.name = "";
                        resetSelected();
                        fetchRooms();
                        notify(
                            {
                                group: "top",
                                title: t("general.success"),
                                text: t("bonfire.update_room")
                            },
                            4000
                        );
                    })
                    .catch(e => {
                        createRoomLoading.value = false;
                    });
            } else if (action == "delete") {
                if (confirm(t("bonfire.delete_room")) == true) {
                    axiosToken({
                        method: "delete",
                        url:
                            "bonfire/bonfireRooms/" +
                            selectedShedule.value.shedule_id,
                        body: {
                            required_login: false,
                            name: formBonfireRoom.form.name,
                            ...timeData
                        }
                    })
                        .then(response => {
                            formBonfireRoom.form.name = "";
                            resetSelected();
                            fetchRooms();
                            notify(
                                {
                                    group: "top",
                                    title: t("general.success"),
                                    text: t(
                                        "bonfire.modal_alert_success_delete_room"
                                    )
                                },
                                4000
                            );
                        })
                        .catch(e => {
                            formBonfireRoom.form.name = "";
                            createRoomLoading.value = false;
                            notify(
                                {
                                    group: "bottom",
                                    title: t("general.error"),
                                    text: t(
                                        "bonfire.modal_alert_error_delete_room"
                                    )
                                },
                                4000
                            );
                        });
                }
            }
        };
        const bonfireRooms = ref([]);
        const loadingBonfireRooms = ref(false);
        const fetchRooms = () => {
            loadingBonfireRooms.value = false;
            axiosToken({
                method: "get",
                url: "bonfire/bonfireRooms"
            }).then(response => {
                bonfireRooms.value = response.data;
                loadingBonfireRooms.value = true;
            });
        };

        const formBonfireRoom = reactive({
            form: {
                name: ""
            }
        });
        const rulesBonfireRoom = computed(() => ({
            form: {
                name: {
                    required
                }
            }
        }));
        const v3$ = useVuelidate(rulesBonfireRoom, formBonfireRoom);

        const copyLink = async link => {
            await navigator.clipboard.writeText(link).then(
                notify(
                    {
                        group: "top",
                        title: t("bonfire.alert_copy_title"),
                        text: t("bonfire.alert_copy_text")
                    },
                    2000
                )
            );
        };

        const durations = [
            {
                text: "5 min",
                int: 5
            },
            {
                text: "10 min",
                int: 10
            },
            {
                text: "15 min",
                int: 15
            },
            {
                text: "20 min",
                int: 20
            }
        ];
        const teams = ref([]);
        const fetchTeams = () => {
            axiosToken({
                method: "get",
                url: "accounts/team"
            }).then(response => {
                teams.value = response.data;
                return response.data;
            });
        };

        const bonfireMenu = ref(false);
        const setBonfireMenu = data => {
            if (
                data == true &&
                (bonfireMenu.value == false || editingShedule.value == true)
            ) {
                editingShedule.value = false;
                cleanForm();
                cleanForm_2();
                formBonfireRoom.form.name = "";
            }

            bonfireMenu.value = data;
            console.log(data);
            selectedShedule.value = {
                shedule_id: null,
                type: null
            };
        };

        // form shedule once
        const newSheduleBonfire = reactive({
            form: {
                name: "",
                date: "",
                hour: "",
                msg: "",
                team: "",
                duration: 0,
                remainder_email: true,
                invite_email: true
            }
        });
        const cleanForm = () => {
            newSheduleBonfire.form = {
                name: "",
                date: "",
                hour: "",
                msg: "",
                team: "",
                duration: 0,
                remainder_email: true,
                invite_email: true
            };
        };
        const rules = computed(() => ({
            form: {
                name: { required },
                date: {
                    required
                },
                hour: { required },
                team: { required },
                msg: {},
                duration: { required },
                remainder_email: {},
                invite_email: {}
            }
        }));
        const v$ = useVuelidate(rules, newSheduleBonfire);
        const sendingLoading = ref(false);
        var tzoffset = new Date().getTimezoneOffset() * 60000;

        const bookingApiRecurrent = action => {
            if (action == "delete") {
                if (confirm(t("bonfire.delete_recurrent_event")) == true) {
                    axiosToken({
                        method: "delete",
                        url:
                            "bonfire/shedule-game-recurrent/" +
                            selectedShedule.value.shedule_id
                    })
                        .then(e => {
                            resetSelected();
                            fetchBonfireEvents();
                            notify(
                                {
                                    group: "top",
                                    title: t("general.success"),
                                    text: t(
                                        "bonfire.modal_alert_success_delete_event"
                                    )
                                },
                                4000
                            );
                            sendingLoading.value = false;
                        })
                        .catch(e => {
                            notify(
                                {
                                    group: "bottom",
                                    title: t("general.error"),
                                    text: t(
                                        "bonfire.modal_alert_error_delete_event"
                                    )
                                },
                                4000
                            );
                        });
                }
                return;
            }
            const timeZoneSelected = ref(
                timeZones.find(e => e.value == myTimeZone.value)
            ).value.utc[0];

            let dateobj = new Date(
                formTeamGame.form.date + " " + formTeamGame.form.hour
            );

            let body = {
                team: formTeamGame.form.team,
                time: formTeamGame.form.hour,
                day: formTeamGame.form.day,
                often: formTeamGame.form.often,
                language: locale.value,
                name: formTeamGame.form.name,
                time_zone: timeZoneSelected,
                duration: durations[formTeamGame.form.duration].int,
                remainder_email: newSheduleBonfire.form.remainder_email,
                invite_email: newSheduleBonfire.form.invite_email
            };
            if (formTeamGame.form.msg) {
                body.msg = formTeamGame.form.msg;
            }

            sendingLoading.value = true;
            if (action == "new") {
                axiosToken({
                    method: "post",
                    url: "bonfire/shedule-game-recurrent",
                    body
                }).then(e => {
                    notify(
                        {
                            group: "top",
                            title: t("general.success"),
                            text: t("bonfire.modal_alert_success")
                        },
                        4000
                    );
                    sendingLoading.value = false;
                    cleanForm();
                    resetSelected();
                    fetchBonfireEvents();
                });
            } else if (action == "edit") {
                axiosToken({
                    method: "put",
                    url:
                        "bonfire/shedule-game-recurrent/" +
                        selectedShedule.value.shedule_id,
                    body
                }).then(e => {
                    resetSelected();
                    fetchBonfireEvents();
                    notify(
                        {
                            group: "top",
                            title: t("general.success"),
                            text: t("bonfire.update_recurrent_event")
                        },
                        4000
                    );
                    sendingLoading.value = false;
                });
            }
        };
        // form shedule once
        const formTeamGame = reactive({
            form: {
                name: "",
                hour: "",
                msg: "",
                team: "",
                often: 0,
                day: null,
                duration: 0,
                remainder_email: true,
                invite_email: true
            }
        });
        const rulesTeamGame = computed(() => ({
            form: {
                name: {
                    required
                },
                hour: { required },
                team: { required },
                msg: {},
                often: { required },
                day: { required },
                duration: { required },
                remainder_email: true,
                invite_email: true
            }
        }));
        const cleanForm_2 = () => {
            formTeamGame.form = {
                name: "",
                hour: "",
                msg: "",
                team: "",
                often: 0,
                day: null,
                duration: 0,
                remainder_email: true,
                invite_email: true
            };
        };

        const v2$ = useVuelidate(rulesTeamGame, formTeamGame);

        const bookingApi = action => {
            if (action == "delete") {
                if (confirm(t("bonfire.delete_event")) == true) {
                    axiosToken({
                        method: "delete",
                        url:
                            "bonfire/shedule-game/" +
                            selectedShedule.value.shedule_id
                    })
                        .then(e => {
                            resetSelected();
                            fetchBonfireEvents();
                            notify(
                                {
                                    group: "top",
                                    title: t("general.success"),
                                    text: t(
                                        "bonfire.modal_alert_success_delete_event"
                                    )
                                },
                                4000
                            );
                            sendingLoading.value = false;
                        })
                        .catch(e => {
                            notify(
                                {
                                    group: "bottom",
                                    title: t("general.error"),
                                    text: t(
                                        "bonfire.modal_alert_error_delete_event"
                                    )
                                },
                                4000
                            );
                        });
                }
                return;
            }
            const timeZoneSelected = ref(
                timeZones.find(e => e.value == myTimeZone.value)
            ).value.utc[0];

            let dateobj = new Date(
                newSheduleBonfire.form.date + " " + newSheduleBonfire.form.hour
            );
            console.log(newSheduleBonfire.form.date);
            var B = dateobj.toISOString();
            let body = {
                team: newSheduleBonfire.form.team,
                time: newSheduleBonfire.form.hour,
                date: new Date(
                    new Date(newSheduleBonfire.form.date) - tzoffset
                ).toISOString(),
                language: locale.value,
                name: newSheduleBonfire.form.name,
                time_zone: timeZoneSelected,
                duration: durations[newSheduleBonfire.form.duration].int,
                remainder_email: newSheduleBonfire.form.remainder_email,
                invite_email: newSheduleBonfire.form.invite_email
            };
            if (newSheduleBonfire.form.msg) {
                body.msg = newSheduleBonfire.form.msg;
            }

            if (dateobj < Date.now()) {
                notify(
                    {
                        group: "error",
                        title: "Error",
                        text: t("activities.modal_error_date")
                    },
                    4000
                );
                return;
            }

            sendingLoading.value = true;
            if (action == "new") {
                axiosToken({
                    method: "post",
                    url: "bonfire/shedule-game",
                    body
                }).then(e => {
                    notify(
                        {
                            group: "top",
                            title: t("general.success"),
                            text: t("bonfire.alert_new_room_created")
                        },
                        4000
                    );
                    sendingLoading.value = false;
                    cleanForm();
                    fetchBonfireEvents();
                });
            } else if (action == "edit") {
                axiosToken({
                    method: "put",
                    url:
                        "bonfire/shedule-game/" +
                        selectedShedule.value.shedule_id,
                    body
                }).then(e => {
                    resetSelected();
                    fetchBonfireEvents();
                    notify(
                        {
                            group: "top",
                            title: t("general.success"),
                            text: t("bonfire.update_event")
                        },
                        4000
                    );
                    sendingLoading.value = false;
                });
            }
        };

        onMounted(() => {
            fetchTeams();
            fetchBonfireEvents();
            fetchRooms();
        });
        // shedule Game

        const bonfireSchedule = ref([]);
        const loadingBonfireShedule = ref(false);
        const num_weeks = 4;
        const fetchBonfireEvents = () => {
            loadingBonfireShedule.value = false;
            axiosToken({
                method: "get",
                url: "bonfire/bonfire-event"
            }).then(response => {
                function getFirstDayOfWeek(d) {
                    const date = new Date(d);
                    const day = date.getDay();
                    const diff = date.getDate() - day + (day === 0 ? -6 : 1);

                    return new Date(date.setDate(diff));
                }
                let firstDay = getFirstDayOfWeek(new Date()).setHours(
                    0,
                    0,
                    0,
                    0
                );

                response.data.sort(
                    (a, b) =>
                        Date.parse(new Date(a.start)) -
                        Date.parse(new Date(b.start))
                );
                console.log(response);
                let dataShow = [];
                for (let i = 0; i < num_weeks + 1; i++) {
                    let next_week = firstDay + 7 * 24 * 60 * 60 * 1000;

                    if (i != num_weeks) {
                        dataShow.push({
                            week_day: firstDay,
                            week: response.data.filter(
                                e =>
                                    new Date(e.start) < next_week &&
                                    new Date(e.start) >= firstDay
                            )
                        });

                        firstDay = next_week;
                    } else {
                        dataShow.push({
                            week_day: "NaN",
                            week: response.data.filter(
                                e => new Date(e.start) >= firstDay
                            )
                        });
                    }
                }
                console.log(dataShow, response.data);
                bonfireSchedule.value = dataShow;

                console.log(bonfireSchedule.value);
                loadingBonfireShedule.value = true;

                /*const next_week = firstDay + 7 * 24 * 60 * 60 * 1000;
                const next_two_week = firstDay + 14 * 24 * 60 * 60 * 1000;
                response.data.sort(
                    (a, b) =>
                        Date.parse(new Date(a.start)) -
                        Date.parse(new Date(b.start))
                );
                const dataShow = {
                    week_day: firstDay,
                    week_2_day: next_week,
                    week: response.data.filter(
                        e => new Date(e.start) < next_week
                    ),
                    week_2: response.data.filter(
                        e =>
                            new Date(e.start) < next_two_week &&
                            new Date(e.start) > next_week
                    )
                };
                console.log(dataShow, response.data);
                bonfireSchedule.value = dataShow;

                console.log(bonfireSchedule.value);
                loadingBonfireShedule.value = true;*/
            });
        };

        //editar
        const [sheduleType, setSheduleType] = useState(0);
        const editShedule = ref({});
        const editingShedule = ref(false);
        const selectedShedule = ref({
            shedule_id: null,
            type: null
        });
        const resetSelected = () => {
            createRoomLoading.value = false;
            editingShedule.value = false;
            setBonfireMenu(false);
        };
        const editarShedule = (shedule_, type) => {
            setBonfireMenu(true);
            editingShedule.value = true;
            if (type == "once") {
                setSheduleType(0);
                newSheduleBonfire.form = {
                    name: shedule_.name,
                    date: moment(
                        new Date(
                            new Date(shedule_.start) - tzoffset
                        ).toISOString()
                    ).format("D MMM, YYYY"),
                    hour: moment(
                        new Date(new Date(shedule_.start)).toISOString()
                    ).format("HH:mm"),
                    msg: shedule_.msg,
                    duration: durations.findIndex(
                        e => e.int == shedule_.duration
                    ),
                    remainder_email: shedule_.remainder_email,
                    invite_email: shedule_.invite_email
                };
                if (shedule_.team == "all") {
                    newSheduleBonfire.form.team = "all";
                } else {
                    newSheduleBonfire.form.team = shedule_.team.id;
                }
                selectedShedule.value.type = "once";
                myTimeZone.value = timeZones.find(e =>
                    e.utc.find(y => y == shedule_.time_zone)
                ).value;
                selectedShedule.value.shedule_id = shedule_.shedule_id;
            } else if (type == "openRoom") {
                setSheduleType(2);
                formBonfireRoom.form.name = shedule_.name;
                selectedShedule.value.type = "open";
                selectedShedule.value.shedule_id = shedule_.id;
            } else if (type == "recurrent") {
                setSheduleType(1);
                console.log(shedule_);
                formTeamGame.form = {
                    name: shedule_.name,
                    hour: moment(
                        new Date(new Date(shedule_.start)).toISOString()
                    ).format("HH:mm"),
                    msg: shedule_.msg,
                    duration: durations.findIndex(
                        e => e.int == shedule_.duration
                    ),
                    often: shedule_.recurrent.often,
                    day: shedule_.recurrent.day,
                    remainder_email: shedule_.remainder_email,
                    invite_email: shedule_.invite_email
                };
                if (shedule_.team == "all") {
                    formTeamGame.form.team = "all";
                } else {
                    formTeamGame.form.team = shedule_.team.id;
                }
                selectedShedule.value.type = "recurrent";
                myTimeZone.value = timeZones.find(e =>
                    e.utc.find(y => y == shedule_.time_zone)
                ).value;
                selectedShedule.value.shedule_id = shedule_.shedule_id;
            }
        };

        return {
            bonfireMenu,
            setBonfireMenu,
            teams,
            v$,
            v2$,
            bookingApi,
            sendingLoading,
            bonfireSchedule,
            createRoom,
            createRoomLoading,
            v3$,
            bonfireRooms,
            copyLink,
            myTimeZone,
            durations,
            editarShedule,
            editingShedule,
            selectedShedule,
            sheduleType,
            setSheduleType,
            bonfireRoomSelector,
            setBonfireRoomSelector,
            loadingBonfireRooms,
            resetSelected,
            loadingBonfireShedule,
            formTeamGame,
            bookingApiRecurrent,
            teamSelected,
            setTeamSelected,
            openSendInvitationsModal,
            sendInvitationsTeamSelected,
            sendInvitations,
            teamLength,
            teamSize,
            newSheduleBonfire
        };
    }
});
</script>

<style lang="scss" scoped>
.tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
.tooltip:hover .tooltiptext {
    visibility: visible;
}
.tooltip .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    top: 100%;
    left: 50%;
    margin-left: -100%; /* Use half of the width (120/2 = 60), to center the tooltip */
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    width: 200%;
}

.max-card {
    max-width: 900px;
}
.carousel__prev,
.carousel__next {
    @apply bg-bonfire-100;
}
.carousel__pagination-button {
    @apply bg-bonfire-80;
}

.carousel__pagination-button--active {
    @apply bg-bonfire-100;
}

.eventSelected {
    @apply border-primary-1 border-2;
}

::-webkit-scrollbar-track {
    border: 1px solid transparent;
    padding: 2px 0;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    @apply bg-primarycustom-100 border border-primarycustom-100;
}

.is-loading {
    .image,
    h2,
    p {
        background: #eee;
        background: linear-gradient(
            110deg,
            #ececec 8%,
            #f5f5f5 18%,
            #ececec 33%
        );
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }

    .image {
        height: 200px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    h2 {
        height: 30px;
    }

    p {
        height: 20px;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.active-bonfire {
    @apply border-soft-100;
}

.activate-day {
    @apply bg-soft-100 text-white;
}
.active-event-type {
    @apply bg-soft-100 text-white;
}

.form-shedule {
    @media (max-width: 1024px) {
        @apply hidden;
    }
}
</style>

<template>
        <div class="block xl:grid grid-cols-2 gap-4 bg-theme-1 ">
                <!-- BEGIN: Login Info -->
                <div class="hidden xl:flex flex-col min-h-screen  items-center justify-center">
                    <img
                        :src="require(`@/assets/images/extra.svg`)"
                        alt=""
                        class="w-2/4 animation-image"
                    >
                </div>
                <!-- END: Login Info -->
                <!-- BEGIN: Login Form -->
                <div class="h-screen xl:h-auto flex-col flex py-5 xl:py-0 xl:my-0 xl:bg-white">
                    <div class="flex flex-row ml-auto">
                            <select name="" id="" 
                                    class="py-3 px-4 m-3 rounded-md block background-input w-full" 
                                    v-model="$i18n.locale" 
                                    v-on:change="$store.dispatch('main/persistLanguage',$i18n.locale)"
                            >
                            <option v-for="(lang, key) in $store.state.main.languages" :key="key" :value="key">
                                {{ lang }}
                            </option>
                        </select>
                    </div>
                    <slot></slot>
                </div>
                <!-- END: Login Form -->
            </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";


export default defineComponent({
    setup() {
        const test = ()=>{
            console.log("teest")
        }
        onMounted(() => {
            cash("body")
                .removeClass("main")
                .removeClass("error-page")
                .addClass("login");
        });

        return {
            test
        };
    },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.animation-image {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.background-input{
    background: rgba(225, 231, 240, 0.5);
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}
</style>
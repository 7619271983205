<template>
    <transition
        name="view"
        mode="out-in"
    >
        <div
            class="flex flex-col items-center justify-center bg-opacity-25 h-screen bg-gray-800 rounded-xl relative"
            v-if="!$store.state.auth.trial.state && $store.state.auth.organitzation.plan =='free' "
            :class="class_area"
        >
            <h2 class="text-center text-text-100 text-2xl ">
                {{$t('trial.endTrial')}}
            </h2>
        </div>
        <div v-else>
            <slot>

            </slot>
        </div>
    </transition>
</template>

<script>
export default {
    name: "AreaTrial",
    props: {
        class_area: { type: String, default: "" },
        class_loader: { type: String, default: "" }
    }
};
</script>

<style>
</style>
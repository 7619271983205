<template>
    <div>
        <div class="">
            <div class="block xl:grid grid-cols-2 gap-4 bg-theme-1">
                <!-- BEGIN: Register Info -->
                <div class="hidden xl:flex flex-col min-h-screen  items-center justify-center">
                    <img
                        :src="require(`@/assets/images/extra.svg`)"
                        alt=""
                        class="w-2/4 animation-image"
                    >
                </div>
                
                <!-- END: Register Info -->
                <!-- BEGIN: Register Form -->

                <div class="h-screen xl:h-auto flex-col flex py-5 xl:py-0 xl:my-0 xl:bg-white">
                   
                    <div class="flex flex-row ml-auto">
                        <select name="" id="" class="py-3 px-4 m-3 rounded-md block background-input w-full" v-model="$i18n.locale">
                            <option v-for="(lang, key) in $store.state.main.languages" :key="key" :value="key">

                                {{ lang }}
                            </option>
                        </select>
                    </div>
                    <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-white-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                        <div v-if="register">
                            <h2 class=" intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left
                                ">
                                {{$t('register.signup')}}
                            </h2>
                            <!-- <div class=" intro-x mt-2 text-gray-500 dark:text-gray-500 xl:hidden text-center
                                ">
                                A few more clicks to sign in to your account. Manage
                                all your e-commerce accounts in one place
                            </div> -->
                            <form @submit.prevent="onSubmit">
                                <div class="intro-x mt-8">
                                    <!-- <input
                                        v-model="v$.form.firstName.$model"
                                        type="text"
                                        class="py-3 px-4  rounded-md block background-input w-full
                                        "
                                        placeholder="Name"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="(error, index) of v$.form.firstName
                                            .$errors"
                                        :key="index"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div> -->
                                    <input
                                        v-model="v$.form.email.$model"
                                        type="text"
                                        class="py-3 px-4  rounded-md block background-input w-full mt-4
                                        "
                                        :placeholder="$t('register.placeholder_email')"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="(error, index) of v$.form.email
                                            .$errors"
                                        :key="index"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <input
                                        v-model="v$.form.password.$model"
                                        type="password"
                                        class="py-3 px-4  rounded-md block background-input w-full mt-4
                                        "
                                        :placeholder="$t('register.placeholder_password')"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="(error, index) of v$.form.password
                                            .$errors"
                                        :key="index"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <input
                                        v-model="v$.form.confirmPassword.$model"
                                        type="password"
                                        class="py-3 px-4  rounded-md block background-input w-full mt-4
                                        "
                                        :placeholder="$t('register.placeholder_password_confirmation')"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="(error, index) of v$.form
                                            .confirmPassword.$errors"
                                        :key="index"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class=" intro-x flex items-center text-black dark:text-gray-600 mt-4 text-xs sm:text-sm 
                                    ">
                                    <input
                                        v-model="v$.form.checkbox.$model"
                                        id="remember-me"
                                        type="checkbox"
                                        class="form-check-input border mr-2 "
                                    />
                                    <label
                                        class="cursor-pointer select-none"
                                        for="remember-me"
                                    >{{$t('register.terms_1')}}</label>
                                    <a
                                        target="_blank"
                                        class="font-bold ml-1"
                                        href="https://remoteboost.io/es/privacy-policy"
                                    >{{$t('register.terms_2')}}</a>.
                                </div>
                                <p v-if="$store.state.auth.errorRegister">
                                    {{$store.state.auth.errorRegister}}
                                </p>
                                <p v-if="$store.state.auth.registerSuccess">
                                    {{ $t('register_activate.alert_register_email')}}
                                </p>
                                <div class="intro-xmt-5xl:mt-8text-centerxl:text-left mt-1
                                    ">
                                    <button
                                        :disabled="v$.form.$invalid || $store.state.auth.startRegister"
                                        class=" btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top
                                        "
                                    >
                                        {{$t('register.btn_register')}}
                                    </button>
                                    <router-link to="login" class="
                                        btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top
                                        ">
                                        {{$t('register.btn_login')}}
                                    </router-link>
                                </div>
                            </form>
                        </div>
                        <div v-else class="p-20 mr-10">
                            <h2 class=" intro-x font-bold text-3xl text-center mb-5">
                                Coming Soon
                            </h2>
                            <p class="text-text-100 text-lg">
                                {{ $t('register.early_1') }}
                            </p>
                            <p class="text-text-100 text-lg">
                                {{ $t('register.early_2') }}
                            </p>
                            <div class="flex flex-col gap-5 justify-center items-center">
                             <input
                             v-model="v2$.form.email.$model"
                                type="text"
                                class="py-3 px-4  rounded-md block background-input w-full mt-4
                                "
                                :placeholder="$t('register.placeholder_email')"
                            />
                            <Btn
                                :disabled="v2$.form.$invalid "
                                class=" btn btn-primary py-3 px-4 "
                                :isLoading="earlyLoading"
                                v-on:click="earlysubmit()"
                            >
                                {{ $t('register.btn_notify') }}
                            </Btn>

                            </div>
                            <p class="text-text-100 text-lg mt-2">
                                {{ $t('register.early_3') }}
                                <a class="text-dark-1" href="mailto:contact@remoteboost.io">contact@remoteboost.io</a>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- END: Register Form -->
            </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
    computed,
    getCurrentInstance,
    ref,
} from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import { useStore } from "vuex";
import Btn from '@/global-components/btn/Main.vue'
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";
import axios from 'axios'

export default defineComponent({
    components: {
        Btn,
    },
    setup(data) {
        const { t } = useI18n();
        const earlyLoading = ref(false)
        // early birds
        const earlysubmit = () =>{
            earlyLoading.value = true
            axios
                .post(process.env.VUE_APP_API_URL + 'accounts/earlyBird', {email:stateEarly.form.email})
            .then(e => {
                earlyLoading.value = false
                notify(
                    {
                        group: "top",
                        title: "Success",
                        text: t('register.early_notifty') ,
                    },
                    4000
                );
                stateEarly.form.email = ""
            })

        }
        const stateEarly = reactive({
            form: {
                email: "",

            },
        });
        const rulesEarly = computed(() => ({
            form: {

                email: { required, email },
 

            },
        }));




        const register = true;
        const state = reactive({
            form: {
                // firstName: "",
                email: "",
                password: "",
                confirmPassword: "",
                checkbox: false,
            },
        });
        const rules = computed(() => ({
            form: {
                // firstName: {
                //     required,
                //     min: minLength(3),
                // },
                email: { required, email },
                password: { required, min: minLength(8) },
                confirmPassword: {
                    required,
                    sameAsPassword: sameAs(state.form.password),
                },
                checkbox: { required, sameAs: sameAs(true) },
            },
        }));
        const store = useStore();
        onMounted(() => {
            cash("body")
                .removeClass("main")
                .removeClass("error-page")
                .addClass("login");
        });
        console.log(state.form.password);
        const onSubmit = () => {
            store.dispatch("auth/register", {
                // username: state.form.firstName,
                email: state.form.email,
                password1: state.form.password,
                password2: state.form.confirmPassword,
            });
            
        };
        const v$ = useVuelidate(rules, state);
        const v2$ = useVuelidate(rulesEarly, stateEarly);
        return { v$,v2$, onSubmit,register,earlyLoading ,earlysubmit};
    },
});
</script>
<style lang="scss" scoped>
.animation-image {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.background-input {
    background: rgba(225, 231, 240, 0.5);
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}
</style>
<template>
    <Screen :loading="!loading">
        <div class="intro-y flex items-center mt-8">
            <h2
                class="font-black text-2xl xl:text-3xl text-center xl:text-left"
            >
                {{ $t("organization.title") }}
            </h2>
        </div>
        <div class="grid grid-cols-12 gap-6">
            <!-- BEGIN: Profile Menu -->
            <div
                class="col-span-12 lg:col-span-3 flex lg:block flex-col-reverse"
            >
                <div class="intro-y box mt-5">
                    <div class="relative flex flex-col items-center p-5 ">
                        <div class="text-xl text-text-120 ml-4 mr-auto">
                            {{ $store.state.auth.organitzation.name }}
                        </div>
                        <div
                            class="font-medium text-base text-text-120 ml-4 mr-auto"
                        >
                            <p v-if="allUsers.length > 0">
                                {{ $t("organization.users") }} :
                                {{ allUsers.length }}
                            </p>
                        </div>
                        <div
                            class="font-medium text-base text-text-120 ml-4 mr-auto"
                        >
                            {{ $t("organization.plan") }} :
                            <span class="capitalize">
                                {{ $store.state.auth.organitzation.plan }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="col-span-12 lg:col-span-9 flex lg:block flex-col-reverse"
            >
                <div class="intro-y box mt-5 pb-2 flex flex-col">
                    <div
                        class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
                    >
                        <h2 class="font-medium text-base mr-auto">
                            {{ $t("organization.change_settings") }}
                        </h2>
                    </div>
                    <div
                        class="relative flex flex-col gap-5 items-center lg:items-start pt-2 pl-5 "
                    >
                        <label for="update-profile-form-1" class="form-label">{{
                            $t("organization.invitations_language")
                        }}</label>
                    </div>
                    <div class="flex flex-row pl-5 gap-5">
                        <div class=" w-10/12 items-start justify-center">
                            <select
                                name=""
                                id=""
                                class="py-3 px-4 rounded-md block bg-white border-2 w-full"
                                v-model="selectedLanguage"
                            >
                                <option
                                    v-for="(lang, key) in $store.state.main
                                        .languages"
                                    :key="key"
                                    :value="key"
                                >
                                    {{ lang }}
                                </option>
                            </select>
                        </div>
                        <div class="w-2/12 pr-5">
                            <button
                                type="button"
                                class="btn btn-primary-soft py-3 px-4 w-full"
                                v-on:click="updateOrganization()"
                                v-if="
                                    $store.getters.getPermission(
                                        'organization.edit'
                                    )
                                "
                            >
                                {{ $t("general.update") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="flex flex-col w-full h-full"
            v-if="
                false
                /*$store.state.auth.rol === 'admin' ||
                    $store.state.auth.rol === 'super'*/
            "
        >
            <div
                class="flex flex-row w-full justify-start text-left mt-8 gap-10"
            >
                <h2 class="font-black text-2xl xl:text-3xl xl:text-left">
                    Slack chats
                </h2>
                <a
                    href="https://slack.com/oauth/v2/authorize?client_id=1458275660774.3776359621745&scope=app_mentions:read,channels:history,channels:manage,channels:read,chat:write,chat:write.public,commands,groups:history,groups:read,groups:write,im:history,im:read,im:write,mpim:history,users.profile:read,users:read,channels:join,files:read,mpim:read,mpim:write,reactions:read,team:read,users:read.email&user_scope=identity.basic"
                    ><img
                        alt="Add to Slack"
                        height="40"
                        width="139"
                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                /></a>
            </div>
            <div class="grid grid-cols-12 gap-6 mt-5 ">
                <CardSlack
                    :value="'#bonfire-channels'"
                    :text="
                        'Channels to play bonfire, with reminders of your games'
                    "
                    :typeOfValue="' &#128293;'"
                    :numberDecimals="2"
                    :inputTxt="'bonfire-channels'"
                    @click="openModal('bonfire-channels')"
                />
                <CardSlack
                    :value="'#virtual-coffee'"
                    :text="
                        'Build more human connection through virtual coffee meetings.'
                    "
                    :typeOfValue="' &#9749;'"
                    :numberDecimals="2"
                    :inputTxt="'virtual-coffee'"
                    @click="openModal('virtual-coffee')"
                />
                <ConfirmModal
                    :title="'Create channel'"
                    :actionAccept="createChannel"
                >
                    <input
                        v-model="inputText"
                        type="text"
                        class="py-3 px-4 rounded-md block background-input w-full ml-5 mr-5"
                    />
                </ConfirmModal>
            </div>
        </div>
    </Screen>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    computed,
    onMounted,
    watch
} from "vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import CardSlack from "@/components/cards/CardSlack.vue";
import { axiosToken } from "@/libs/http";
import Screen from "@/global-components/loading/Screen";
import { useStore } from "vuex";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export default defineComponent({
    components: {
        Screen,
        CardSlack,
        ConfirmModal
    },
    setup() {
        const { t } = useI18n();
        const allUsers = ref([]);
        const loading = ref(false);
        const store = useStore();
        const inputText = ref("");
        const inputTxtRef = ref(null);
        let defaultNameChannel = "";
        const route = useRoute();

        let selectedLanguage = ref(store.state.auth.organitzation.language);
        watch(
            () => store.state.auth.organitzation.language,
            () => {
                selectedLanguage.value =
                    store.state.auth.organitzation.language;
            }
        );
        onMounted(() => {
            fetchUsers();
            installSlack();
        });

        const installSlack = () => {
            if (route.query.code !== undefined) {
                axiosToken({
                    method: "get",
                    url: "slack/install?code=" + route.query.code
                }).then(response => {
                    notify(
                        {
                            group: "top",
                            title: t("general.success"),
                            text: "Slack app installed"
                        },
                        4000
                    );
                    console.log(response);
                });
            }
        };

        const openModal = name => {
            defaultNameChannel = name;
            inputText.value = name;
            cash("#confirm-modal").modal("show");
        };

        const createChannel = async () => {
            if (inputText.value) {
                axiosToken({
                    method: "POST",
                    url: "slack/createChannel",
                    body: {
                        name: inputText.value,
                        defaultNameChannel: defaultNameChannel,
                        email: store.state.auth.userInfo.email
                    }
                }).then(response => {
                    return response.data;
                });
            }
        };

        const updateOrganization = () => {
            axiosToken({
                method: "PUT",
                url: "accounts/organitzation",
                body: {
                    language: selectedLanguage.value
                }
            })
                .then(response => {
                    notify(
                        {
                            group: "top",
                            title: t("general.success"),
                            text: t("organization.alert_success_updating")
                        },
                        4000
                    );
                    store.commit("auth/setOrganitzationData", response.data);
                })
                .catch(response => {
                    notify(
                        {
                            group: "bottom",
                            title: t("general.error"),
                            text: t("organization.alert_error_updating")
                        },
                        4000
                    );
                });
        };

        const fetchUsers = () => {
            axiosToken({
                method: "get",
                url: "accounts/userteam"
            }).then(response => {
                loading.value = true;
                allUsers.value = response.data;
            });
        };

        return {
            allUsers,
            loading,
            updateOrganization,
            selectedLanguage,
            createChannel,
            inputText,
            openModal
        };
    }
});
</script>

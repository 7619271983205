<template>
   <transition
            name="view"
            mode="out-in"
            
    >
        <div class="flex flex-col items-center justify-center h-screen" v-if="loading" :class="class">
            <img
                class="w-1/12 animation-image"
                src="@/assets/icons/preloader.svg "
                alt=""
            >
        </div>
        <div v-else >
            <slot >
                
            </slot>
        </div>
    </transition>
</template>

<script>
export default {
    props:{
        loading:{type:Boolean, default:false},
        class:{type:String, default:""}
    }
};
</script>

<style>
</style>
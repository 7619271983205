import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import './libs'
import Notifications from 'notiwind'
// SASS Theme
import './assets/sass/app.scss'
import moment from 'moment';
import { i18n } from './i18n'


const app = createApp(App)
  .use(store)
  .use(router)
  .use(Notifications)

app.config.globalProperties.$filters = {
  formatDate(value) {
    const date = new Date(value)
    return moment(date).format('DD/MM/YYYY HH:mm')
  },
  formatDateDay(value) {
    const date = new Date(value)
    return moment(date).format('DD/MM/YYYY')
  }
  ,
  formatDateHour(value) {
    const date = new Date(value)
    return moment(date).format('HH:mm ')
  }
}
import InlineSvg from 'vue-inline-svg';
app.component('inline-svg', InlineSvg);
app.use(i18n)
globalComponents(app)
utils(app)

app.mount('#app')


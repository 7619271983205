<template>
    <span>
        <div class="flex flex-row mt-3">
            <h2
                class="font-black text-2xl xl:text-3xl text-center xl:text-left"
            >
                {{ $t("teams.title") }}
            </h2>
            <div class="flex flex-row ml-auto">
                <a
                    data-target="#team-modal-add"
                    data-toggle="modal"
                    class="btn btn-primary-soft w-24 mr-1 capitalize"
                    v-if="$store.getters.getPermission('team.add_team')"
                >
                    {{ $t("teams.addteam_btn") }}
                </a>
                <a
                    class="btn btn-primary-soft w-24 mr-1 capitalize"
                    data-target="#user-modal"
                    data-toggle="modal"
                    v-on:click="cleanForm()"
                    v-if="$store.getters.getPermission('team.add_user')"
                    >{{ $t("teams.adduser_btn") }}
                </a>
            </div>
        </div>

        <div class="flex flex-row gap-5">
            <!-- BEGIN: Modal Toggle -->

            <div class="flex w-1/4">
                <div class="intro-y mt-5 w-full">
                    <ul
                        class=" list-players flex flex-col bg-white rounded-lg border border-border-100 "
                    >
                        <li
                            class="flex flex-row justify-end p-5"
                            :class="
                                selectedTeam == 0
                                    ? 'bg-primarycustom-80 text-primarycustom-120 '
                                    : ''
                            "
                        >
                            <button
                                class="mr-auto w-full text-justify  "
                                :class="selectedTeam == 0 ? 'font-bold' : ''"
                                v-on:click="selectTeam(0)"
                            >
                                # {{ $t("teams.all_teams") }}
                            </button>
                            <div class="flex flex-row gap-2">
                                <!-- <p class=" justify-end my-auto bg-theme-16 p-1 rounded-md">
                                    44
                                </p> -->
                            </div>
                        </li>
                        <li
                            class="flex flex-row justify-end p-5"
                            v-for="team in teams"
                            :key="team"
                            :class="
                                selectedTeam == team.id
                                    ? 'bg-primarycustom-80 text-primarycustom-120 font-bold'
                                    : ''
                            "
                        >
                            <button
                                v-on:click="selectTeam(team.id)"
                                class="mr-auto w-full text-justify capitalize font-normal "
                                :class="
                                    selectedTeam == team.id
                                        ? 'font-extrabold '
                                        : ''
                                "
                            >
                                # {{ team.name }}
                            </button>
                            <div class="flex flex-row gap-2">
                                <!-- <p class=" justify-end my-auto bg-theme-16 p-1 rounded-md">
                                    44
                                </p> -->
                                <div class="">
                                    <a
                                        v-if="
                                            $store.getters.getPermission(
                                                'team.edit_team'
                                            )
                                        "
                                        v-on:click="editTeam(team.id)"
                                    >
                                        <Edit2Icon
                                            class="block mx-auto cursor-pointer"
                                        />
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="w-full">
                <Screen :loading="!loadingUsers">
                    <div
                        class="grid grid-cols-1 gap-2 w-full 2xl:grid-cols-3 lg:grid-cols-2"
                    >
                        <div
                            class="intro-y mt-5 w-full"
                            v-for="user in allUsers"
                            :key="user.id"
                        >
                            <div
                                class="bg-white rounded-lg p-5 flex flex-col w-full h-full"
                            >
                                <div class="flex flex-row">
                                    <div class="w-1/4 p-2">
                                        <div
                                            class="w-full mx-auto mb-2 aspect-square"
                                        >
                                            <img
                                                v-if="user.image"
                                                class="object-cover h-full w-full  rounded-full "
                                                :src="user.image"
                                            />
                                            <img
                                                v-else
                                                class="object-cover h-full w-full  rounded-full"
                                                src="@/assets/images/user-solid.svg"
                                            />
                                        </div>
                                        <p
                                            class="text-primary-1 capitalize text-center"
                                            v-if="user.rol == 'regular_user'"
                                        >
                                            {{ $t("teams.rol_user") }}
                                        </p>
                                        <p
                                            class="text-primary-1 capitalize text-center"
                                            v-else-if="user.rol == 'team_lead'"
                                        >
                                            Team Lead
                                        </p>
                                        <p
                                            class="text-primary-1 capitalize text-center"
                                            v-else
                                        >
                                            {{ user.rol }}
                                        </p>
                                    </div>

                                    <div class="w-2/4 mt-3 ">
                                        <div
                                            class="text-text-100   mt-0.5 truncate font-extrabold"
                                        >
                                            {{ user.name }}
                                        </div>
                                        <div
                                            class="text-text-100 font-bold mt-0.5 truncate"
                                        >
                                            {{ user.title }}
                                        </div>

                                        <div
                                            class="text-text-100   mt-0.5 truncate"
                                        >
                                            {{ user.email }}
                                        </div>
                                        <div
                                            class="text-text-100  mt-0.5 truncate"
                                            v-for="team in user.team"
                                            :key="team"
                                        >
                                            # {{ team.name }}
                                        </div>
                                    </div>
                                    <div
                                        class="flex w-1/4 items-center justify-center"
                                        v-if="
                                            $store.getters.getPermission(
                                                'team.edit_user'
                                            )
                                        "
                                    >
                                        <a
                                            class="btn btn-outline-secondary py-1 px-2"
                                            data-target="#user-modal-edit"
                                            data-toggle="modal"
                                            v-on:click="
                                                updateModalUser(user.id)
                                            "
                                        >
                                            {{ $t("general.edit") }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Screen>
            </div>
        </div>

        <div id="user-modal" class="modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a data-dismiss="modal" href="javascript:;">
                        <XIcon class="w-8 h-8 text-slate-400" />
                    </a>
                    <div class="modal-body px-5 py-10">
                        <div class="text-center">
                            <div class="mb-5">
                                {{ $t("teams.newuser_title") }}
                            </div>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.newuser_placeholder_name") }}
                            </p>
                            <input
                                v-model="v$.form.name.$model"
                                type="text"
                                class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mb-2"
                                :placeholder="
                                    $t('teams.newuser_placeholder_name')
                                "
                            />
                            <div
                                class="input-errors"
                                v-for="(error, index) of v$.form.name.$errors"
                                :key="index"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.newuser_placeholder_email") }}
                            </p>
                            <input
                                v-model="v$.form.email.$model"
                                type="email"
                                class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mb-2"
                                :placeholder="
                                    $t('teams.newuser_placeholder_email')
                                "
                            />
                            <div
                                class="input-errors"
                                v-for="(error, index) of v$.form.email.$errors"
                                :key="index"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.newuser_placeholder_title") }}
                            </p>
                            <input
                                v-model="v$.form.title.$model"
                                type="text"
                                class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mb-2 "
                                :placeholder="
                                    $t('teams.newuser_placeholder_title')
                                "
                            />
                            <div id="multiple-select" class="">
                                <p class="mr-left text-left text-text-100 my-1">
                                    {{ $t("teams.newuser_team_labble") }}
                                </p>
                                <div class="preview">
                                    <TomSelect
                                        v-model="v$.form.team.$model"
                                        :options="{
                                            placeholder: 'Select the teams'
                                        }"
                                        class="w-full"
                                        multiple
                                    >
                                        <option
                                            v-for="team in teams"
                                            :key="team"
                                            :value="team.id"
                                            >{{ team.name }}</option
                                        >
                                    </TomSelect>
                                </div>
                            </div>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.newuser_rol_labble") }}
                            </p>
                            <select
                                v-model="v$.form.rol.$model"
                                class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2"
                            >
                                <option value="regular_user"
                                    >Regular User</option
                                >
                                <option value="team_lead">Team Lead</option>
                                <option value="admin">Admin</option>
                            </select>
                            <button
                                :disabled="v$.form.$invalid"
                                type="button"
                                v-on:click="newUser()"
                                class="btn btn-primary-soft w-24"
                            >
                                {{ $t("teams.newuser_save_btn") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="user-modal-edit"
            class="modal"
            tabindex="-1"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <a data-dismiss="modal" href="javascript:;">
                        <XIcon class="w-8 h-8 text-slate-400" />
                    </a>
                    <div class="modal-body px-5 py-10">
                        <div class="text-center">
                            <div class="mb-5">
                                {{ $t("teams.edituser_title") }}
                            </div>
                            <p v-if="newUserForm.form.user">
                                {{ $t("teams.edituser_linked_acc") }}
                            </p>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.newuser_placeholder_name") }}
                            </p>
                            <input
                                v-model="v$.form.name.$model"
                                type="text"
                                class="
                                    focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2 
                                "
                                :placeholder="
                                    $t('teams.newuser_placeholder_name')
                                "
                            />
                            <div
                                class="input-errors"
                                v-for="(error, index) of v$.form.name.$errors"
                                :key="index"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.newuser_placeholder_email") }}
                            </p>
                            <input
                                v-model="v$.form.email.$model"
                                :disabled="newUserForm.form.user"
                                type="email"
                                class="
                                focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2 
                                "
                                :placeholder="
                                    $t('teams.newuser_placeholder_email')
                                "
                            />
                            <div
                                class="input-errors"
                                v-for="(error, index) of v$.form.email.$errors"
                                :key="index"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.newuser_placeholder_title") }}
                            </p>
                            <input
                                v-model="v$.form.title.$model"
                                type="text"
                                class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2 "
                                :placeholder="
                                    $t('teams.newuser_placeholder_title')
                                "
                                :disabled="v$.form.user"
                            />
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.newuser_team_labble") }}
                            </p>
                            <div id="multiple-select" class="">
                                <div class="preview">
                                    <TomSelect
                                        v-model="v$.form.team.$model"
                                        :options="{
                                            placeholder: 'Select the teams'
                                        }"
                                        class="w-full"
                                        multiple
                                    >
                                        <option
                                            v-for="team in teams"
                                            :key="team"
                                            :value="team.id"
                                            >{{ team.name }}</option
                                        >
                                    </TomSelect>
                                </div>
                            </div>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.newuser_rol_labble") }}
                            </p>
                            <select
                                :disabled="
                                    $store.state.auth.userInfo.id == idUserEdit
                                "
                                v-model="v$.form.rol.$model"
                                class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2"
                            >
                                <option value="regular_user"
                                    >Regular User</option
                                >
                                <option value="team_lead">Team Lead</option>
                                <option value="admin">Admin</option>
                            </select>
                            <button
                                :disabled="
                                    v$.form.$invalid ||
                                        $store.state.auth.userInfo.id ==
                                            idUserEdit
                                "
                                type="button my"
                                v-on:click="removeUserPush()"
                                class="btn btn-outline mx-2"
                            >
                                {{ $t("teams.edituser_btn_delete") }}
                            </button>
                            <button
                                :disabled="v$.form.$invalid"
                                type="button"
                                v-on:click="editUserPush()"
                                class="btn btn-primary-soft w-24"
                            >
                                {{ $t("general.edit") }}
                            </button>
                            <!-- <button
                                v-if="!newUserForm.form.user"
                                :disabled="v$.form.$invalid || $store.state.auth.userInfo.id == idUserEdit"
                                type="button my"
                                v-on:click="removeUserPush()"
                                class="btn btn-primary w-24 "
                            >
                                Invite 
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="team-modal-add" class="modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a data-dismiss="modal" href="javascript:;">
                        <XIcon class="w-8 h-8 text-slate-400" />
                    </a>
                    <div class="modal-body px-5 py-10">
                        <div class="text-center">
                            <div class="mb-5">
                                {{ $t("teams.newteam_title") }}
                            </div>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.newuser_placeholder_name") }}
                            </p>
                            <input
                                v-model="v$2.form.name.$model"
                                type="text"
                                class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2 "
                                :placeholder="
                                    $t('teams.newteam_placeholder_name')
                                "
                            />
                            <div
                                class="input-errors"
                                v-for="(error, index) of v$2.form.name.$errors"
                                :key="index"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.users") }}
                            </p>

                            <div id="multiple-select" class="mb-16">
                                <div class="preview">
                                    <TomSelect
                                        v-model="v$2.form.users.$model"
                                        :options="{
                                            closeAfterSelect: false,
                                            placeholder: 'Select the users'
                                        }"
                                        class="w-full"
                                        multiple
                                    >
                                        <option
                                            v-for="user in selectUsers"
                                            :key="user"
                                            :value="user.id"
                                            >{{ user.name }}</option
                                        >
                                    </TomSelect>
                                </div>
                            </div>

                            <button
                                :disabled="v$2.form.$invalid"
                                type="button"
                                v-on:click="newTeam()"
                                class="btn btn-primary-soft"
                            >
                                {{ $t("teams.newteam_save_btn") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="team-modal-edit"
            class="modal"
            tabindex="-1"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <a data-dismiss="modal" href="javascript:;">
                        <XIcon class="w-8 h-8 text-slate-400" />
                    </a>
                    <div class="modal-body px-5 py-10">
                        <div class="text-center">
                            <div class="mb-5">
                                {{ $t("teams.editteam_title") }}
                            </div>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.newuser_placeholder_name") }}
                            </p>
                            <input
                                v-model="v$2.form.name.$model"
                                type="text"
                                class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mt-4 mb-2 "
                                :placeholder="
                                    $t('teams.newteam_placeholder_name')
                                "
                            />
                            <div
                                class="input-errors"
                                v-for="(error, index) of v$2.form.name.$errors"
                                :key="index"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <p class="mr-left text-left text-text-100 my-1">
                                {{ $t("teams.users") }}
                            </p>
                            <div id="multiple-select" class="mb-16">
                                <div class="preview">
                                    <TomSelect
                                        v-model="v$2.form.users.$model"
                                        :options="{
                                            closeAfterSelect: false,
                                            placeholder: 'Select the teams'
                                        }"
                                        class="w-full"
                                        multiple
                                    >
                                        <option
                                            v-for="user in selectUsers"
                                            :key="user"
                                            :value="user.id"
                                            >{{ user.name }}</option
                                        >
                                    </TomSelect>
                                </div>
                            </div>
                            <button
                                type="button my"
                                v-on:click="removeTeamPush()"
                                class="btn btn-outline  mx-2"
                            >
                                {{ $t("teams.editteam_btn_delete") }}
                            </button>
                            <button
                                :disabled="v$2.form.$invalid"
                                type="button"
                                v-on:click="editTeamPush()"
                                class="btn btn-primary-soft "
                            >
                                {{ $t("general.edit") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    computed,
    onMounted,
    onUpdated
} from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { axiosToken } from "@/libs/http";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";
import Screen from "@/global-components/loading/Screen.vue";
import { sortArrayDataById } from "@/libs/libs";

export default defineComponent({
    components: {
        Screen
    },
    setup() {
        const { t } = useI18n();

        onMounted(() => {
            fetchUsers();
            fetchTeams();
            console.log("team.add_user");
        });

        onUpdated(() => {
            selectTeam(selectedTeam.value);
        });

        const fetchUsers = () => {
            loadingUsers.value = false;
            axiosToken({
                method: "get",
                url: "accounts/userteam"
            }).then(response => {
                console.log(response.data);
                // var ids = new Set(response.data.map((d) => d.id));
                // var merged = [
                //     ...response.data,
                //     ...allUsers.value.filter((d) => !ids.has(d.id)),
                // ];
                const sortedData = sortArrayDataById(response.data, "asc");
                allUsers.value = sortedData;
                selectUsers.value = sortedData;
                allUsersSave = sortedData;
                loadingUsers.value = true;
                return sortedData;
            });
        };
        let allUsersSave = [];
        const selectUsers = ref([]);
        const allUsers = ref([]);
        const loadingUsers = ref(true);
        const newUserForm = reactive({
            form: {
                name: "",
                email: "",
                title: "",
                team: [],
                rol: "regular_user",
                user: {}
            }
        });
        const rules = computed(() => ({
            form: {
                name: {
                    required,
                    min: minLength(3)
                },
                email: { email },
                title: {},
                team: {},
                rol: {}
            }
        }));

        const newUser = () => {
            axiosToken({
                method: "post",
                url: "accounts/userteam",
                body: {
                    name: newUserForm.form.name,
                    email: newUserForm.form.email,
                    title: newUserForm.form.title,
                    team: newUserForm.form.team,
                    rol: newUserForm.form.rol
                }
            }).then(e => {
                cash("#user-modal").modal("hide");
                fetchUsers();
            });
        };
        let idUserEdit = ref(0);
        const editUserPush = () => {
            axiosToken({
                method: "put",
                url: "accounts/userteam",
                body: {
                    id: idUserEdit.value,
                    name: newUserForm.form.name,
                    email: newUserForm.form.email,
                    title: newUserForm.form.title,
                    team: newUserForm.form.team,
                    rol: newUserForm.form.rol
                }
            })
                .then(e => {
                    cash("#user-modal-edit").modal("hide");
                    cleanForm();
                    fetchUsers();
                })
                .catch(e => {
                    if (e.response.data.error == 1) {
                        notify(
                            {
                                group: "error",
                                title: "Error",
                                text: "This email is already registed"
                            },
                            4000
                        );
                    }
                });
        };

        const removeUserPush = () => {
            axiosToken({
                method: "delete",
                url: "accounts/userteam",
                body: {
                    id: idUserEdit.value
                }
            })
                .then(e => {
                    cash("#user-modal-edit").modal("hide");
                    cleanForm();
                    njp;
                    fetchUsers();
                })
                .catch(e => {
                    if (e.response.data.error == 2) {
                        notify(
                            {
                                group: "top",
                                title: "Error",
                                text: t("teams.alert_linked_account")
                            },
                            4000
                        );
                        cash("#user-modal-edit").modal("hide");
                        return;
                    }
                });
        };

        const updateModalUser = id => {
            idUserEdit.value = id;
            const userEdit = allUsers.value.find(e => e.id == id);
            console.log(userEdit.title);
            newUserForm.form.name = userEdit.name;
            newUserForm.form.email = userEdit.email;
            newUserForm.form.title = userEdit.title;
            newUserForm.form.rol = userEdit.rol;
            newUserForm.form.user = userEdit.user;
            console.log(newUserForm);
            let team_array = [];
            userEdit.team.map(e => team_array.push(e.id));
            newUserForm.form.team = team_array;
        };
        const cleanForm = () => {
            newUserForm.form.name = "";
            newUserForm.form.email = "";
            newUserForm.form.title = "";
            newUserForm.form.team = [];
            newUserForm.form.rol = "regular_user";
            newUserForm.form.user = {};
        };

        const v$ = useVuelidate(rules, newUserForm);

        // TEAM

        const teams = ref([]);

        const fetchTeams = () => {
            axiosToken({
                method: "get",
                url: "accounts/team"
            }).then(response => {
                console.log(response.data);
                teams.value = response.data.sort((a, b) => a.id - b.id);
                return response.data;
            });
        };

        const teamForm = reactive({
            form: {
                name: "",
                users: []
            }
        });
        const rulesTeam = computed(() => ({
            form: {
                name: {
                    required,
                    min: minLength(3)
                },
                users: {}
            }
        }));
        const v$2 = useVuelidate(rulesTeam, teamForm);
        const newTeam = () => {
            axiosToken({
                method: "post",
                url: "accounts/team",
                body: {
                    name: teamForm.form.name,
                    users: teamForm.form.users
                }
            }).then(e => {
                cash("#team-modal-add").modal("hide");
                teamForm.form.name = "";
                fetchTeams();
                fetchUsers();
            });
        };

        let idTeamEdit = 0;
        const editTeam = id => {
            idTeamEdit = id;
            const teamEdit = teams.value.find(e => e.id == id);
            teamForm.form.name = teamEdit.name;
            let usersArray = [];
            selectUsers.value.forEach(element => {
                if (element.team.find(e => e.id == id)) {
                    usersArray.push(element.id);
                }
            });
            console.log(usersArray);
            teamForm.form.users = usersArray;
            cash("#team-modal-edit").modal("show");
        };

        const editTeamPush = () => {
            axiosToken({
                method: "put",
                url: "accounts/team",
                body: {
                    id: idTeamEdit,
                    name: teamForm.form.name,
                    users: teamForm.form.users
                }
            }).then(e => {
                cash("#team-modal-edit").modal("hide");
                teamForm.form.name = "";
                fetchTeams();
                fetchUsers();
            });
        };

        const removeTeamPush = () => {
            axiosToken({
                method: "delete",
                url: "accounts/team",
                body: {
                    id: idTeamEdit,
                    users: teamForm.form.users
                }
            })
                .then(e => {
                    cash("#team-modal-edit").modal("hide");
                    teamForm.form.name = "";
                    fetchTeams();
                    selectedTeam.value = 0;
                    allUsers.value = filterTeams;
                })
                .catch(e => {
                    console.log(e);
                });
        };

        // selector Teams

        const selectedTeam = ref(0);
        const selectTeam = id => {
            selectedTeam.value = id;
            if (id == 0) {
                allUsers.value = allUsersSave;
            } else {
                const filterTeams = allUsersSave.filter(e =>
                    e.team.some(b => b.id == id)
                );
                allUsers.value = filterTeams;
            }
        };
        return {
            v$,
            v$2,
            selectTeam,
            newUserForm,
            newUser,
            allUsers,
            updateModalUser,
            cleanForm,
            newTeam,
            fetchTeams,
            teams,
            editTeam,
            editTeamPush,
            editUserPush,
            selectedTeam,
            removeTeamPush,
            removeUserPush,
            idUserEdit,
            loadingUsers,
            selectUsers
        };
    }
});
</script>

<style lang="scss" scoped>
.list-players > li:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.list-players > li:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.aspect-square {
    aspect-ratio: 1;
}
</style>

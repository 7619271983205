import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import auth from './auth'
import globalVariables from './global-variables'
import permissions from './permissions'
const store = createStore({
  modules: {
    main,
    sideMenu,
    auth,
    globalVariables,
    permissions
  }
})

export function useStore() {
  return store
}

export default store

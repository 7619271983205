<template>
    <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
        <div class="report-box zoom-in">
            <div class="box p-5">
                <div class="flex">
                    <inline-svg
                        :src="require('@/assets/icons/' + props.icon)"
                        alt=""
                        class="h-6 w-6"
                    />
                    <div class="ml-auto">
                        <Tippy
                            tag="div"
                            class="report-box__indicator bg-success cursor-pointer"
                            content="33% Higher than last month"
                        >
                            33%
                            <ChevronUpIcon class="w-4 h-4 ml-0.5" />
                        </Tippy>
                    </div>
                </div>
                <div class="text-3xl font-medium leading-8 mt-6">
                    {{
                        typeof props.value !== "object"
                            ? typeof props.value !== "number"
                                ? props.value
                                : props.value.toFixed(props.numberDecimals)
                            : props.value[language]
                    }}{{ props.typeOfValue }}
                </div>
                <div class="text-base text-slate-500 mt-1">
                    {{ props.text }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onUpdated, ref } from "vue";
export default {
    components: {},

    props: {
        value: { type: [Number, String, Object], default: "-" },
        text: { type: String, default: "-" },
        icon: { type: String, default: "-" },
        typeOfValue: { type: String, default: "" },
        numberDecimals: { type: Number, default: 0 }
    },

    setup(props) {
        const language = ref(localStorage.getItem("language"));
        onUpdated(() => {
            language.value = localStorage.getItem("language");
        });

        return {
            props,
            language
        };
    }
};
</script>

<style></style>

import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL
console.log(API_URL)
class AuthService {
  login (user) {
    
  }

  logout (token) {
    axios
      .post(API_URL + 'api/authentication/logout/', {}, {headers: { Authorization: 'Bearer ' + token //the token is a variable which holds the token
      }})
      .then(e => {
        return localStorage.removeItem('user')
      })
  }

  register (user) {
    console.log(user)
    return axios.post(API_URL + 'api/registration/', {
      // username: user.username,
      email: user.email,
      password1: user.password1,
      password2: user.password2
    })
  }
}

export default new AuthService()

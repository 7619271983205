<template>
    <layout>
        <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-white-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
            <div class="flex flex-col gap-5">
                <h2 class=" font-bold text-2xl xl:text-3xl text-center xl:text-left w-3/4">
                    {{$t('onBoarding.title_org')}}
                </h2>
                <div class=" mt-2">
                    <p class="text-text-100 my-2">
                        {{$t('onBoarding.label_org')}}
                    </p>
                    <input
                        v-model="v$.form.name.$model"
                        type="text"
                        class="py-3 px-4  rounded-md block background-input w-full"
                        :placeholder="$t('onBoarding.placeholder_org')"
                    />
                    <div
                        class="input-errors"
                        v-for="(error, index) of v$.form.name.$errors"
                        :key="index"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                 <div class=" mt-2">
                    <p class="text-text-100 my-2">
                        {{$t('onBoarding.label_size')}}
                    </p>
                    <select 
                        name="" 
                        id="" 
                        class="py-3 px-4 rounded-md block background-input w-full" 
                        v-model="v$.form.size.$model"
                    >
                        <option value="1-10">1-10</option>
                        <option value="11-25">11-25</option>
                        <option value="26-50">26-50</option>
                        <option value="51-100">51-100</option>
                        <option value="101-250">101-250</option>
                        <option value="251-500">251-500</option>
                        <option value="500+">500+</option>
                    </select>
                    <div
                        class="input-errors"
                        v-for="(error, index) of v$.form.size.$errors"
                        :key="index"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                 <div class=" mt-2">
                    <p class="text-text-100 my-2">
                        {{$t('onBoarding.label_language')}}
                    </p>
                    <select 
                        name="" 
                        id="" 
                        class="py-3 px-4 rounded-md block background-input w-full" 
                        v-model="v$.form.language.$model"
                    >
                        <option v-for="(lang, key) in $store.state.main.languages" :key="key" :value="key">
                            {{ lang }}
                        </option>
                    </select>
                    <div
                        class="input-errors"
                        v-for="(error, index) of v$.form.language.$errors"
                        :key="index"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                 <div class=" mt-2">
                    <p class="text-text-100 my-2">
                        {{$t('onBoarding.label_industry')}}
                    </p>
                    <input
                        v-model="v$.form.industry.$model"
                        type="text"
                        class="py-3 px-4  rounded-md block background-input w-full"
                        :placeholder="$t('onBoarding.placeholder_industry')"
                    />
                    <div
                        class="input-errors"
                        v-for="(error, index) of v$.form.industry.$errors"
                        :key="index"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <Btn
                    :disabled="v$.form.$invalid "
                    class=" btn btn-primary py-3 px-4 "
                    :isLoading="loadingOrg"
                    v-on:click="submitOrg()"
                >
                    {{$t('onBoarding.btn_finish')}}
                </Btn>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "@/components/auth/Layout.vue";
import {
    reactive,
    computed,
    ref,

} from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import Btn from "@/global-components/btn/Main.vue";
import {axiosToken} from '@/libs/http.js'
import { useStore } from 'vuex';

export default {
    components: {
        Layout,
        Btn,
    },
    setup() {
        const store = useStore();
        const loadingOrg = ref(false)
        const state = reactive({
            form: {
                name: "",
                size: 0,
                language: "",
                industry: "",
            },
        });
        const rules = computed(() => ({
            form: {
                name: { required, min: minLength(4) },
                size: { required} ,
                language: { required },
                industry: {},
            },
        }));
        const v$ = useVuelidate(rules, state);
        const submitOrg = () => {
            loadingOrg.value = true;
            axiosToken({
                method: "post",
                url: "accounts/organitzation",
                body: {
                    name: state.form.name,
                    size: state.form.size,
                    language: state.form.language,
                    industry: state.form.industry,
                },
            }).then((response) => {
                 store.dispatch("auth/checkOrg")
            });
        };


        return {
            v$,
            submitOrg,
            loadingOrg,
        };
    },
};
</script>

<style>
</style>
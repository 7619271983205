const state = () => {
  return {
    menu: [

      {
        icon: 'UsersIcon',
        custom_icon: false,
        pageName: 'team',
        title: 'Team',
        permission: 'team',
      },
      {
        icon: 'bonfire-stroke-icon.svg',
        custom_icon: true,
        pageName: 'side-menu-dashboard-overview-2',
        title: 'Bonfire',
        permission: 'bonfire',
      },
      {
        icon: 'PlayCircleIcon',
        custom_icon: false,
        pageName: 'side-menu-dashboard-overview-3',
        title: 'Activities',
        permission: 'activities'
      },
      {
        icon: 'CalendarIcon',
        custom_icon: false,
        pageName: 'side-book-Pricing',
        title: 'Bookings',
        permission: 'bookings'
      },
      {
        icon: 'HomeIcon',
        custom_icon: false,
        pageName: 'landing',
        title: 'Organization',
        permission: 'organization'
      },
      {
        icon: 'SettingsIcon',
        custom_icon: false,
        pageName: 'side-menu-settings',
        title: 'Settings',
        permission: 'settings'
      },
      {
        icon: 'FileTextIcon',
        custom_icon: false,
        pageName: 'content',
        title: 'Content',
        permission: 'content'
      },
      {
        icon: 'chart-bar-regular.svg',
        custom_icon: true,
        pageName: 'analytics',
        title: 'Analytics',
        permission: 'analytics'
      },
      "devider",
      {
        icon: 'FileTextIcon',
        custom_icon: false,
        pageName: 'content-admin',
        title: 'ContentAdmin',
        permission: 'content_admin'
      },
      {
        icon: 'desktop-solid.svg',
        custom_icon: true,
        pageName: 'game-usage',
        title: 'GameUsage',
        permission: 'game_usage'
      }

    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

const state = () => {
  return {
    pictionary: {
      minContent: 10,
      maxContent: 20,
    },
    factMatching: {
      minContent: 3,
      maxContent: 5,
    },
    mingleTingle: {
      minContent: 3,
      minQuestionLength: 5,
    },
    quiz: {
      minQuestionLength: 5,
      minResponseLength: 5,
      minContent: 4,
      maxContent: 10,
    },
    sellerSponge: {
      minContent: 10,
      maxContent: 10,
    },
    topo: {
      minContent: 20,
      maxContent: 20,
      maxTags: 20,
    },
    thisOrThat: {
      minQuestionLength: 5,
      minResponseLength: 1
    }
  }
}


export default { state }

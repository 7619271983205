<template>
    <div>
        <div class="intro-y flex items-center mt-8">
            <h2 class="font-black text-2xl xl:text-3xl text-center xl:text-left">{{ $t('update_profile.title') }} </h2>
        </div>
        <div class="grid grid-cols-12 gap-6">
            <!-- BEGIN: Profile Menu -->
            <div class="col-span-12 lg:col-span-4 2xl:col-span-3 flex lg:block flex-col-reverse">
                <div class="intro-y box mt-5">
                    <div class="relative flex items-center p-5">
                        <div class="ml-4 mr-auto">
                            <div class="font-medium text-base text-text-120">
                                {{$store.state.auth.userInfo.name}}
                            </div>
                            <div class="text-text-100">{{$store.state.auth.userInfo.email}}</div>
                            <div class="text-text-100 capitalize">Rol: {{$store.state.auth.rol}}</div>
                        </div>
                    </div>
                    <!-- <div class="p-5 border-t border-gray-200">
                        <a class="flex items-center text-text-100">
                            <ActivityIcon class="w-4 h-4 mr-2" /> Personal Information
                        </a>
                    </div> -->
                </div>
            </div>
            <!-- END: Profile Menu -->
            <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
                <!-- BEGIN: Display Information -->
                <div class="intro-y box lg:mt-5">
                    <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto">{{ $t('update_profile.change_password') }}</h2>
                    </div>
                    <div class="p-5">
                        <div class="flex xl:flex-row ">
                            <div class="flex-1 mt-6 xl:mt-0">
                                <div class="grid grid-cols-12 gap-x-5">
                                    <div class="col-span-12 2xl:col-span-6">
                                        <div>
                                            <label
                                                for="update-profile-form-1"
                                                class="form-label"
                                            >{{ $t('update_profile.password_labble') }}</label>
                                            <input
                                                id="update-profile-form-1"
                                                type="password"
                                                class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mb-2 "
                                                :placeholder=" $t('update_profile.placeholder_password') "
                                                v-model="v$.form.password.$model"
                                            />
                                            <div
                                                class="input-errors"
                                                v-for="(error, index) of v$.form.password
                                        .$errors"
                                                :key="index"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-12 2xl:col-span-6">
                                        <div>
                                            <label
                                                for="update-profile-form-4"
                                                class="form-label"
                                            >{{ $t('update_profile.re_passowrd_labble') }}</label>
                                            <input
                                                id="update-profile-form-4"
                                                type="password"
                                                class="focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block mb-2  "
                                                :placeholder=" $t('update_profile.placeholder_password') "
                                                v-model="v$.form.confirmPassword.$model"
                                            />
                                        </div>
                                        <div
                                            class="input-errors"
                                            v-for="(error, index) of v$.form.confirmPassword
                                        .$errors"
                                            :key="index"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <button
                                    type="button"
                                    class="btn btn-primary-soft w-20 mt-3"
                                    v-on:click="updatePass()"
                                >
                                    {{ $t('general.update') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END: Display Information -->

            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import { axiosToken } from "@/libs/http";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";

export default defineComponent({
    setup() {
        const {t} = useI18n();
        const state = reactive({
            form: {
                password: "",
                confirmPassword: "",
            },
        });
        const rules = computed(() => ({
            form: {
                password: { required, min: minLength(8) },
                confirmPassword: {
                    required,
                    sameAsPassword: sameAs(state.form.password),
                },
            },
        }));
        const v$ = useVuelidate(rules, state);
        const cleanForm = () => {
            state.form.password=""
            state.form.confirmPassword=""
        }
        const updatePass = () => {
            axiosToken({
                method: "POST",
                url: "api/authentication/password/change/",
                body:{
                  new_password1:state.form.password,
                  new_password2:state.form.confirmPassword
                },
            }).then((response) => {
              cleanForm();
                notify(
                    {
                        group: "top",
                        title: t('general.success'),
                        text: t('update_profile.success_password'),
                    },
                    4000
                );
            }).catch((response)=>{
              cleanForm();
              notify(
                    {
                        group: "bottom",
                        title: t('general.error'),
                        text: t('update_profile.errror_password'),
                    },
                    4000
                );
            });
        };
        return {
            v$,
            updatePass,
        };
    },
});
</script>

<template>
    <Layout>
        <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-white-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
            <h2 class=" font-bold text-2xl xl:text-3xl text-center xl:text-left">
                {{ $t('login.signIn_title') }}
            </h2>
            <form id="form_login" @submit.prevent="">
                <div class=" mt-8">
                    <input
                        type="text"
                        v-model="v$.form.email.$model"
                        class="py-3 px-4  rounded-md block background-input w-full"
                        placeholder="Email"
                    />
                    <input
                        v-model="v$.form.password.$model"
                        type="password"
                        class="py-3 px-4  rounded-md block mt-4 background-input w-full"
                        placeholder="Password"
                    />
                </div>
                <br>
                <transition
                    name="fade"
                    v-if="$store.state.auth.errorLogin"
                >
                    <p>{{ $t('login.password_error') }}</p>
                </transition>
                <div class=" flex text-text-100 dark:text-text-100 text-xs sm:text-sm mt-4">
                    <router-link to="/forget-password">{{ $t('login.forgot_password') }}</router-link>
                </div>
                <div class=" mt-5 xl:mt-8 text-center xl:text-left">
                    <Btn
                        type="submit"
                        
                        :isLoading="$store.state.auth.loginLoading"
                        class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                        v-on:click="login()"
                    >
                        {{ $t('login.login_btn') }}
                    </Btn>
                    <router-link
                        to="/register"
                        class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
                    >
                        {{ $t('login.signup_btn') }}
                    </router-link>
                    
                </div>
                <div class="flex flex-row mt-3">
                    <a :href="urlGoogle" class="w-full">
                        <button type="button" class="login-with-google-btn py-3 px-4 w-full xl:w-64 mt-3 xl:mt-0 align-top" >
                            Login in with Google
                        </button>
                    </a>
                </div>
                
            </form>
            <div class="intro-x mt-10 xl:mt-24 text-text-100 dark:text-text-100 text-center xl:text-left">
               {{ $t('login.term_text') }}<br />
                <a
                    class="text-primary-1 "
                    href="https://remoteboost.io/es/legal-advise"
                >{{ $t('login.term') }} &nbsp;</a>
                
                <a
                    class="text-primary-1 "
                    href="https://remoteboost.io/es/privacy-policy"
                >{{ $t('login.privacy') }}</a>
                
            </div>
        </div>
    </Layout>
</template>

<script>
import { defineComponent, onMounted, ref, computed, reactive } from "vue";
import { useStore } from "vuex";
import Layout from "@/components/auth/Layout.vue";
import Btn from "@/global-components/btn/Main.vue"
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
export default defineComponent({
    components: {
        Layout,
        Btn,
    },
    setup() {

        // URL GOOGLE
        const urlGoogle = process.env.VUE_APP_GOOGLE_URL


        const rules = computed(() => ({
            form: {
                email: { required, email },
                password: { required },
            },
        }));

        
        const form = reactive({form:{
            email: null,
            password: null,
        }});
        const v$ = useVuelidate(rules, form);

        const loginLoading=ref(false)
        const errorPassword = ref(false);
        const store = useStore();
        onMounted(() => {
            cash("body")
                .removeClass("main")
                .removeClass("error-page")
                .addClass("login");
        });
        const login = () => {
            
            store.dispatch("auth/login", form.form);
            return store;
        };
        return {
            form,
            login,
            loginLoading,
            v$,
            urlGoogle,
        };
    },
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.animation-image {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.background-input {
    background: rgba(225, 231, 240, 0.5);
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}


.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
    
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #eeeeee;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}














</style>
<template>
    <span>
        <div
            class="flex gap-5 w-full mb-5  "
            v-for="(dat, index) in data.questions"
            :key="dat"
        >
            <div class="flex flex-row w-full flex-wrap content-start ">
                <p>
                    {{ $t("content.quiz.question") }}
                </p>
                <input
                    id="regular-form-1"
                    type="text"
                    class="form-control my-1 w-full"
                    :placeholder="$t('content.quiz.question')"
                    v-model="dat.question"
                    :class="
                        dat.question.trim().length <=
                        $store.state.globalVariables.thisOrThat
                            .minQuestionLength
                            ? 'border-danger-100'
                            : 'border-success-100'
                    "
                />
            </div>
            <div class="flex flex-col w-full flex-wrap content-center ">
                <div class="flex flex-row w-full flex-wrap content-center mb-2">
                    <p>
                        First Response
                    </p>
                    <input
                        id="regular-form-1"
                        type="text"
                        class="form-control  my-1  w-full"
                        :placeholder="$t('content.quiz.correct')"
                        v-model="dat.responses[0]"
                        :class="
                            dat.responses[0].trim().length <=
                            $store.state.globalVariables.thisOrThat
                                .minResponseLength
                                ? 'border-danger-100'
                                : 'border-success-100'
                        "
                        @change="checkUnique(index)"
                    />
                </div>
                <div
                    class="flex flex-row w-full flex-wrap content-center mb-5 "
                >
                    <p>
                        Second Response
                    </p>
                    <input
                        id="regular-form-1"
                        type="text"
                        class="form-control  my-1  w-full"
                        :placeholder="$t('content.quiz.correct')"
                        v-model="dat.responses[1]"
                        :class="
                            dat.responses[1].trim().length <=
                            $store.state.globalVariables.thisOrThat
                                .minResponseLength
                                ? 'border-danger-100'
                                : 'border-success-100'
                        "
                        @change="checkUnique(index)"
                    />
                </div>
                <div
                    class="flex flex-row items-center gap-5 justify-end mt-auto"
                >
                    <p>
                        {{ $t("content.quiz.eliminar") }}
                    </p>
                    <button
                        type="button"
                        v-on:click="removedataContent(index)"
                        class="btn btn-primary-outline  w-16 h-10"
                    >
                        <inline-svg
                            :src="require('@/assets/icons/trash-solid.svg')"
                            alt=""
                            class="h-full"
                        />
                    </button>
                </div>
            </div>
        </div>
        <div class="flex flex-row my-5 items-center gap-3">
            <button
                type="button"
                v-on:click="addNewData()"
                class="btn btn-primary w-16 my-5 h-10"
            >
                <inline-svg
                    :src="require('@/assets/icons/plus-solid.svg')"
                    alt=""
                    class="h-full"
                    style="fill:white;"
                />
            </button>
            <p v-if="dataContent" class="text-lg  ">
                {{ dataContent.length }}
            </p>
        </div>
    </span>
</template>

<script>
import { makeid } from "@/libs/libs";
import { defineComponent, onBeforeMount, onMounted, ref, watch } from "vue";
export default defineComponent({
    props: {
        data: Object,
        all_data: Object,
        id_content: Number
    },

    setup(props) {
        const dataContent = ref(props.data.questions);

        watch(
            () => props.data.questions,
            () => {
                dataContent.value = props.data.questions;
            }
        );

        const addNewData = () => {
            dataContent.value.push({
                question: "",
                responses: ["", ""]
            });
        };

        const removedataContent = index => {
            dataContent.value.splice(index, 1);
        };

        return { addNewData, removedataContent, dataContent };
    }
});
</script>

<style></style>

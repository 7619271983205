<template>
    <div>
        <div class="">
            <div class="block xl:grid grid-cols-2 gap-4 bg-theme-1 ">
                <!-- BEGIN: Login Info -->
                <div class="hidden xl:flex flex-col min-h-screen  items-center justify-center">
                    <img
                        :src="require(`@/assets/images/extra.svg`)"
                        alt=""
                        class="w-2/4 animation-image"
                    >
                </div>
                <!-- END: Login Info -->
                <!-- BEGIN: Login Form -->
                <div class="h-screen xl:h-auto flex py-5 xl:py-0 xl:my-0 xl:bg-white">
                    <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-white-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                        <h2 class=" font-bold text-2xl xl:text-3xl text-center xl:text-left">
                            New Password
                        </h2>
                        <form @submit.prevent="recoveryPassword">
                        <div class=" mt-8">
                            <input
                                type="password"
                                v-model="v$.form.password.$model"
                                class="py-3 px-4  rounded-md block background-input w-full"
                                placeholder="password"
                            />
                            <div
                                    class="input-errors"
                                    v-for="(error, index) of v$.form.password
                                        .$errors"
                                    :key="index"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                        </div>
                        <div class=" mt-8">
                            <input
                                type="password"
                                v-model="v$.form.confirmPassword.$model"
                                class="py-3 px-4  rounded-md block background-input w-full"
                                placeholder="repeat password"
                            />
                            <div
                                    class="input-errors"
                                    v-for="(error, index) of v$.form.confirmPassword
                                        .$errors"
                                    :key="index"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                        </div>
                        <br>
                        <transition
                            name="fade"
                            v-if="restPassWordState==1"
                        >
                            <p>Your password has been succesfully updated, you can log in now.</p>
                        </transition>
                        <transition
                            name="fade"
                            v-if="restPassWordState == 2"
                        >
                        <div>
                            <p>Internal server error</p>
                            <p class="text-primary-1">Please contact <a href="mailto:contact@remoteboost.io">contact@remoteboost.io</a> </p>
                        </div>
                        </transition>
                        <transition
                            name="fade"
                            v-if="restPassWordState == 3"
                        >
                        <div>
                            <p>The link has expired. Please try again: <router-link class="text-primary-1" to="/forget-password"> Recover Password </router-link></p>

                        </div>
                        </transition>
                        <transition
                            name="fade"
                            v-if="restPassWordState == 4"
                        >
                        <div>
                            <p v-for="e in passwordErrorText" :key="e">{{e}}</p>

                        </div>
                        </transition>

                        <div class=" mt-5 xl:mt-8 text-center xl:text-left">
                            <button
                                :disabled="v$.form.$invalid || $store.state.auth.loadingResetPassword"
                                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                                v-on:click="recoveryPassword()"
                            >
                                Reset Password
                            </button>
                            <router-link to="/login" class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top">
                                Log in
                            </router-link>
                        </div>
                        </form>
                        <!-- <div class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
                            By signin up, you agree to our <br />
                            <a
                                class="text-theme-1 dark:text-theme-10"
                                href=""
                            >Terms and Conditions</a>
                            &
                            <a
                                class="text-theme-1 dark:text-theme-10"
                                href=""
                            >Privacy Policy</a>
                        </div> -->
                    </div>
                </div>
                <!-- END: Login Form -->
            </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    onMounted,
    computed,
    ref,reactive
} from "vue";
import { useStore } from "vuex";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import axios from 'axios'

import useVuelidate from "@vuelidate/core";
import { useRoute } from "vue-router";
export default defineComponent({
    setup() {
        const restPassWordState = ref(0)
        const passwordErrorText =ref([])
        const route = useRoute();
        const state = reactive({form:{
            password: "",
            confirmPassword: "",
        }});
        const rules = computed(() => ({
            form: {
                password: { required, min: minLength(8) },
                confirmPassword: {
                    required,
                    sameAsPassword: sameAs(state.form.password),
                },
            },
        }));

        const errorPassword = ref(false);
        const store = useStore();
        onMounted(() => {
            cash("body")
                .removeClass("main")
                .removeClass("error-page")
                .addClass("login");
        });
        const recoveryPassword = () => {
            axios
                .post(process.env.VUE_APP_API_URL + 'api/authentication/password/reset/confirm/',
                 {new_password1:state.form.password,
                 new_password2:state.form.confirmPassword,
                 uid:route.params.uid,
                 token:route.params.token
                 
                 
                 }
                 )
            .then(e => {
                restPassWordState.value = 1
            }).catch(e => {
                console.log(e.response.data)
                if( "token" in e.response.data)
                {
                    restPassWordState.value =3
                }
                else if("new_password2" in e.response.data)
                {
                    restPassWordState.value =4
                    passwordErrorText.value = e.response.data.new_password2
                }
                else
                {
                    restPassWordState.value =2

                }
            }
            )
        };
        const v$ = useVuelidate(rules, state);
        return {
            v$,
            recoveryPassword,
            restPassWordState,
            passwordErrorText,
        };
    },
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.animation-image {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.background-input{
    background: rgba(225, 231, 240, 0.5);
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}
</style>
<template>
    <span>
        <div class="flex flex-col my-3 gap-5">
            <h2
                class="font-black text-2xl xl:text-3xl text-center xl:text-left"
            >
                <span v-if="!admin">
                    {{ $t("content.title") }}
                </span>
                <span v-else>
                    {{ $t("content.title_admin") }}
                </span>
            </h2>
            <p>
                <span class="font-bold">{{ $t("general.help") }}: </span>
                {{ $t("content.text_first_page") }}
            </p>
        </div>
        <Area
            :loading="!loadingContent"
            :class="'my-10'"
            :class_loader="'w-1/4'"
        >
            <div class="grid lg:grid-cols-3 2xl:grid-cols-4 grid-cols-2 gap-5">
                <div
                    class="box p-5 flex-col flex h-full gap-3 "
                    v-for="game in gameId"
                    :key="game"
                >
                    <img :src="game.icon" alt="" class="w-full p-2" />
                    <div
                        class="text-3xl text-text-100 font-medium leading-8 text-center"
                    >
                        {{ game.name }}
                    </div>
                    <p class="text-text-100">
                        {{ game.descripcion_princiapl }}
                    </p>
                    <router-link
                        v-if="!admin"
                        :to="'/content/' + game.name_id.name_id"
                        class="btn btn-primary-soft mt-auto w-36 mx-auto"
                        >{{ $t("content.btn_add_content") }}
                    </router-link>
                    <router-link
                        v-else
                        :to="'/admin-content/' + game.name_id.name_id"
                        class="btn btn-primary-soft mt-auto w-36 mx-auto"
                        >{{ $t("content.btn_add_content") }}
                    </router-link>
                </div>
            </div>
        </Area>
    </span>
</template>

<script>
import { axiosToken } from "@/libs/http";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { sortArrayDataByParameter2 } from "@/libs/libs.js";
import Area from "@/global-components/loading/Area.vue";
export default defineComponent({
    components: {
        Area
    },

    setup() {
        const { locale } = useI18n();
        const router = useRouter();

        const gameId = ref([]);
        const selectedGame = ref(null);
        const admin = ref(false);
        const selectGame = game_name => {
            selectedGame.value = game_name;
        };
        const loadingContent = ref(false);
        watch(
            () => router.currentRoute.value.fullPath,
            () => {
                console.log("change");
                if (window.location.pathname.split("/")[1] == "admin-content") {
                    admin.value = true;
                } else {
                    admin.value = false;
                }
            }
        );
        watch(
            () => locale.value,
            () => {
                loadingContent.value = false;
                axiosToken({
                    url: "activities/bonfire",
                    method: "get",
                    body: { language: locale.value }
                }).then(response => {
                    loadingContent.value = true;
                    gameId.value = response.data.filter(
                        e => e.name_id.custom_content == true
                    );
                    gameId.value = sortArrayDataByParameter2(
                        gameId.value,
                        "desc",
                        "name_id",
                        "id"
                    );
                    console.log(gameId.value);
                });
                if (window.location.pathname.split("/")[1] == "admin-content") {
                    admin.value = true;
                } else {
                    admin.value = false;
                }
            }
        );
        onMounted(() => {
            loadingContent.value = false;
            axiosToken({
                url: "activities/bonfire",
                method: "get",
                body: { language: locale.value }
            }).then(response => {
                loadingContent.value = true;
                gameId.value = response.data.filter(
                    e => e.name_id.custom_content == true
                );
                gameId.value = sortArrayDataByParameter2(
                    gameId.value,
                    "desc",
                    "name_id",
                    "id"
                );
            });
            if (window.location.pathname.split("/")[1] == "admin-content") {
                admin.value = true;
            } else {
                admin.value = false;
            }
        });

        return {
            gameId,
            selectGame,
            selectedGame,
            admin,
            loadingContent
        };
    }
});
</script>

<template>
    <div>
        <div class="flex flex-col gap-2 w-full mt-2">
            <p>
                {{ $t("content.mingleTingle.open_questions") }}
            </p>
            <input
                id="regular-form-1"
                type="text"
                class="form-control w-full"
                :placeholder="$t('content.mingleTingle.open_questions')"
                v-model="data.questions[0]"
                :class="
                    data.questions[0].trim().length <=
                    $store.state.globalVariables.mingleTingle.minQuestionLength
                        ? 'border-danger-100'
                        : 'border-success-100'
                "
            />
            <input
                id="regular-form-1"
                type="text"
                class="form-control w-full"
                :placeholder="$t('content.mingleTingle.open_questions')"
                v-model="data.questions[1]"
                :class="
                    data.questions[1].trim().length <=
                    $store.state.globalVariables.mingleTingle.minQuestionLength
                        ? 'border-danger-100'
                        : 'border-success-100'
                "
            />
            <input
                id="regular-form-1"
                type="text"
                class="form-control w-full"
                :placeholder="$t('content.mingleTingle.open_questions')"
                v-model="data.questions[2]"
                :class="
                    data.questions[2].trim().length <=
                    $store.state.globalVariables.mingleTingle.minQuestionLength
                        ? 'border-danger-100'
                        : 'border-success-100'
                "
            />
            <p>
                {{ $t("content.mingleTingle.final_question") }}
            </p>
            <input
                id="regular-form-1"
                type="text"
                class="form-control w-full"
                :placeholder="$t('content.mingleTingle.final_question')"
                v-model="data.final"
                :class="
                    data.final.trim().length <=
                    $store.state.globalVariables.mingleTingle.minQuestionLength
                        ? 'border-danger-100'
                        : 'border-success-100'
                "
            />
            <hr />
        </div>
    </div>
</template>

<script>
import { axiosToken } from "@/libs/http";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { SmartTagz } from "smart-tagz";
import "smart-tagz/dist/smart-tagz.css";

export default defineComponent({
    components: {
        SmartTagz
    },

    props: {
        data: Object,
        id_content: Number
    },

    setup(props) {
        const dataContent = ref(props.data);
        console.log(dataContent);
        const logResult = (result, e) => {
            dataContent.value.questions = result;
        };

        const tag = ref(["asd"]);
        return {
            tag,
            logResult,
            dataContent
        };
    }
});
</script>

<template>
    <Area
        :loading="!loadingActivities"
        :class="'my-10'"
        :class_loader="'w-1/5'"
    >
        <h2
            class="font-black text-2xl xl:text-3xl text-center xl:text-left my-5"
        >
            {{ $t("activities.title") }}
        </h2>
        <transition name="view" mode="out-in">
            <div
                class="grid grid-cols-3 gap-3 mt-4"
                v-if="activities.length > 0"
            >
                <div
                    v-for="activity in activities"
                    :key="activity.id"
                    class="bg-white rounded-xl border-border-100  flex flex-col"
                >
                    <img
                        class="rounded-t-lg"
                        :src="activity.photo_principal"
                        alt=""
                    />
                    <div class="p-5 flex flex-col justify-between h-full">
                        <h2
                            class="font-black text-xl xl:text-2xl text-center xl:text-left"
                        >
                            {{ activity.name }}
                        </h2>
                        <p class="mb-5">
                            {{ activity.short_description }}
                        </p>
                        <div class=" mt-auto flex flex-row-reverse gap-5">
                            <a
                                class="btn btn-primary-soft w-24"
                                data-toggle="modal"
                                data-target="#booking-modal"
                                v-on:click="setActivity(activity.id)"
                                >{{ $t("activities.btn_book") }}</a
                            >
                            <a
                                data-toggle="modal"
                                data-target="#large-slide-over-size-preview"
                                class="btn btn-primary-soft"
                                v-on:click="setActivity(activity.id)"
                                >{{ $t("activities.btn_details") }}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <img
                    class="m-auto w-2/12 animation-image"
                    src="@/assets/icons/preloader.svg "
                    alt=""
                />
            </div>
        </transition>
        <div
            id="large-slide-over-size-preview"
            class="modal modal-slide-over"
            tabindex="-1"
            aria-hidden="true"
        >
            <div class="w-full modal-dialog modal-xl  " v-if="currentActivity">
                <div class="modal-content">
                    <a data-dismiss="modal" href="javascript:;">
                        <XIcon class="w-8 h-8 text-slate-400" />
                    </a>
                    <div class="modal-header p-5">
                        <h2 class="text-3xl font-bold text-text-100 mr-auto">
                            {{ currentActivity.name }}
                        </h2>
                        <a
                            class="btn btn-primary-soft w-24"
                            data-toggle="modal"
                            data-target="#booking-modal"
                            v-on:click="setActivity(activity.id)"
                            >{{ $t("activities.btn_book") }}</a
                        >
                    </div>
                    <div class="modal-body">
                        <div class="grid grid-cols-4 gap-4 m-4">
                            <img
                                class="rounded-md m-1 row-span-2 col-span-2 h-full object-cover w-full"
                                :src="currentActivity.photo_principal"
                                alt=""
                            />
                            <img
                                class="rounded-md m-1 h-full object-cover w-full"
                                :src="currentActivity.photo_sub_1"
                                alt=""
                            />
                            <img
                                class="rounded-md m-1  h-full object-cover w-full"
                                :src="currentActivity.photo_sub_2"
                                alt=""
                            />
                            <img
                                class="rounded-md m-1 h-full col-span-2 object-cover w-full"
                                :src="currentActivity.photo_sub_3"
                                alt=""
                            />
                        </div>
                        <div class="flex flex-row items-end gap-5">
                            <div class="mr-auto">
                                <h2 class="text-xl font-bold text-text-100">
                                    {{ currentActivity.name }}
                                </h2>
                            </div>
                            <div class="flex flex-col">
                                <ClockIcon
                                    class="block mx-auto text-text-100"
                                />
                                {{ currentActivity.duracion }}
                                {{ $t("activities.details_min") }}
                            </div>
                            <div class="flex flex-col">
                                <UsersIcon
                                    class="block mx-auto text-text-100"
                                />
                                {{ currentActivity.grup_size }}
                                {{ $t("activities.details_pax") }}
                            </div>
                            <div class="flex flex-col">
                                <MessageCircleIcon
                                    class="block mx-auto text-text-100"
                                />
                                {{ currentActivity.idiomas }}
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-5 my-3">
                            <div>
                                <p class="whitespace-pre-wrap text-text-100">
                                    {{ currentActivity.formato }}
                                </p>
                            </div>
                            <div>
                                <h2 class="text-lg  font-bold text-text-100">
                                    {{ $t("activities.details_include") }}:
                                </h2>
                                <p class="whitespace-pre-wrap text-text-100">
                                    {{ currentActivity.incluye }}
                                </p>
                            </div>
                            <div>
                                <h2 class="text-lg  font-bold text-text-100">
                                    {{ $t("activities.details_requirements") }}
                                </h2>
                                <p class="whitespace-pre-wrap text-text-100">
                                    {{ currentActivity.requisitos_preparacion }}
                                </p>
                            </div>
                            <div>
                                <h2 class="text-lg  font-bold text-text-100">
                                    {{
                                        $t(
                                            "activities.details_player_requirements"
                                        )
                                    }}:
                                </h2>
                                <p class="whitespace-pre-wrap text-text-100">
                                    {{
                                        currentActivity.requisitos_participantes
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BookingModal :activity="currentActivity" />
    </Area>
</template>

<script>
import {
    defineComponent,
    onMounted,
    ref,
    reactive,
    watch,
    onUpdated
} from "vue";
import { axiosToken } from "@/libs/http";
import { notify } from "notiwind";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import Btn from "@/global-components/btn/Main.vue";
import BookingModal from "@/components/modals/BookingModal.vue";
import { useI18n } from "vue-i18n";
import Area from "@/global-components/loading/Area.vue";
export default defineComponent({
    components: {
        Btn,
        BookingModal,
        Area
    },
    setup() {
        onMounted(() => {
            fetchActivities();
        });

        const bookingLoading = ref(false);
        const loadingActivities = ref(false);

        const activities = ref([]);
        const { locale } = useI18n();

        const fetchActivities = () => {
            loadingActivities.value = false;
            axiosToken({
                method: "get",
                url: "activities/activities",
                body: {
                    language: locale.value
                }
            }).then(response => {
                console.log(response.data);
                loadingActivities.value = true;
                activities.value = response.data.sort((a, b) => a.id - b.id);
            });
        };

        watch(
            () => locale.value,
            () => fetchActivities()
        );

        const currentActivity = ref(null);
        const setActivity = id => {
            currentActivity.value = activities.value.find(e => e.id == id);
        };

        return {
            activities,
            setActivity,
            currentActivity,
            loadingActivities
        };
    }
});
</script>
<style scoped="scss"></style>

<template>
    <span>
        <div
            class="flex flex-col gap-5 w-full mb-2"
            v-for="(dat, index) in data"
            :key="dat"
        >
            <div class="grid grid-cols-2 gap-2 w-full">
                <div class="flex flex-col gap-2 ">
                    <p>
                        {{ $t("content.quiz.question") }}
                    </p>
                    <input
                        id="regular-form-1"
                        type="text"
                        class="form-control w-full"
                        :placeholder="$t('content.quiz.question')"
                        v-model="dat.question"
                        :class="
                            dat.question.trim().length <=
                            $store.state.globalVariables.quiz.minQuestionLength
                                ? 'border-danger-100'
                                : 'border-success-100'
                        "
                    />
                    <p>
                        {{ $t("content.quiz.correct") }}
                    </p>
                    <input
                        id="regular-form-1"
                        type="text"
                        class="form-control  my-1  w-full"
                        :placeholder="$t('content.quiz.correct')"
                        v-model="dat.correct"
                        :class="
                            dat.correct.trim().length <=
                                $store.state.globalVariables.quiz
                                    .minResponseLength || duplicated[index][0]
                                ? 'border-danger-100'
                                : 'border-success-100'
                        "
                        @change="checkUnique(index)"
                    />
                    <p>
                        {{ $t("content.quiz.incorrect") }}
                    </p>
                    <input
                        id="regular-form-1"
                        type="text"
                        class="form-control  my-1  w-full"
                        :placeholder="$t('content.quiz.incorrect')"
                        v-model="dat.others[0]"
                        :class="
                            dat.others[0].trim().length <=
                                $store.state.globalVariables.quiz
                                    .minResponseLength || duplicated[index][1]
                                ? 'border-danger-100'
                                : 'border-success-100'
                        "
                        @change="checkUnique(index)"
                    />
                    <input
                        id="regular-form-1"
                        type="text"
                        class="form-control  my-1  w-full"
                        :placeholder="$t('content.quiz.incorrect')"
                        v-model="dat.others[1]"
                        :class="
                            dat.others[1].trim().length <=
                                $store.state.globalVariables.quiz
                                    .minResponseLength || duplicated[index][2]
                                ? 'border-danger-100'
                                : 'border-success-100'
                        "
                        @change="checkUnique(index)"
                    />
                    <input
                        id="regular-form-1"
                        type="text"
                        class="form-control w-full my-1"
                        :placeholder="$t('content.quiz.incorrect')"
                        v-model="dat.others[2]"
                        :class="
                            dat.others[2].trim().length <=
                                $store.state.globalVariables.quiz
                                    .minResponseLength || duplicated[index][3]
                                ? 'border-danger-100'
                                : 'border-success-100'
                        "
                        @change="checkUnique(index)"
                    />
                </div>
                <div class="h-full flex flex-col justify-start">
                    <p class="mb-2">
                        {{ $t("content.quiz.photo") }}
                    </p>
                    <div class="grid grid-cols-2 gap-1">
                        <img
                            :key="dat.image"
                            v-if="returnImg(dat.id)"
                            :src="returnImg(dat.id)"
                            alt=""
                            class="w-full h-48"
                        />
                        <img
                            v-else
                            :key="dat.image"
                            class="w-full h-48 object-cover aspect-square"
                            :src="require('@/assets/images/default.png')"
                            alt=""
                        />
                        <Dropzone
                            :options="{
                                acceptedFiles: '.jpeg,.jpg,.png,.gif,.svg',
                                url: urlUpload,
                                thumbnailWidth: 150,
                                maxFilesize: 50,
                                maxFiles: 1,
                                headers: {
                                    'Cache-Control': '',
                                    Authorization: Token
                                }
                            }"
                            id="uploadImage"
                            class="dropzone aspect-square h-48 w-full"
                            :check_class="true"
                            :uploaded="returnImg(dat.id) != ''"
                            :success="x => uploadImage(dat.id, x)"
                        >
                            <input
                                name="id_image"
                                type="hidden"
                                v-model="dat.id"
                            />
                            <input
                                name="id_content"
                                type="hidden"
                                v-model="id_content"
                            />
                            <div class="text-lg font-medium text-text-100">
                                {{
                                    $t("content.sellerSponge.placeholder_image")
                                }}
                            </div>
                        </Dropzone>
                    </div>
                    <div
                        class="flex flex-row items-center gap-2 justify-end mt-auto"
                    >
                        <p>
                            {{ $t("content.quiz.eliminar") }}
                        </p>
                        <button
                            type="button"
                            v-on:click="removedataContent(index)"
                            class="btn btn-primary-outline  w-16 h-10"
                        >
                            <inline-svg
                                :src="require('@/assets/icons/trash-solid.svg')"
                                alt=""
                                class="h-full"
                            />
                        </button>
                    </div>
                </div>
            </div>
            <hr class="w-full" />
        </div>

        <div class="flex flex-row my-5 items-center gap-3">
            <button
                type="button"
                v-on:click="addNewData()"
                class="btn btn-primary w-16 my-5 h-10"
            >
                <inline-svg
                    :src="require('@/assets/icons/plus-solid.svg')"
                    alt=""
                    class="h-full"
                    style="fill:white;"
                />
            </button>
            <p
                v-if="dataContent"
                class="text-lg  "
                :class="
                    dataContent.length <
                    $store.state.globalVariables.quiz.minContent
                        ? 'text-danger-100'
                        : 'text-success-120'
                "
            >
                {{ dataContent.length }}/{{
                    $store.state.globalVariables.quiz.maxContent
                }}
            </p>
        </div>
    </span>
</template>

<script>
import { axiosToken } from "@/libs/http";
import { defineComponent, onBeforeMount, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { makeid } from "@/libs/libs";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { checkDuplicates } from "@/libs/libs.js";

export default defineComponent({
    props: {
        data: Object,
        all_data: Object,
        id_content: Number
    },

    setup(props) {
        const Token = "Token " + localStorage.user;
        const urlUpload =
            process.env.VUE_APP_API_URL + "content/subcategoryImage";
        const { t } = useI18n();
        const store = useStore();
        const dataContent = ref(props.data);

        watch(
            () => props.data,
            () => {
                dataContent.value = props.data;
            }
        );

        let duplicated = ref([]);
        onBeforeMount(() => {
            if (!dataContent.value) {
                return;
            }
            dataContent.value.forEach(element => {
                duplicated.value.push(
                    checkDuplicates([
                        element.correct,
                        element.others[0],
                        element.others[1],
                        element.others[2]
                    ])
                );
            });
            dataContent.value.map(content => {
                content.image = "";
            });
            console.log("iinn");
        });

        const checkUnique = index => {
            let element = dataContent.value[index];
            duplicated.value[index] = checkDuplicates([
                element.correct,
                element.others[0],
                element.others[1],
                element.others[2]
            ]);
        };

        const logResult = (result, e) => {
            dataContent.value[e].others = result;
        };

        const addNewData = () => {
            let dataLength = 0;
            if (dataContent.value) {
                dataLength = dataContent.value.length;
            }
            if (dataLength >= store.state.globalVariables.quiz.maxContent) {
                notify(
                    {
                        group: "error",
                        title: t("general.error"),
                        text: t("content.alert_limit_content")
                    },
                    2000
                );
                return;
            }

            dataContent.value.push({
                question: "",
                correct: "",
                others: ["", "", ""],
                id: makeid(16)
            });
        };

        const removedataContent = index => {
            dataContent.value.splice(index, 1);
        };
        const returnImg = id => {
            if (dataContent.value.find(e => e.id == id).image) {
                return dataContent.value.find(e => e.id == id).image;
            }
            for (let x = 0; x < props.all_data.images.length; x++) {
                if (props.all_data.images[x].name == id) {
                    return props.all_data.images[x].img;
                }
            }
            return "";
        };
        const uploadImage = (id, response) => {
            dataContent.value.find(e => e.id == id).image = response.url;
        };

        const tag = ref(["asd"]);
        return {
            tag,
            logResult,
            dataContent,
            addNewData,
            removedataContent,
            returnImg,
            uploadImage,
            urlUpload,
            Token,
            checkUnique,
            duplicated
        };
    }
});
</script>

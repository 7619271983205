<template>

    <div
        class="flex flex-col items-center justify-center h-screen"
        v-if="!loadingScreen"
    >
        <img
            class="w-1/12 animation-image"
            src="@/assets/icons/preloader.svg "
            alt=""
        >
    </div>
    <div v-else>
        <h2 class=" text-lg font-medium mt-10">Bonfire Games</h2>
        <div class="flex flex-row w-full gap-2">
            <div class="bg-white p-2 rounded-lg">
                <p>
                    Total Bonfires {{bonfireGames.count}}
                </p>
            </div>
            <div class="bg-white p-2 rounded-lg">
                <p>
                    Total Games {{macroData.games}}
                </p>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-6 mt-5">
            <!-- BEGIN: Data List -->
            <div class="col-span-12 overflow-auto lg:overflow-visible">
                <div v-if="!loaderTable">
                    <img
                        class="w-1/12 animation-image"
                        src="@/assets/icons/preloader.svg "
                        alt=""
                    >
                </div>
                <table
                    class="table table-report -mt-2"
                    v-else
                >
                    <thead>
                        <tr>
                            <th class="whitespace-nowrap">Organitzation</th>
                            <th class="whitespace-nowrap">Start</th>
                            <th class="text-center whitespace-nowrap">Duration</th>
                            <th class="text-center whitespace-nowrap">Players</th>
                            <th class="text-center whitespace-nowrap">Details</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(game, key) in bonfireGames.results"
                            :key="key"
                            class="intro-x"
                        >
                            <td class="w-40">
                                <span v-if="game.organitzation">{{game.organitzation.name}}</span>
                                <span v-else>None</span>
                            </td>
                            <td>
                                {{$filters.formatDate(game.start)}}
                            </td>
                            <td class="text-center">
                                {{((game.duration/60)).toFixed(2)}} minutes
                            </td>
                            <td class="w-40 text-center">
                                {{game.players.players.length}}
                            </td>
                            <td class="text-center mx-auto">
                                <a
                                    class="flex items-center cursor-pointer mx-auto "
                                    data-toggle="modal"
                                    data-target="#large-slide-over-size-preview"
                                    v-on:click="fetchDetails(game.id)"
                                >
                                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                                    Detalles
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- END: Data List -->
            <!-- BEGIN: Pagination -->
            <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center gap-2">
                <paginate
                    :page-count="totalPages"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="(page)=>{newPage(page)}"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                >
                </paginate>
            </div>
            <!-- END: Pagination -->
        </div>
        <!-- BEGIN: Delete Confirmation Modal -->

        <div
            id="large-slide-over-size-preview"
            class="modal modal-slide-over"
            tabindex="-1"
            aria-hidden="true"
        >
            <div class="w-full modal-dialog modal-xl  ">
                <div
                    class="modal-content"
                    v-if="detailsData"
                >
                    <a
                        data-dismiss="modal"
                        href="javascript:;"
                    >
                        <XIcon class="w-8 h-8 text-slate-400" />
                    </a>
                    <div class="modal-header p-5">
                        <h2 class="text-3xl text-bold text-text-100 mr-auto">Games</h2>
                    </div>
                    <div v-if="loaderDetails" class="flex flex-col h-screen items-center justify-center">
                        <img
                            class="w-1/12 animation-image"
                            src="@/assets/icons/preloader.svg "
                            alt=""
                        >
                    </div>
                    <div
                        class="modal-body"
                        v-else-if="detailsData.games"
                    >
                        <table
                            class="table table-report -mt-2"
                            v-if="detailsData.games.length > 1"
                        >
                            <thead>
                                <tr>
                                    <th class="whitespace-nowrap">Game</th>
                                    <th class="whitespace-nowrap">Content</th>
                                    <th class="text-center whitespace-nowrap">SubContent</th>
                                    <th class="text-center whitespace-nowrap">Duration</th>
                                    <th class="text-center whitespace-nowrap">Players</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(game, key) in detailsData.games"
                                    :key="key"
                                    class="intro-x"
                                >
                                    <td class="w-40">
                                        <span v-if="game.content">{{game.content.name_id}}</span>
                                        <span v-else>None</span>
                                    </td>
                                    <td>
                                        <span v-if="game.content">{{game.content.name}}</span>
                                        <span v-else>None</span>
                                    </td>
                                    <td class="text-center">
                                        <span v-if="game.sub_content">{{game.sub_content.sub_name}}</span>
                                        <span v-else>None</span>
                                    </td>
                                    <td class="w-40 text-center">
                                        {{((game.duration/60)).toFixed(2)}} minutes
                                    </td>
                                    <td class="text-center mx-auto">
                                        {{game.players.length}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h4 v-else class="text-center">
                            No games
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
</template>

<script>
import { axiosToken } from "@/libs/http";
import { defineComponent, onMounted, ref } from "vue";
import Paginate from "vuejs-paginate-next";

export default defineComponent({
    components: {
        Paginate,
    },

    setup() {
        const bonfireGames = ref([]);
        const totalPages = ref(0);
        const macroData = ref({});
        const loadingScreen = ref(false);
        const fetchGames = () => {
            axiosToken({ url: "bonfire/usage", method: "get" }).then(
                (response) => {
                    bonfireGames.value = response.data;
                    totalPages.value = parseInt(response.data.count / 25) + 1;
                    console.log("in1");
                    allLoad();
                }
            );
        };

        const fetchGeneralData = () => {
            axiosToken({ url: "bonfire/usage_macro", method: "get" }).then(
                (response) => {
                    macroData.value = response.data;
                    console.log("in2");
                    allLoad();
                }
            );
        };
        let loaded = 0;
        const allLoad = () => {
            loaded++;
            if (loaded >= 2) {
                console.log("allLoaded");
                loadingScreen.value = true;
            }
        };

        onMounted(() => {
            fetchGames();
            fetchGeneralData();
        });
        const loaderTable = ref(true);
        const newPage = (page) => {
            const pageTotal = 25 * (page - 1);
            loaderTable.value = false;
            axiosToken({
                url: "bonfire/usage?limit=25&offset=" + String(pageTotal),
                method: "get",
            }).then((response) => {
                bonfireGames.value = response.data;
                totalPages.value = parseInt(response.data.count / 25) + 1;
                loaderTable.value = true;
            });
        };
        const detailsData = ref({});
        const loaderDetails = ref(false);
        const fetchDetails = (id) => {
            loaderDetails.value = true;
            axiosToken({
                url: "bonfire/usage/" + String(id),
                method: "get",
            }).then((response) => {
                detailsData.value = response.data;
                loaderDetails.value = false;
            });
        };

        return {
            bonfireGames,
            totalPages,
            loadingScreen,
            macroData,
            newPage,
            loaderTable,
            detailsData,
            fetchDetails,
            loaderDetails,
        };
    },
});
</script>

<style lang="scss">
/* Adopt bootstrap pagination stylesheet. */

/* Write your own CSS for pagination */
.pagination {
    @apply m-0 p-0;
    .page-item {
        @apply border-text-60 border p-1 cursor-pointer;
    }
    .disabled {
        @apply cursor-not-allowed;
    }
}
.page-item.active {
    @apply bg-primary-1;
}
</style>
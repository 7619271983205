<template>
    <form
        ref="fileUploadRef"
        v-file-upload="{ props, emit }"
        :class="
            check_class
                ? !uploaded
                    ? 'dropzone-empty'
                    : 'dropzone-full'
                : 'dropzone'
        "
    >
        <div class="dz-message">
            <slot></slot>
        </div>
    </form>
</template>

<script>
import { defineComponent, inject, ref, onMounted } from "vue";
import { init } from "./index";

export default defineComponent({
    directives: {
        fileUpload: {
            mounted(el, { value }) {
                init(el, value.props);
            }
        }
    },
    props: {
        options: {
            type: Object,
            default: () => ({})
        },
        refKey: {
            type: String,
            default: null
        },
        success: {
            type: Function,
            default: null
        },
        check_class: {
            type: Boolean,
            default: false
        },
        uploaded: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const fileUploadRef = ref();
        const bindInstance = () => {
            if (props.refKey) {
                const bind = inject(`bind[${props.refKey}]`);
                if (bind) {
                    bind(fileUploadRef.value);
                }
            }
        };

        onMounted(() => {
            bindInstance();
        });

        return {
            props,
            ...context,
            fileUploadRef
        };
    }
});
</script>

<template>
  <Layout>
    <div class="block xl:grid grid-cols-2 gap-4 bg-theme-1">
      <!-- BEGIN: Register Info -->
      <div class="hidden xl:flex flex-col min-h-screen  items-center justify-center">
          <img
              :src="require(`@/assets/images/extra.svg`)"
              alt=""
              class="w-2/4 animation-image"
          >
      </div>

      <!-- END: Register Info -->
      <!-- BEGIN: Register Form -->

      <div class="h-screen xl:h-auto flex-col flex py-5 xl:py-0 xl:my-0 xl:bg-white justify-center">
        <div class="error-message">Error</div>
        <div class="error-message">404</div>
        <div class="flex justify-center w-full mt-6">
          <Btn 
              type="button"
              class="btn btn-primary w-6/12"
              @click="goHome"
          >
            {{$t('general.back')}}
          </Btn>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import Btn from "@/global-components/btn/Main.vue";
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Btn
  },
  setup () {
    const router = useRouter();

    const goHome = () => {
      router.push('/');
    }

    return {
      goHome
    };
  }
})
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.animation-image {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.background-input {
    background: rgba(225, 231, 240, 0.5);
}

.error-message {
  color: rgba(254, 55, 50, var(--tw-bg-opacity));
  font-size: 10em;
  font-weight: bold;
  font-family: Helvetica;
  text-align: center;
}

</style>

<template>
    <div>
        <div class="">
            <div class="block xl:grid grid-cols-2 gap-4 bg-theme-1">
                <!-- BEGIN: Register Info -->
                <div class="hidden xl:flex flex-col min-h-screen  items-center justify-center">
                    <img
                        :src="require(`@/assets/images/extra.svg`)"
                        alt=""
                        class="w-2/4 animation-image"
                    >
                </div>

                <!-- END: Register Info -->
                <!-- BEGIN: Register Form -->

                <div class="h-screen xl:h-auto flex-col flex py-5 xl:py-0 xl:my-0 xl:bg-white">

                    <div class="flex flex-row ml-auto">
                        <select
                            name=""
                            id=""
                            class="py-3 px-4 m-3 rounded-md block background-input w-full"
                            v-model="$i18n.locale"
                        >
                            <option
                                v-for="(lang, key) in $store.state.main.languages"
                                :key="key"
                                :value="key"
                            >

                                {{ lang }}
                            </option>
                        </select>
                    </div>
                    <div
                        v-if="emailValidateFail"
                        class="my-auto mx-auto xl:ml-20 bg-white dark:bg-white-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
                    >
                        <h2 class=" intro-x font-bold text-3xl text-center mb-5">
                           {{$t('email_validate.title_error')}}
                        </h2>
                        <div class="flex flex-col gap-5 justify-center items-center">
                            <p>
                                {{$t('email_validate.text_send_again')}}
                            </p>
                            <input
                                v-model="v2$.form.email.$model"
                                type="text"
                                class="py-3 px-4  rounded-md block background-input w-full mt-4
                                "
                                :placeholder="$t('register.placeholder_email')"
                            />
                            <Btn
                                :disabled="v2$.form.$invalid "
                                class=" btn btn-primary py-3 px-4 "
                                :isLoading="newEmailLoading"
                                v-on:click="newEmail()"
                            >
                                {{$t('email_validate.btn_re_send')}}
                            </Btn>

                        </div>
                    </div>
                    <div
                        v-if="emailValidate"
                        class="my-auto mx-auto xl:ml-20 bg-white dark:bg-white-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
                    >
                    <div class="flex flex-col items-center">
                        <h2 class=" intro-x font-bold text-3xl text-center mb-5">
                            {{$t('email_validate.text')}}
                        </h2>
                        <a class=" btn btn-primary py-3 px-4 mx-auto" href="/login">
                           {{$t('email_validate.btn_log_in')}}
                        </a>

                    </div>

                    </div>
                </div>
            </div>
            <!-- END: Register Form -->
        </div>
  
    </div>
</template>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
    computed,
    getCurrentInstance,
    ref,
} from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import { useStore } from "vuex";
import Btn from "@/global-components/btn/Main.vue";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";
import axios from "axios";
import { useRoute } from "vue-router";

export default defineComponent({
    components: {
        Btn,
    },
    setup(data) {
        const { t } = useI18n();
        const newEmailLoading = ref(false);
        const stateEarly = reactive({
            form: {
                email: "",
            },
        });
        const rulesEarly = computed(() => ({
            form: {
                email: { required, email },
            },
        }));
        const newEmail = () => {
            newEmailLoading.value = true;
            axios
                .post(
                    process.env.VUE_APP_API_URL +
                        "api/registration/resend-email/",
                    { email: stateEarly.form.email }
                )
                .then((e) => {
                    newEmailLoading.value = false;
                    notify(
                        {
                            group: "top",
                            title: "Success",
                            text:t('register_activate.alert_new_email')
                        },
                        4000
                    );
                    stateEarly.form.email = "";
                });
        };

        const route = useRoute();
        const key = route.query.key;
        const emailValidate = ref(false);
        const emailValidateFail = ref(false);
        onMounted(() => {
            axios
                .post(
                    process.env.VUE_APP_API_URL +
                        "api/registration/account-confirm-email/",
                    { key: key }
                )
                .then((e) => {
                    emailValidate.value = true;
                   
                })
                .catch((e) => {
                    emailValidateFail.value = true
                });
        });
        const v2$ = useVuelidate(rulesEarly, stateEarly);
        return {
            v2$,
            emailValidate,
            emailValidateFail,
            newEmail,
            newEmailLoading,
        };
    },
});
</script>
<style lang="scss" scoped>
.animation-image {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.background-input {
    background: rgba(225, 231, 240, 0.5);
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}
</style>
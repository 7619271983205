<template>
    <Screen :loading="loading">
        <div class="flex flex-row gap-3 mt-3">
            <h2
                class="font-black text-2xl xl:text-3xl text-center xl:text-left"
            >
                {{ bonfireDetails.name }}
            </h2>
            <div
                class="flex flex-row ml-auto gap-10 items-center justify-center"
            >
                <p class="text-text-100 text-lg font-bold">
                    {{ $t("content.content_of") }}
                    <span v-if="categoryContent.user"
                        >{{ categoryContent.user.name }}
                    </span>
                </p>
                <!-- <ContentLanguage/> -->
                <router-link
                    v-if="!admin"
                    :disabled="loadingNew"
                    type="button"
                    :to="'/content/' + route.params.id"
                    class="btn btn-primary-outline w-32 ml-auto h-10"
                    >{{ $t("general.back") }}
                </router-link>
                <router-link
                    v-else
                    :disabled="loadingNew"
                    type="button"
                    :to="'/admin-content/' + route.params.id"
                    class="btn btn-primary-outline w-32 ml-auto h-10"
                    >{{ $t("general.back") }}
                </router-link>
            </div>
        </div>
        <div class="flex flex-col mb-5">
            <h4 class="text-xl font-bold leading-8 my-1">
                {{ $t("content.subcontent_title") }}
            </h4>
            <p
                v-if="bonfireDetails.name_id.name_id == 'factMatching'"
                class="text-xl"
                v-html="
                    $t('content.subcontent_text_factMatching', {
                        minContent:
                            $store.state.globalVariables.factMatching
                                .minContent,
                        maxContent:
                            $store.state.globalVariables.factMatching.maxContent
                    })
                "
            />
            <p
                v-else-if="bonfireDetails.name_id.name_id == 'topo'"
                class="text-xl"
                v-html="
                    $t('content.subcontent_text_topo', {
                        minContent: $store.state.globalVariables.topo.minContent
                    })
                "
            />
            <p
                v-else-if="bonfireDetails.name_id.name_id == 'sellerSponge'"
                class="text-xl"
                v-html="
                    $t('content.subcontent_text_sellerSponge', {
                        minContent:
                            $store.state.globalVariables.sellerSponge.minContent
                    })
                "
            />
            <p
                v-else-if="bonfireDetails.name_id.name_id == 'quiz'"
                class="text-xl"
                v-html="
                    $t('content.subcontent_text_quiz', {
                        minContent:
                            $store.state.globalVariables.quiz.minContent,
                        maxContent: $store.state.globalVariables.quiz.maxContent
                    })
                "
            />
            <p
                v-else-if="bonfireDetails.name_id.name_id == 'pictionary'"
                class="text-xl"
                v-html="
                    $t('content.subcontent_text_pictionary', {
                        minContent:
                            $store.state.globalVariables.pictionary.minContent,
                        maxContent:
                            $store.state.globalVariables.pictionary.maxContent
                    })
                "
            />
            <p
                v-else-if="bonfireDetails.name_id.name_id == 'mingleTingle'"
                class="text-xl"
                v-html="
                    $t('content.subcontent_text_mingleTingle', {
                        minContent:
                            $store.state.globalVariables.mingleTingle.minContent
                    })
                "
            />
        </div>
        <div class="col-span-12 lg:col-span-6">
            <div class="intro-y box">
                <div
                    class="gap-5 flex flex-col sm:flex-row items-center p-5 flex-wrap"
                >
                    <!-- not admin -->
                    <label class="ml-0 sm:ml-2 text-lg" for="regular-form-1">
                        {{ $t("content.label_name_category") }}:
                    </label>
                    <h2
                        class="font-medium text-base mr-auto flex flex-col gap-1 items-center"
                    >
                        <div
                            class="gap-5 flex flex-row sm:flex-col items-center"
                        >
                            <input
                                id="regular-form-1"
                                type="text"
                                class="form-control h-10 w-48"
                                :placeholder="
                                    $t('content.placeholder_name_category')
                                "
                                v-model="categoryContent.name"
                            />
                        </div>
                    </h2>
                    <h2
                        class="font-medium text-base mr-auto flex flex-row gap-1 items-center"
                        v-if="admin"
                    >
                        ES:
                        <input
                            id="regular-form-2"
                            type="text"
                            class="form-control h-10 w-48"
                            :placeholder="
                                $t('content.placeholder_name_category')
                            "
                            v-model="categoryContent.name_es"
                        />
                    </h2>
                    <h2
                        class="font-medium text-base mr-auto flex flex-row gap-1 items-center"
                        v-if="admin"
                    >
                        EN:
                        <input
                            id="regular-form-3"
                            type="text"
                            class="form-control h-10 w-48"
                            :placeholder="
                                $t('content.placeholder_name_category')
                            "
                            v-model="categoryContent.name_en"
                        />
                    </h2>
                    <Btn
                        :disabled="loadingSave"
                        type="button"
                        v-on:click="saveBtn(true)"
                        class="text-lg btn btn-primary-soft h-10 lg:justify-center sm:justify-end"
                        :isLoading="loadingSave"
                    >
                        <p class="mr-3 whitespace-nowrap">
                            {{ $t("content.btn_save_all") }}
                        </p>
                        <inline-svg
                            :src="
                                require('@/assets/icons/floppy-disk-solid.svg')
                            "
                            alt=""
                            class="h-full"
                            style="fill:white"
                        />
                    </Btn>
                </div>
                <div
                    class="gap-5 flex flex-col sm:flex-row items-center  justify-end pb-5 pl-5 pr-5 border-b border-gray-200 dark:border-dark-5"
                >
                    <label class="ml-0 sm:ml-2 text-lg" for="regular-form-2">
                        {{ $t("content.label_name_set") }}:
                    </label>
                    <div>
                        <input
                            id="regular-form-2"
                            type="text"
                            class="form-control text-text-100 w-48"
                            :placeholder="$t('content.placeholder_name_set')"
                            v-model="nameContent"
                        />
                    </div>
                    <Btn
                        :disabled="loadingNew || nameContent.length == 0"
                        type="button"
                        v-on:click="newOne()"
                        class="text-lg btn btn-primary-soft h-10 lg:justify-center sm:justify-end"
                        :isLoading="loadingNew"
                    >
                        <p class="mr-3 whitespace-nowrap">
                            {{ $t("content.btn_new_theme") }}
                        </p>
                        <inline-svg
                            :src="require('@/assets/icons/plus-solid.svg')"
                            alt=""
                            class="h-full"
                            style="fill:white"
                        />
                    </Btn>
                </div>
                <div id="boxed-accordion" class="p-5">
                    <div>
                        <div
                            id="faq-accordion-2"
                            class="accordion accordion-boxed"
                        >
                            <div
                                class="accordion-item"
                                v-for="content in gameContent"
                                :key="content"
                            >
                                <div
                                    :id="'faq-accordion-content-' + content.id"
                                    class="accordion-header flex flex-row items-center gap-3"
                                >
                                    <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        :data-bs-target="
                                            '#faq-accordion-collapse-' +
                                                content.id
                                        "
                                        aria-expanded="true"
                                        :aria-controls="
                                            'faq-accordion-collapse-' +
                                                content.id
                                        "
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-chevron-down block mx-auto"
                                        >
                                            <polyline
                                                points="6 9 12 15 18 9"
                                            ></polyline>
                                        </svg>
                                    </button>
                                    <div class="flex flex-col float-left mr-2">
                                        <p
                                            class="text-xl inline-block whitespace-nowrap"
                                            :class="
                                                content.validated_content
                                                    ? 'text-success-100'
                                                    : 'text-danger-100'
                                            "
                                        >
                                            {{
                                                content.validated_content
                                                    ? $t("content.validated")
                                                    : $t(
                                                          "content.not_validated"
                                                      )
                                            }}
                                        </p>
                                    </div>
                                    <select
                                        name=""
                                        id=""
                                        class="py-3 px-4 m-3 rounded-md block background-input w-24"
                                        v-if="admin"
                                        v-model="content.idioma"
                                    >
                                        <option value="es">
                                            Es
                                        </option>
                                        <option value="en">
                                            En
                                        </option>
                                    </select>
                                    <input
                                        id="regular-form-1"
                                        type="text"
                                        class="form-control w-2/12 "
                                        placeholder="Name "
                                        v-model="content.sub_name"
                                    />
                                    <button
                                        class="btn btn-primary-soft h-10 w-16"
                                        v-on:click="deleteBTn(content.id)"
                                    >
                                        <inline-svg
                                            :src="
                                                require('@/assets/icons/trash-solid.svg')
                                            "
                                            alt=""
                                            class="h-full"
                                            style="fill:white"
                                        />
                                    </button>
                                </div>
                                <div
                                    :id="'faq-accordion-collapse-' + content.id"
                                    class="accordion-collapse collapse show"
                                    :aria-labelledby="
                                        'faq-accordion-content-' + content.id
                                    "
                                    data-bs-parent="#faq-accordion-2"
                                >
                                    <div
                                        class="accordion-body text-text-100  leading-relaxed"
                                    >
                                        <PictonaryContent
                                            v-if="
                                                route.params.id == 'pictionary'
                                            "
                                            :data="content.data"
                                        />
                                        <TopoContent
                                            v-else-if="
                                                route.params.id == 'topo'
                                            "
                                            :data="content.data"
                                            :all_data="content"
                                        />
                                        <MingleTingleContent
                                            v-else-if="
                                                route.params.id ==
                                                    'mingleTingle'
                                            "
                                            :data="content.data"
                                            :id_content="content.id"
                                        />
                                        <FactMatchingContent
                                            v-else-if="
                                                route.params.id ==
                                                    'factMatching'
                                            "
                                            :data="content.data"
                                        />
                                        <SellerSpongeContent
                                            v-else-if="
                                                route.params.id ==
                                                    'sellerSponge'
                                            "
                                            :data="content.data"
                                            :id_content="content.id"
                                            :all_data="content"
                                        />
                                        <QuizContent
                                            v-else-if="
                                                route.params.id == 'quiz'
                                            "
                                            :data="content.data"
                                            :id_content="content.id"
                                            :all_data="content"
                                        />
                                        <ThisOrThatContent
                                            v-else-if="
                                                route.params.id == 'thisOrThat'
                                            "
                                            :data="content.data"
                                            :id_content="content.id"
                                            :all_data="content"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Screen>
</template>

<script>
import { axiosToken } from "@/libs/http";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import PictonaryContent from "./PicitonaryContent.vue";
import TopoContent from "./TopoContent";
import MingleTingleContent from "./MingleTingleContent.vue";
import FactMatchingContent from "./FactMatchingContent.vue";
import SellerSpongeContent from "./SellerSpongeContent.vue";
import ThisOrThatContent from "./ThisOrThatContent.vue";
import QuizContent from "./QuizContent.vue";
import Screen from "@/global-components/loading/Screen";

import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Btn from "@/global-components/btn/Main";
import { notify } from "notiwind";

export default defineComponent({
    components: {
        PictonaryContent,
        TopoContent,
        MingleTingleContent,
        FactMatchingContent,
        SellerSpongeContent,
        QuizContent,
        Screen,
        Btn,
        ThisOrThatContent
    },

    setup() {
        const route = useRoute();
        const router = useRouter();
        const gameContent = ref([]);
        const selectedGame = ref(null);
        const nameContent = ref("");
        const loadingSave = ref(false);
        const loadingNew = ref(false);
        const categoryContent = ref([]);
        const nameContentGeneral = ref(route.query.name);
        const store = useStore();
        const dataFetch = ref(false);
        const bonfireDetails = ref({});
        const { locale, t } = useI18n();
        const admin = ref(false);
        const setAdmin = () => {
            if (window.location.pathname.split("/")[1] == "admin-content") {
                admin.value = true;
            }
        };

        const language_settings = {
            title: null
        };
        const selectGame = game_name => {
            selectedGame.value = game_name;
        };

        const checkValidated = data => {
            gameContent.value.find(e => e.id == data.id).validated_content =
                data.validated_content;
        };

        const newOne = async () => {
            if (nameContent.value == "") {
                return;
            }
            loadingNew.value = true;
            await saveBtn(false);
            let data = [];
            if (route.params.id == "mingleTingle") {
                data = { questions: ["", "", ""], final: "" };
            }

            axiosToken({
                url: "content/subcategory",
                method: "post",
                body: {
                    sub_name: nameContent.value,
                    id: route.params.idSub,
                    data,
                    idioma: "es" //default
                }
            }).then(response => {
                nameContent.value = "";
                fetchData();
                loadingNew.value = false;
                notify(
                    {
                        group: "top",
                        title: t("general.success"),
                        text: t("content.alert_add_content_success")
                    },
                    2000
                );
            });
        };

        const saveBtn = async directCall => {
            loadingSave.value = true;
            let bodyCategory = {
                name_es: categoryContent.value.name_es,
                name_en: categoryContent.value.name_en,
                name: categoryContent.value.name
            };
            axiosToken({
                url: "content/category/" + route.params.idSub,
                method: "put",
                body: bodyCategory
            });

            if (gameContent.value.length == 0) {
                loadingSave.value = false;
            }
            let promise1 = new Promise(function(resolve, reject) {
                gameContent.value.map(content => {
                    axiosToken({
                        url: "content/subcategory/" + content.id,
                        method: "put",
                        body: {
                            sub_name: content.sub_name,
                            data: content.data,
                            idioma: content.idioma
                        }
                    }).then(response => {
                        checkValidated(response.data);
                        resolve("in map");
                        return true;
                    });
                });
            });
            promise1
                .then(function(value) {
                    if (directCall) {
                        notify(
                            {
                                group: "top",
                                title: t("general.success"),
                                text: t("content.alert_save_success")
                            },
                            2000
                        );
                    }
                    console.log(value);
                    loadingSave.value = false;
                })
                .catch(e => {
                    notify(
                        {
                            group: "error",
                            title: t("general.error"),
                            text: t("content.alert_save_error")
                        },
                        2000
                    );
                });
        };

        const deleteBTn = async id => {
            await saveBtn(false);
            axiosToken({
                url: "content/subcategory/" + id,
                method: "delete"
            })
                .then(response => {
                    fetchData();
                    notify(
                        {
                            group: "top",
                            title: t("general.success"),
                            text: t("content.alert_delete_question_success")
                        },
                        2000
                    );
                })
                .catch(e => {
                    notify(
                        {
                            group: "error",
                            title: t("general.error"),
                            text: t("content.alert_delete_question_error")
                        },
                        2000
                    );
                });
        };
        const loading = ref(true);
        const loadingScreen = () => {
            loadingCounter++;
            if (loadingCounter >= 3) {
                loading.value = false;
            }
        };

        onMounted(() => {
            setAdmin();
            fetchData();
        });

        let loadingCounter = 0;
        let firstTime = true;
        const fetchData = () => {
            axiosToken({
                url: "content/subcategory?id=" + route.params.idSub,
                method: "get",
                body: {
                    all: true
                }
            }).then(response => {
                console.log("content/subcategory?id=" + route.params.idSub);
                console.log(response.data);
                gameContent.value = response.data;
                dataFetch.value = true;
                loadingScreen();
            });
            axiosToken({
                url: "content/category/" + route.params.idSub,
                method: "get"
            }).then(response => {
                console.log("content/category/" + route.params.idSub);
                console.log(response.data);
                categoryContent.value = response.data;
                loadingScreen();
            });
            if (firstTime) {
                firstTime = false;
                axiosToken({
                    url: "activities/bonfire/" + route.params.id,
                    method: "get",
                    body: {
                        language: locale.value
                    }
                })
                    .then(response => {
                        console.log("activities/bonfire/" + route.params.id);
                        console.log(response.data);
                        loadingScreen();
                        bonfireDetails.value = response.data;
                    })
                    .catch(e => {
                        router.push("/content");
                    });
            }
        };
        return {
            gameContent,
            selectGame,
            selectedGame,
            route,
            nameContent,
            newOne,
            saveBtn,
            loadingSave,
            loadingNew,
            deleteBTn,
            nameContentGeneral,
            language_settings,
            categoryContent,
            dataFetch,
            bonfireDetails,
            loading,
            admin
        };
    }
});
</script>

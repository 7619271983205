<template>
    <span>
        <div class="grid grid-cols-2 gap-2 w-full">
            <div
                class="flex flex-col gap-1"
                v-for="(dat, index) in data"
                :key="dat"
            >
                <div
                    class="flex flex-row items-center justify-center w-full gap-1"
                >
                    <input
                        id="regular-form-1"
                        type="text"
                        class="form-control w-full border-2"
                        :placeholder="
                            $t('content.sellerSponge.placeholder_question')
                        "
                        v-model="dat.question"
                        :class="
                            dat.question.trim().length <= 5
                                ? 'border-danger-100'
                                : 'border-success-100'
                        "
                    />
                    <button
                        type="button"
                        v-on:click="removedataContent(index)"
                        class="btn btn-primary-outline  w-16 h-10"
                    >
                        <inline-svg
                            :src="require('@/assets/icons/trash-solid.svg')"
                            alt=""
                            class="h-full"
                        />
                    </button>
                </div>
                <div class="grid grid-cols-2 gap-1">
                    <img
                        :key="dat.image"
                        v-if="returnImg(dat.id)"
                        :src="returnImg(dat.id)"
                        alt=""
                        class="w-full h-48"
                    />

                    <img
                        v-else
                        class="w-full h-48 object-cover aspect-square"
                        :src="require('@/assets/images/default.png')"
                        alt=""
                    />
                    <Dropzone
                        :options="{
                            acceptedFiles: '.jpeg,.jpg,.png,.gif,.svg',
                            url: urlUpload,
                            thumbnailWidth: 150,
                            maxFilesize: 50,
                            maxFiles: 1,
                            headers: {
                                'Cache-Control': '',
                                Authorization: Token
                            }
                        }"
                        id="uploadImage"
                        class="dropzone aspect-square h-48 w-full"
                        :check_class="true"
                        :uploaded="returnImg(dat.id) != ''"
                        :success="x => uploadImage(dat.id, x)"
                    >
                        <input name="id_image" type="hidden" v-model="dat.id" />
                        <input
                            name="id_content"
                            type="hidden"
                            v-model="id_content"
                        />
                        <div class="text-lg font-medium text-text-100">
                            {{ $t("content.sellerSponge.placeholder_image") }}
                        </div>
                    </Dropzone>
                </div>
            </div>
        </div>
        <div class="flex flex-row my-5 items-center gap-3">
            <button
                type="button"
                v-on:click="addNewData()"
                class="btn btn-primary w-16 my-5 h-10"
            >
                <inline-svg
                    :src="require('@/assets/icons/plus-solid.svg')"
                    alt=""
                    class="h-full"
                    style="fill:white;"
                />
            </button>
            <p
                v-if="dataContent"
                class="text-lg  "
                :class="
                    dataContent.length <
                    $store.state.globalVariables.sellerSponge.minContent
                        ? 'text-danger-100'
                        : 'text-success-120'
                "
            >
                {{ dataContent.length }}/{{
                    $store.state.globalVariables.sellerSponge.maxContent
                }}
            </p>
        </div>
    </span>
</template>

<script>
import { axiosToken } from "@/libs/http";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { SmartTagz } from "smart-tagz";
import "smart-tagz/dist/smart-tagz.css";
import { makeid } from "@/libs/libs";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
    components: {
        SmartTagz
    },

    props: {
        data: Object,
        id_content: Number,
        all_data: Object
    },

    setup(props) {
        const Token = "Token " + localStorage.user;
        const urlUpload =
            process.env.VUE_APP_API_URL + "content/subcategoryImage";
        const dataContent = ref(props.data);
        const { t } = useI18n();
        const store = useStore();

        onMounted(() => {
            dataContent.value.map(content => {
                content.image = "";
            });
        });

        const logResult = (result, e) => {
            dataContent.value[e].quiz = result;
        };

        const addNewData = () => {
            if (
                dataContent.value.length >=
                store.state.globalVariables.sellerSponge.maxContent
            ) {
                notify(
                    {
                        group: "error",
                        title: t("general.error"),
                        text: t("content.alert_limit_content")
                    },
                    2000
                );
                return;
            }
            dataContent.value.push({
                name: "",
                image: "",
                id: makeid(16),
                question: ""
            });
        };

        const removedataContent = index => {
            dataContent.value.splice(index, 1);
        };

        const returnImg = id => {
            console.log(dataContent.value);
            if (dataContent.value.find(e => e.id == id).image) {
                return dataContent.value.find(e => e.id == id).image;
            }
            for (let x = 0; x < props.all_data.images.length; x++) {
                if (props.all_data.images[x].name == id) {
                    return props.all_data.images[x].img;
                }
            }
            return "";
        };
        const uploadImage = (id, response) => {
            dataContent.value.find(e => e.id == id).image = response.url;
            console.log(dataContent.value, response);
        };

        return {
            logResult,
            dataContent,
            addNewData,
            removedataContent,
            urlUpload,
            Token,
            returnImg,
            uploadImage
        };
    }
});
</script>

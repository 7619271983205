<template>
    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12">
            <div class="grid grid-cols-12 gap-6">
                <!-- BEGIN: General Report -->
                <div class="col-span-12 mt-8">
                    <div class="intro-y grid grid-cols-12 h-10 ">
                        <div class="col-span-6 items-start">
                            <h2 class="text-xl font-medium truncate mr-5">
                                {{ $t("analytics.title") }}
                            </h2>
                        </div>
                        <div
                            class="col-span-6 flex flex-row gap-5 items-center"
                        >
                            <label
                                class="text-lg form-check-label text-text-100"
                            >
                                {{ $t("general.date_start") }}:
                            </label>
                            <Litepicker
                                v-model="dateStart"
                                :options="{
                                    autoRefresh: true,
                                    showWeekNumbers: true,
                                    dropdowns: {
                                        minYear: 1990,
                                        maxYear: null,
                                        months: true,
                                        years: true
                                    }
                                }"
                                :callbackOnChange="getAnalytics"
                                class=" focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block"
                            />
                            <label
                                class="text-lg form-check-label text-text-100"
                            >
                                {{ $t("general.date_end") }}:
                            </label>
                            <Litepicker
                                v-model="dateEnd"
                                :options="{
                                    autoRefresh: true,
                                    showWeekNumbers: true,
                                    dropdowns: {
                                        minYear: 1990,
                                        maxYear: null,
                                        months: true,
                                        years: true
                                    }
                                }"
                                :callbackOnChange="getAnalytics"
                                class=" focus:font-bold w-full rounded-lg font-300 py-3 px-4 border border-border-100 block"
                            />
                        </div>
                    </div>
                    <Area
                        :loading="!loadingAnalytics"
                        :class="'my-10'"
                        :class_loader="'w-1/4'"
                    >
                        <div class="grid grid-cols-12 gap-6 mt-5">
                            <CardAnalytics
                                :value="analytics.bonfire_time"
                                :text="t('analytics.bonfire_time')"
                                :icon="'clock-regular.svg'"
                                :typeOfValue="' min'"
                                :numberDecimals="2"
                            />
                            <CardAnalytics
                                :value="analytics.play_time"
                                :text="t('analytics.play_time')"
                                :icon="'play-solid.svg'"
                                :typeOfValue="' min'"
                                :numberDecimals="2"
                            />
                            <CardAnalytics
                                :value="analytics.games_played"
                                :text="t('analytics.games_played')"
                                :icon="'play-solid.svg'"
                            />
                            <CardAnalytics
                                :value="analytics.mean_players"
                                :text="t('analytics.mean_players')"
                                :icon="'users-solid.svg'"
                            />
                            <CardAnalytics
                                :value="analytics.most_played"
                                :text="t('analytics.most_played')"
                                :icon="'star-regular.svg'"
                            />
                            <CardAnalytics
                                :value="analytics.content_most_played"
                                :text="t('analytics.content_most_played')"
                                :icon="'star-regular.svg'"
                            />
                        </div>
                    </Area>
                </div>
                <!-- END: General Report -->
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, onBeforeMount, watch } from "vue";
import { axiosToken } from "@/libs/http";
import CardAnalytics from "@/components/cards/CardAnalytics.vue";
import { useI18n } from "vue-i18n";
import Area from "@/global-components/loading/Area.vue";
import {
    getDateZFormatted,
    todayDateWithCustomStart
} from "@/libs/date-functions.js";

export default {
    props: {},
    components: {
        CardAnalytics,
        Area
    },

    setup(props) {
        const { t } = useI18n();
        const analytics = ref([]);
        const loadingAnalytics = ref(false);

        let dateStart = ref(
            getDateZFormatted(todayDateWithCustomStart(0, -1, 0))
        );
        let dateEnd = ref(getDateZFormatted());

        const getAnalytics = () => {
            console.log(loadingAnalytics);
            loadingAnalytics.value = false;
            const dateStartGet = getDateZFormatted(new Date(dateStart.value));
            const dateEndGet = getDateZFormatted(new Date(dateEnd.value));
            axiosToken({
                method: "get",
                url:
                    "bonfire/analytics?start=" +
                    dateStartGet +
                    "&end=" +
                    dateEndGet
            }).then(response => {
                analytics.value = response.data;
                loadingAnalytics.value = true;
                return response.data;
            });
        };

        onMounted(() => {
            getAnalytics();
        });

        return {
            analytics,
            t,
            dateStart,
            dateEnd,
            getAnalytics,
            loadingAnalytics
        };
    }
};
</script>

<style></style>

<template>
    <span>
        <div>
            <smart-tagz
                :defaultTags="[...dataContent]"
                editable
                :inputPlaceholder="$t('content.topo.placeholder')"
                :allowPaste="{delimiter: ','}"
                :allowDuplicates="false"
                :maxTags="$store.state.globalVariables.topo.maxTags"
                :theme="{
            primary: '#FE3732',
            background: '#fff',
            tagTextColor: '#fff',
            
        }"
                :on-changed="(e)=>logResult(e)"
            />
        </div>
        <p
            v-if="dataContent"
            class="text-lg  "
            :class="dataContent.length < $store.state.globalVariables.topo.minContent ? 'text-danger-100' :'text-success-120' "
        >
            {{dataContent.length}}/{{$store.state.globalVariables.topo.maxContent}}
        </p>
    </span>
</template>

<script>
import { axiosToken } from "@/libs/http";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { SmartTagz } from "smart-tagz";
import "smart-tagz/dist/smart-tagz.css";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
    components: {
        SmartTagz,
    },

    props: {
        data: Object,
        all_data:Object,
    },

    setup(props) {
        const dataContent = ref(props.all_data.data);
        const topoDataOffset = ref(props.data);
        const { t } = useI18n();
        const store = useStore();
        const logResult = (result) => {
            if(result.length <= store.state.globalVariables.topo.maxContent) 
            {
                console.log('IN ', result.length, ' <= ', store.state.globalVariables.topo.maxContent)
                dataContent.value = result
                props.all_data.data = result
            }
            else 
            {
                notify ({
                    group: "error",
                    title: t('general.error'),
                    text: t('content.alert_limit_content')
                }, 2000)
            }
        };

        return {
            logResult,
            dataContent,
            topoDataOffset,
        };
    },
});
</script>


import { createRouter, createWebHistory } from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'




import Teams from '../views/app/teams/Main.vue'
import Bonfire from '../views/app/bonfire/Main2.vue'
import Activities from '../views/app/activities/Main.vue'
import Booking from '../views/app/bookings/Main.vue'
import UpdateProfile from '../views/app/update-profile/Main.vue'
import Landing from '../views/app/landing/Main.vue'
import Content from '../views/app/content/Main.vue'
import ContentGame from '../views/app/content-game/Main.vue'
import ContentGameDetails from '../views/app/content-game-details/Main.vue'
import gameUsage from '../views/app/gameUsage/Main.vue'
import Analytics from '../views/app/analytics/Main.vue'

import Login from '../views/auth/login/Main.vue'
import SocialLogin from '../views/auth/login/SocialLogin.vue'

import Register from '../views/auth/register/Main.vue'
import RegisterActivate from '../views/auth/register_activate/Main.vue'
import ForgetPassword from '../views/auth/forgetPassword/Main.vue'
import NewPassword from '../views/auth/newPassword/Main.vue'


import ErrorPage from '../views/error-page/Main.vue'
import store from '@/store';



const routes = [
  {
    path: '/',
    meta: {
      requiresAuth: true
    },
    component: SideMenu,
    children: [
      {
        path: '/',
        name: 'team',
        component: Teams
      },
      {
        path: 'bonfire',
        name: 'side-menu-dashboard-overview-2',
        component: Bonfire
      },
      {
        path: 'activities',
        name: 'side-menu-dashboard-overview-3',
        component: Activities
      },
      {
        path: 'booking',
        name: 'side-book-Pricing',
        component: Booking
      },
      {
        path: 'organitzation',
        name: 'landing',
        component: Landing
      },
      {
        path: 'settings',
        name: 'side-menu-settings',
        component: UpdateProfile
      },
      {
        path: 'content',
        name: 'content',
        component: Content
      },
      {
        path: 'content/:id',
        name: 'content-game-id',
        component: ContentGame
      },
      {
        path: 'content/:id/:idSub',
        name: 'content-game-edit-admin',
        component: ContentGameDetails
      },
      {
        path: 'game-usage',
        name: 'game-usage',
        component: gameUsage
      },
      {
        path: 'analytics',
        name: 'analytics',
        component: Analytics
      },
      {
        path: 'admin-content/:id/:idSub',
        name: 'content-game-edit',
        component: ContentGameDetails,
        meta: {
          requiresSuperUser: true
        },
      },
      {
        path: 'admin-content',
        name: 'content-admin',
        component: Content,
        meta: {
          requiresSuperUser: true
        },
      },
      {
        path: 'admin-content/:id',
        name: 'content-game-id-admin',
        component: ContentGame,
        meta: {
          requiresSuperUser: true
        },
      },
    ]
  },
  {
    path: '/login/google',
    name: 'login2',
    component: SocialLogin
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/register-activate',
    name: 'register-activate',
    component: RegisterActivate
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/forget-password',
    component: ForgetPassword

  },
  {
    path: '/recovery-password/:uid/:token',
    component: NewPassword

  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in 
    // if not, redirect to login page.


    if (!store.state.auth.status.loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()! 
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresSuperUser)) {
    // this route requires auth, check if logged in 
    // if not, redirect to login page.


    if (store.state.auth.rol !== 'super') {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()! 
  }
})



export default router

import {es} from './locale/es.js'
import {en} from './locale/en.js'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: navigator.language,
  fallbackLocale: 'en', // set fallback locale
  globalInjection: true, 
  messages:{
      'es-ES':es,
      'es':es,
      'en':en,
  }
})


export { i18n }